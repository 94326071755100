import { CollectionCreateModel } from '@on-arte/core-types';
import {
  ApiError,
  useFormikForm,
  UseFormikForm,
  UseLogger,
  useLogger,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { createCollection } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

import { CollectionForm } from '../components';

export const CollectionAddFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();
  const formRef: React.Ref<FormikProps<CollectionCreateModel>> = useRef(null);

  const save: () => Promise<void> = async (): Promise<void> => {
    if (!formRef.current) {
      return;
    }

    const formObject: FormikProps<CollectionCreateModel> = formRef.current;
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (isValid) {
      formObject.handleSubmit();
      setFormSubmitted();
      const { name, title, description, coverId }: CollectionCreateModel = formObject.values;

      createCollection({ name, title, description, coverId })
        .then((): void => {
          addToast({ content: t('onarte.management.collectionAddForm.createCollection') });
          redirect(getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/');
        })
        .catch((error: ApiError): void => logger('createCollection', error, 'error'));
    }
  };
  
  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.contentCollectionAddForm.title'),
        buttonLabel: t('onarte.management.collectionAddForm.buttonLabel'),
        buttonAction: save,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentCollectionsList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/' 
        },
        { 
          label: t('onarte.management.meta.contentCollectionAddForm.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentCollectionAddForm)?.url ?? '/' 
        },
      ]}
    >
      <CollectionForm
        initialValues={{
          name: '',
          title: '',
          description: '',
          coverId: ''
        }}
        isFormSubmitted={isFormSubmitted}
        ref={formRef}
      />
    </BaseView>
  );
};
