import { ItemSearchInterface, ListPaginationInterface, ManufacturerInterface, TranslationsEnum } from '@on-arte/core-types';
import {
  ElementsTable,
  PaginationDetails,
  usePagination,
  ElementsTableRow,
  UseState,
  IconName,
  getPathWithParams,
  InputTheme,
  Input,
  useNotifications,
  UseNotifications,
  ApiError,
  UseLogger,
  useLogger,
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { deleteManufacturer, searchItems, searchManufacturers, verifyManufacturer } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { authorStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

export const AuthorsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const [authors, setAuthors]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const [authorsSearch, setAuthorsSearch]: UseState<string> = useState<string>('');
  const [searchInputText, setSearchInputText]: UseState<string> = useState<string>('');
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);
  const navigate: NavigateFunction = useNavigate();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const queryClient: QueryClient = useQueryClient();

  // TODO: temporary hidden
  // const verifyAuthor: (manufacturer: ManufacturerInterface, confirmed: boolean) => void = (
  //   manufacturer: ManufacturerInterface, confirmed: boolean
  // ): void => {
  //   showSmallDialog({
  //     header: t(`onarte.management.authorsList.${confirmed ? 'confirmAuthor' : 'discardAuthor'}.header`),
  //     content: t(
  //       `onarte.management.authorsList.${confirmed ? 'confirmAuthor' : 'discardAuthor'}.content`,
  //       { name: manufacturer.name }
  //     ),
  //     acceptButton: {
  //       label: t(`onarte.management.authorsList.${confirmed ? 'confirmAuthor' : 'discardAuthor'}.acceptButtonLabel`),
  //       action: (): void => {
  //         verifyManufacturer(manufacturer.id, { confirmed, userId: manufacturer.user?.id })
  //           .then((): void => {
  //             void queryClient.invalidateQueries(QueryKey.AuthorsList);
  //             addToast({
  //               content: t(
  //                 `onarte.management.authorsList.${confirmed ? 'confirmAuthor' : 'discardAuthor'}.success`,
  //                 { name: manufacturer.name }
  //               )
  //             });
  //           })
  //           .catch((error: ApiError): void => addToast({ content: t(error.message) }))
  //           .finally((): void => hideSmallDialog());
  //       }
  //     },
  //     cancelButton: {
  //       label: t('onarte.common.cancel'),
  //     },
  //   });
  // };

  const deleteAuthor: (manufacturer: ManufacturerInterface) => void = (manufacturer: ManufacturerInterface): void => {
    showSmallDialog({
      header: t('onarte.management.authorsList.deleteAuthor.header'),
      content: t('onarte.management.authorsList.deleteAuthor.content', { name: manufacturer.name }),
      acceptButton: {
        label: t('onarte.management.authorsList.deleteAuthor.acceptButtonLabel'),
        action: (): void => {
          deleteManufacturer(manufacturer.id)
            .then((): void => {
              void queryClient.invalidateQueries(QueryKey.AuthorsList);
              addToast({ content: t('onarte.management.authorsList.deleteAuthor.success', { name: manufacturer.name }) });
            })
            .catch((error: ApiError): void => authorDeleteErrorAction(error, manufacturer))
            .finally((): void => hideSmallDialog());
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel'),
      },
    });
  };

  const authorDeleteErrorAction: (error: ApiError, manufacturer: ManufacturerInterface) => void = (
    error: ApiError, manufacturer: ManufacturerInterface
  ): void => {
    if (error.message === TranslationsEnum.ManufacturerHasPublicProfile) {
      addToast({ content: t(error.message) });
    } else if (error.message === TranslationsEnum.ManufacturerHasItems) {
      searchItems({ manufacturerId: manufacturer.id, limit: 100, offset: 0})
        .then((items: ListPaginationInterface<ItemSearchInterface>): void => {
          let itemsListText: string = '';
          items.list.forEach((item: ItemSearchInterface): string => itemsListText += `- ${item.name}\n`);
          addToast({ content: `${t(error.message)}\n${itemsListText}` });
        })
        .catch((): void => undefined);
    }
  };

  useQuery(
    [QueryKey.AuthorsList, offset, authorsSearch],
    (): Promise<ListPaginationInterface<ManufacturerInterface>> => searchManufacturers(
      { limit: itemsPerPage, offset, name: authorsSearch }
    ),
    {
      onSuccess: (data: ListPaginationInterface<ManufacturerInterface>): void => {
        setMaxItems(data.amount);
        setAuthors(data.list
          .map((manufacturer: ManufacturerInterface): ElementsTableRow => ({
            cells: [
              { name: 'id', content: manufacturer.id },
              { name: 'name', content: manufacturer.name },
              // TODO: temporary hidden
              // {
              //   name: 'status',
              //   content: {
              //     label: t(authorStatusConfig[manufacturer.type].label),
              //     background: authorStatusConfig[manufacturer.type].background,
              //     color: authorStatusConfig[manufacturer.type].color,
              //   },
              // },
              { name: 'relatedUser', content: manufacturer.user?.name ?? '-' },
            ],
            contextMenuPositions: [
              {
                label: t('onarte.management.authorsList.actions.edit'),
                internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.AuthorEditForm)?.url ?? '/', { id: manufacturer.id }),
                icon: IconName.Description
              },
              // TODO: temporary hidden
              // ...(!manufacturer.verified ? [
              //   {
              //     label: t('onarte.management.authorsList.actions.confirm'),
              //     action: (): void => verifyAuthor(manufacturer, true),
              //     icon: IconName.CheckCircle
              //   },
              //   {
              //     label: t('onarte.common.discard'),
              //     action: (): void => verifyAuthor(manufacturer, false),
              //     icon: IconName.Cancel
              //   }
              // ] : []),
              {
                label: t('onarte.common.delete'),
                action: (): void => deleteAuthor(manufacturer),
                icon: IconName.Delete
              }
            ]
          }))
        );
      },
      onError: (error: ApiError): void => logger(QueryKey.AuthorsList, error, 'error')
    }
  );

  const handleAuthorsSearch: (e: React.KeyboardEvent<HTMLInputElement>) => void = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') setAuthorsSearch(searchInputText);
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.authorsList.title'),
        buttonLabel: t('onarte.management.authorsList.actions.add'),
        buttonAction: (): void => navigate(getRouteDetailsByName(RouteNameEnum.AuthorsAddForm)?.url ?? '/'),
      }}
      pageTitleChildren={
        <Input
          label={t('onarte.common.searchInput.label')}
          icon={IconName.Loupe}
          inputTheme={InputTheme.Small}
          value={authorsSearch}
          onChange={(value: string): void => {
            setSearchInputText(value);
            setPage(1);
          }}
          onKeyPress={handleAuthorsSearch}
        />
      }
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.authorsList.title'), path: getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/' },
      ]}
    >
      <ElementsTable
        columns={[
          { name: 'id', label: t('onarte.management.authorsList.tableColumns.id') },
          { name: 'name', label: t('onarte.management.authorsList.tableColumns.name') },
          // TODO: temporary hidden
          // { name: 'status', label: t('onarte.management.authorsList.tableColumns.status') },
          { name: 'relatedUser', label: t('onarte.management.authorsList.tableColumns.relatedUser') },
        ]}
        rows={authors}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        initialPageCount={page}
      />
    </BaseView>
  );
};
