import { ListPaginationInterface, UserListElementInterface } from '@on-arte/core-types';
import { 
  ApiError, 
  ElementsTable, 
  ElementsTableRow, 
  getPathWithParams, 
  IconName, 
  Input, 
  InputTheme, 
  Language, 
  PaginationDetails, 
  useFormatDate, 
  UseFormatDate, 
  UseLogger, 
  useLogger, 
  UseNotifications, 
  useNotifications, 
  usePagination, 
  UseState 
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getUsers } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { userStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

export const UsersListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const { formatDate }: UseFormatDate = useFormatDate(Language.Pl);
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);
  const [users, setUsers]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const [searchInputText, setSearchInputText]: UseState<string> = useState<string>('');

  useQuery(
    [QueryKey.UsersList, offset, searchInputText],
    (): Promise<ListPaginationInterface<UserListElementInterface>> => getUsers(
      { offset, limit: itemsPerPage, search: searchInputText }
    ),
    {
      onSuccess: (data: ListPaginationInterface<UserListElementInterface>): void => {
        setMaxItems(data.amount);
        setUsers(data.list.map((user: UserListElementInterface): ElementsTableRow => {
          return {
            cells: [
              { name: 'firstName', content: user.firstName },
              { name: 'lastName', content: user.lastName },
              { name: 'email', content: user.email },
              { name: 'phone', content: user.phone ?? '' },
              { name: 'createdAt', content: formatDate(user.registrationDate, 'DD MMMM YYYY') },
              {
                name: 'status',
                content: {
                  label: t(userStatusConfig[user.status].label),
                  background: userStatusConfig[user.status].background,
                  color: userStatusConfig[user.status].color,
                },
              },
            ],
            contextMenuPositions: [
              {
                label: t('onarte.management.usersListView.actions.userEdit'),
                internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.UserEditForm)?.url ?? '/', { id: user.id }),
                icon: IconName.Edit
              },
              // TODO: temporary hidden, fix when be will be ready
              // {
              //  label: t('onarte.management.usersListView.actions.userDelete'),
              //  action: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') }),
              //  icon: IconName.Delete
              // },
              // {
              //  label: t('onarte.management.usersListView.actions.userArchive'),
              //  action: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') }),
              //  icon: IconName.Archive
              // },
              // {
              //  label: t('onarte.management.usersListView.actions.userBlock'),
              //  action: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') }),
              //  icon: IconName.Block
              // },
            ]
          };
        }));
      },
      onError: (error: ApiError): void => {
        logger(QueryKey.UsersList, error, 'error');
        addToast({ content: t(error.message) });
      },
    }
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.usersList.title'),
      }}
      pageTitleChildren={
        <Input
          label={t('onarte.common.searchInput.label')}
          icon={IconName.Loupe}
          inputTheme={InputTheme.Small}
          value={searchInputText}
          onChange={(value: string): void => {
            setSearchInputText(value);
            setPage(1);
          }}
        />
      }
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.usersList.title'), path: getRouteDetailsByName(RouteNameEnum.UsersList)?.url ?? '/' },
      ]}
    >
      <ElementsTable
        columns={[
          { name: 'firstName', label: t('onarte.management.usersListView.tableColumns.firstName') },
          { name: 'lastName', label: t('onarte.management.usersListView.tableColumns.lastName') },
          { name: 'email', label: t('onarte.management.usersListView.tableColumns.email') },
          { name: 'phone', label: t('onarte.management.usersListView.tableColumns.phone') },
          { name: 'createdAt', label: t('onarte.management.usersListView.tableColumns.createdAt') },
          { name: 'status', label: t('onarte.management.usersListView.tableColumns.status') },
        ]}
        rows={users}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        initialPageCount={page}
      />
    </BaseView>
  );
};
