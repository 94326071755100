import { EventCreateModel } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useEventAddFormValidation: () => Yup.SchemaOf<Partial<EventCreateModel>> = (): Yup.SchemaOf<Partial<EventCreateModel>> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    name: Yup.string()
      .required(t('onarte.common.requiredField')),
    published: Yup.boolean()
      .required(t('onarte.common.requiredField')),
    startTimestamp: Yup.number()
      .required(t('onarte.common.requiredField')),
    endTimestamp: Yup.number()
      .required(t('onarte.common.requiredField')),
    coverPhotoId: Yup.string()
      .required(t('onarte.common.requiredField')),
    description: Yup.string()
      .required(t('onarte.common.requiredField')),
    offerCondition: Yup.string()
      .required(t('onarte.common.requiredField')),
  });
};
