import { LoginByPasswordModel } from '@on-arte/core-types';
import { ApiError, UseFormikForm, useFormikForm, UseState } from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { loginRequest } from '@onArte/api';
import { useAuth } from '@onArte/hooks';
import { UseAuth, LoginResponse } from '@onArte/interfaces';

import { useSignInValidation } from './signIn.hooks';
import { Container, FormikForm, LoginContainer, StyledButton, StyledInput, StyledLogo, StyledValidationBar } from './signIn.styled';

export const SignInView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [loginError, setLoginError]: UseState<string> = useState<string>('');
  const { signIn }: UseAuth = useAuth();
  const SignInFormValidationSchema: Yup.SchemaOf<LoginByPasswordModel> = useSignInValidation();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();

  const loginAction: (data: { login: string; password: string }) => void = (data: { login: string; password: string }): void => {
    setFormSubmitted();
    if (!data.login || !data.password) {
      return;
    }

    loginRequest(data.login, data.password)
      .then((response: LoginResponse) => {
        setLoginError('');
        signIn(response, response?.token ?? '', parseInt(response?.tokenExpiration ?? '', 10));
      })
      .catch((error: ApiError): void => {
        setLoginError(t(error.message));
        setTimeout((): void => setLoginError(''), 3000);
      });
  };

  return (
    <Container>
      <LoginContainer>
        <StyledLogo />
        <Formik
          initialValues={{ login: '', password: '' }}
          onSubmit={loginAction}
          validationSchema={SignInFormValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors }: FormikProps<LoginByPasswordModel>): JSX.Element => (
            <FormikForm onSubmit={handleSubmit}>
              <StyledInput
                label={t('onarte.common.email')}
                onChange={(value: string): void => setFieldValue('login', value)}
                onBlur={(value: string): void => setFieldValue('login', value)}
                validationMessage={errors.login || ''}
              />
              <StyledInput
                label={t('onarte.common.password')}
                onChange={(value: string): void => setFieldValue('password', value)}
                onBlur={(value: string): void => setFieldValue('password', value)}
                validationMessage={errors.password || ''}
                type='password'
              />
              <StyledValidationBar message={loginError} />
              <StyledButton
                label={t('onarte.common.signIn')}
                type='submit'
                onClick={setFormSubmitted}
                fullWidth
              />
            </FormikForm>
          )}
        </Formik>
      </LoginContainer>
    </Container>
  );
};
