import { 
  AuctionInfoInterface, 
  AuctionStatusEnum, 
  AuctionTypeEnum, 
  ListPaginationInterface, 
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import { 
  ApiError, 
  DirectionEnum, 
  SuggestionItem, 
  UseLogger, 
  UseNotifications, 
  UseState, 
  useLogger, 
  useNotifications, 
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';

import { getAuctionsList, updateAuctionHighlightedSettings, updateAuctionSortIndex } from '@onArte/api';
import { ItemsSelectionBaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions } from '@onArte/hooks';
import { AutosuggestActions } from '@onArte/interfaces';
import { getRouteDetailsByName, getImageThumbnail } from '@onArte/utils';

import { NoDataMessage, StyledAuctionDetailsBox } from '../highlighted.styled';

export const HighlightedBiddingListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const { searchAuctionsAction }: AutosuggestActions = useAutosuggestActions();
  const [auctionsList, setAuctionsList]: UseState<AuctionInfoInterface[]> = useState<AuctionInfoInterface[]>([]);
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);

  const { refetch, isFetching }: QueryObserverResult = useQuery(
    [QueryKey.AuctionsList],
    (): Promise<ListPaginationInterface<AuctionInfoInterface>> => getAuctionsList(
      { limit: 100, offset: 0, sortByHighlighted: true, status: AuctionStatusEnum.Active, type: AuctionTypeEnum.Bidding, highlighted: true }
    ),
    {
      onSuccess: (data: ListPaginationInterface<AuctionInfoInterface>): void => setAuctionsList(data.list),
      onError: (error: ApiError): void => logger(QueryKey.AuctionsList, error)
    }
  );

  const onSelectNewItemHandler: (suggestion: SuggestionItem) => void = (suggestion: SuggestionItem): void => {
    if (!suggestion.id) {
      return;
    }

    updateAuctionHighlightedSettings(suggestion.id, { highlighted: true })
      .then((): void => {
        addToast({ content: t('onarte.management.highlightedBiddingList.updateAuctionHighlightedSettings.success') });
        void refetch();
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  const onPositionChangeAction: (
    dir: DirectionEnum, auctionId: string
  ) => Promise<void> = (dir: DirectionEnum, auctionId: string): Promise<void> => {
    return new Promise((resolve: () => void, reject: () => void): void => {
      updateAuctionSortIndex(auctionId, dir)
        .then((): void => {
          void refetch()
            .then((): void => resolve());
        })
        .catch((): void => reject());
    });
  };

  const onRemoveAction: (auctionId: string) => void = (auctionId: string): void => {
    updateAuctionHighlightedSettings(auctionId, { highlighted: false })
      .then((): void => {
        addToast({ content: t('onarte.management.highlightedBiddingList.updateAuctionHighlightedSettings.removeSuccess') });
        void refetch();
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  return (
    <ItemsSelectionBaseView 
      pageTitleSettings={{
        title: t('onarte.management.meta.contentHighlightedBiddingList.title'),
        withUnderline: true
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentHighlightedBiddingList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentHighlightedBiddingList)?.url ?? '/' 
        },
      ]}
      children={
        !isFetching && (
          !auctionsList.length ? (
            <NoDataMessage>{t('onarte.common.noArtworks')}</NoDataMessage>
          ) : (
            auctionsList.map((auction: AuctionInfoInterface, index: number): JSX.Element => (
              <StyledAuctionDetailsBox
                key={index}
                boxTitleDetails={{
                  author: auction.manufacturer.name,
                  itemName: auction.name,
                  itemDescriptiveAttributes: [t(auction.label)]
                }}
                image={getImageThumbnail(auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_340xAuto)}
                onRemove={(): void => onRemoveAction(auction.id)}
                isFirst={index === 0}
                isLast={index === (auctionsList.length - 1)}
                onPositionChangeAsync={(dir: DirectionEnum): Promise<void> => onPositionChangeAction(dir, auction.id)}
                setIsLoading={setIsLoading}
              />
            ))
          )
        )
      }
      onSelectNewItemHandler={onSelectNewItemHandler}
      autosuggestAction={(inputValue: string): Promise<SuggestionItem[]> => searchAuctionsAction(inputValue, AuctionTypeEnum.Bidding)}
      isLoading={isLoading}
    />
  );
};
