import { Button, StyledComponent } from '@on-arte/ui';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div``;

export const FormContainer: StyledComponent<'div'> = styled.div`
  margin-top: 72px;
`;

export const FormikForm: StyledComponent<typeof Form> = styled(Form)`
  width: 100%;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 24px;
`;
