import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';

import { appConfig } from '@onArte/app.config';
import { LocalStorageKey, RouteNameEnum } from '@onArte/enums';
import { AuthContextState } from '@onArte/interfaces';
import { ApiError, FrontendApiError } from '@onArte/models';
import { getRouteDetailsByName } from '@onArte/utils';

export const api: AxiosInstance = axios.create({
  baseURL: appConfig.api,
  responseType: 'json',
  headers: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    'x-auth-token': typeof window !== 'undefined'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      ? JSON.parse(window.localStorage.getItem(LocalStorageKey.AuthData) ?? '{}')?.token
      : undefined,
  },
});

export const setAuthToken: (token: string) => void = (token: string): void => {
  const id: number = api.interceptors.request.use((request: AxiosRequestConfig): AxiosRequestConfig => {
    const headers: AxiosRequestHeaders = request.headers ?? {};
    headers['x-auth-token'] = token;

    return request;
  });
  if (id > 0) {
    api.interceptors.request.eject(id - 1);
  }
};

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (response.headers['x-auth-token']) {
      setAuthToken(response.headers['x-auth-token']);
      return {
        ...response,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: { ...response.data, token: response.headers['x-auth-token'], tokenExpiration: response.headers['x-auth-expires'] }
      };
    }
    if (response.headers['x-auth-expires']) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const authData: AuthContextState = JSON.parse(window.localStorage.getItem(LocalStorageKey.AuthData) ?? '{}');
      authData.tokenExpiration = parseInt(response.headers['x-auth-expires'], 10);
      window.localStorage.setItem(LocalStorageKey.AuthData, JSON.stringify(authData));
    }

    return response;
  },
  (error: AxiosError<ApiError>): void | ApiError => {
    if (error.response) {
      const apiErrorObject: FrontendApiError = new FrontendApiError(
        error.response.data.message,
        error.response.status,
        error.response.data.messageParams,
        error.response.data.details,
      );

      if (error.response.status === 401) {
        if (
          window.location.pathname !== getRouteDetailsByName(RouteNameEnum.SignIn)?.url
          && window.location.pathname !== getRouteDetailsByName(RouteNameEnum.Logout)?.url
        ) {
          window.location.assign(getRouteDetailsByName(RouteNameEnum.Logout)?.url ?? '/');
        }
        throw apiErrorObject;
      } else if (error.response.status === 400 || error.response.status > 401) {
        throw apiErrorObject;
      }
    } else if (error.request && !error.code) {
      throw new FrontendApiError('onarte.management.errors.custom.noInternetConnection', 503);
    } else {
      throw new FrontendApiError('onarte.management.errors.custom.unknownApiError', 503);
    }
  },
);
