import { EventStatusEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const eventStatusConfig: Record<EventStatusEnum, Status> = {
  [EventStatusEnum.InProgress]: {
    label: 'onarte.common.eventStatuses.inProgress',
    background: Color.Success100,
    color: Color.Success500
  },
  [EventStatusEnum.Incoming]: {
    label: 'onarte.common.eventStatuses.incoming',
    background: Color.Gray200,
    color: Color.Gray500
  },
  [EventStatusEnum.Finished]: {
    label: 'onarte.common.eventStatuses.finished',
    background: Color.Black,
    color: Color.White
  },
};
