import { 
  AuctionDetailsInterface, 
  AuctionHighlightedUpdateOnManagementModel, 
  AuctionInfoInterface, 
  AuctionUpdateOnManagementModel, 
  ListPaginationInterface 
} from '@on-arte/core-types';
import { ApiError, DirectionEnum } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { AuctionListParams, ListParams } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const getAuctionsList: (
  params: ListParams & AuctionListParams
) => Promise<ListPaginationInterface<AuctionInfoInterface>> = (
  params: ListParams & AuctionListParams
): Promise<ListPaginationInterface<AuctionInfoInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<AuctionInfoInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/auctions?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<AuctionInfoInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getAuctionDetails: (
  auctionId: string
) => Promise<AuctionDetailsInterface> = (auctionId: string): Promise<AuctionDetailsInterface> => new Promise(
  (resolve: ApiResolve<AuctionDetailsInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/auctions/${auctionId}`).then(
      (response: AxiosResponse<AuctionDetailsInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const editAuction: (
  auctionId: string, data: AuctionUpdateOnManagementModel
) => Promise<AuctionDetailsInterface> = (
  auctionId: string, data: AuctionUpdateOnManagementModel
): Promise<AuctionDetailsInterface> => new Promise(
  (resolve: ApiResolve<AuctionDetailsInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/auctions/${auctionId}`, data).then(
      (response: AxiosResponse<AuctionDetailsInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updateAuctionHighlightedSettings: (
  auctionId: string, data: AuctionHighlightedUpdateOnManagementModel
) => Promise<void> = (
  auctionId: string, data: AuctionHighlightedUpdateOnManagementModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/auctions/${auctionId}/highlighted`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updateAuctionSortIndex: (
  auctionId: string, direction: DirectionEnum
) => Promise<void> = (
  auctionId: string, direction: DirectionEnum
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/auctions/${auctionId}/sort/${direction}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
