import { AuctionTypeSearchEnum } from '@on-arte/core-types';

import { AuctionTypeFilter } from './artworksList.types';

export const auctionTypeFilters: AuctionTypeFilter[] = [
  { label: 'onarte.common.all' },
  { name: AuctionTypeSearchEnum.BuyNow, label: 'onarte.common.buyNow' },
  { name: AuctionTypeSearchEnum.PriceProposal, label: 'onarte.common.priceProposal' },
  { name: AuctionTypeSearchEnum.Bidding, label: 'onarte.common.bidding' },
  { name: AuctionTypeSearchEnum.None, label: 'onarte.common.readyForSale' },
];
