import { SliderDTO } from '@on-arte/core-types';
import {
  ApiError,
  useFormikForm,
  UseFormikForm,
  UseLogger,
  useLogger,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
  UseState,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Params, useParams } from 'react-router-dom';

import { editSlider, getSliderDetails } from '@onArte/api';
import { BaseView, SliderForm } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useObjectsTransformations } from '@onArte/hooks';
import { AddOrUpdateSliderForm, UseObjectsTranformations } from '@onArte/interfaces';
import { getRouteDetailsByName, getSliderUpdateModelByForm } from '@onArte/utils';

export const SliderEditFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Params<string>> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();
  const { transformAttachmentInterfaceToAddPhotoFile }: UseObjectsTranformations = useObjectsTransformations();
  const [sliderData, setSliderData]: UseState<SliderDTO | null> = useState<SliderDTO | null>(null);
  const formRef: React.Ref<FormikProps<AddOrUpdateSliderForm>> = useRef(null);

  useQuery(
    [QueryKey.SliderDetails],
    (): Promise<SliderDTO> => getSliderDetails(id ? Number(id) : 0),
    {
      enabled: !!id,
      onSuccess: (data: SliderDTO): void => setSliderData(data),
      onError: (error: ApiError): void => logger(QueryKey.SliderDetails, error, 'error')
    }
  );

  const save: () => Promise<void> = async (): Promise<void> => {
    if (!id || !formRef.current) {
      return;
    }

    const formObject: FormikProps<AddOrUpdateSliderForm> = formRef.current;
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (isValid) {
      formObject.handleSubmit();
      setFormSubmitted();

      editSlider(getSliderUpdateModelByForm(formObject.values), Number(id))
        .then((): void => {
          addToast({ content: t('onarte.management.sliderEditForm.editSlider') });
          redirect(getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/');
        })
        .catch((error: ApiError): void => {
          addToast({ content: t(error.message) });
          logger('editSlider', error, 'error');
        });
    }
  };
  
  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.contentSliderEditForm.title'),
        buttonLabel: t('onarte.management.sliderEditForm.buttonLabel'),
        buttonAction: save,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentSlidersList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/' 
        },
        { 
          label: t('onarte.management.meta.contentSliderEditForm.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentSliderEditForm)?.url ?? '/' 
        },
      ]}
    >
      {!!sliderData && (
        <SliderForm
          initialValues={{
            header: sliderData.header,
            imageId: sliderData.image.id,
            description: sliderData.description,
            isButtonVisible: !!sliderData.buttonSettings,
            buttonLabel: sliderData.buttonSettings?.label,
            buttonPathType: sliderData.buttonSettings?.path.type,
            buttonPath: sliderData.buttonSettings?.path.value,
            isSecondButtonVisible: !!sliderData.secondButtonSettings,
            secondButtonLabel: sliderData.secondButtonSettings?.label,
            secondButtonPathType: sliderData.secondButtonSettings?.path.type,
            secondButtonPath: sliderData.secondButtonSettings?.path.value,
            isLinkedItemVisible: !!sliderData.linkedItemId,
            linkedItemId: sliderData.linkedItemId ?? ''
          }}
          isFormSubmitted={isFormSubmitted}
          ref={formRef}
          files={[transformAttachmentInterfaceToAddPhotoFile(sliderData.image)]}
        />
      )}
    </BaseView>
  );
};
