import {
  AttachmentInterface,
  AttachmentModel,
  AttachmentTypeEnum,
  AttributeNameEnum,
  AvailableDeliveryOptionInterface,
  CategoryAttributeInterface,
  CategoryWithAttributesInterface,
  DeliveryOptionInterface,
  ItemAttributeInterface,
  ItemAttributeModel,
  ItemAttributeTypeEnum,
  ItemWithChangesInterface,
  SimpleBlogPostInterface
} from '@on-arte/core-types';
import {
  AddPhotoFile,
  ApiError,
  DeliveryOptionsChoose,
  DropdownOption,
  FormFieldData,
  FormFieldType,
  FormFieldValue,
  FormSectionData,
  getSingleValueFromFormFieldValue,
  RelatedNews,
  SelectedNewsElement,
  SuggestionItem,
  UseFormikForm,
  useFormikForm,
  UseLogger,
  useLogger,
  UseNotifications,
  useNotifications,
  UseState
} from '@on-arte/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { BaseSchema } from 'yup';

import {
  editItemAvailableDeliveryOptions,
  editItemDetails,
  getItemAvailableDeliveryOptions,
  getItemCategories,
  getItemDetails
} from '@onArte/api';
import { ArtworkAddForm, BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions, useObjectsTransformations } from '@onArte/hooks';
import { AutosuggestActions, UseObjectsTranformations } from '@onArte/interfaces';
import { FormSectionWithTopSpace } from '@onArte/theme';
import { getRouteDetailsByName } from '@onArte/utils';

import { artworkDetailsForm } from './artworkEditForm.form';
import { FormContainer, StyledValidationBar } from './artworkEditForm.styled';

export const ArtworkEditFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const [deliveryOptions, setDeliveryOptions]: UseState<DropdownOption[]> = useState<DropdownOption[]>([]);
  const [selectedDeliveryOptionsIds, setSelectedDeliveryOptionsIds]: UseState<string[]> = useState<string[]>([]);
  const [itemData, setItemData]: UseState<ItemWithChangesInterface | null> = useState<ItemWithChangesInterface | null>(null);
  const [itemCategories, setItemCategories]: UseState<CategoryWithAttributesInterface[]> = useState<CategoryWithAttributesInterface[]>([]);
  const { setFormSubmitted, setFormNotSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const navigate: NavigateFunction = useNavigate();
  const [selectedCategoryAttributes, setSelectedCategoryAttributes]: UseState<CategoryAttributeInterface[]>
  = useState<CategoryAttributeInterface[]>([]);
  const [formValues, setFormValues]: UseState<Record<string, FormFieldValue>> = useState<Record<string, FormFieldValue>>({});
  const [formValidationSchema, setFormValidationSchema]: UseState<BaseSchema | null> = useState<BaseSchema | null>(null);
  const [selectedBlogPosts, setSelectedBlogPosts]: UseState<SelectedNewsElement[]> = useState<SelectedNewsElement[]>([]);
  const {
    transformAttachmentInterfaceToAddPhotoFile,
    transformAddPhotoFileToAttachmentModel
  }: UseObjectsTranformations = useObjectsTransformations();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const { searchBlogPosts }: AutosuggestActions = useAutosuggestActions();

  useQuery(
    [QueryKey.ItemCategories],
    (): Promise<CategoryWithAttributesInterface[]> => getItemCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => setItemCategories(data),
      onError: (error: ApiError): void => logger(QueryKey.ItemCategories, error, 'error')
    }
  );

  useQuery(
    [QueryKey.ArtworkDetails],
    (): Promise<ItemWithChangesInterface> => getItemDetails(id ?? ''),
    {
      onSuccess: (data: ItemWithChangesInterface): void => {
        setItemData(data);
        setSelectedBlogPosts(data.blogPosts.map((post: SimpleBlogPostInterface): SelectedNewsElement => ({
          id: String(post.id),
          label: post.title
        })));
        setSelectedDeliveryOptionsIds(data.deliveryOptions.map((option: DeliveryOptionInterface): string => option.id.toString()));
      },
      onError: (error: ApiError): void => logger(QueryKey.ArtworkDetails, error, 'error')
    }
  );

  useQuery(
    [],
    (): Promise<AvailableDeliveryOptionInterface[]> => getItemAvailableDeliveryOptions(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: AvailableDeliveryOptionInterface[]): void => {
        setDeliveryOptions(data.map((option: AvailableDeliveryOptionInterface): DropdownOption => ({
          name: option.id.toString(), label: `${t(option.name)} - ${option.price}`
        })));
      },
      onError: (error: ApiError): void => {
        logger(QueryKey.EventDetails, error, 'error');
        addToast({ content: t(error.message) });
        navigate(getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/');
      },
    }
  );

  useEffect(
    (): void => {
      const categoryId: number = parseInt(getSingleValueFromFormFieldValue<string>(itemData?.category?.id?.toString() ?? ''), 10);
      const categoryAttributes: CategoryAttributeInterface[] = itemCategories
        .find((category: CategoryWithAttributesInterface): boolean => category.id === categoryId)
        ?.attributes ?? [];
      setSelectedCategoryAttributes(categoryAttributes);
    },
    [itemData, itemCategories]
  );

  const handleFormChange: (values: Record<string, FormFieldValue | AddPhotoFile[]>) => void = (
    values: Record<string, FormFieldValue | AddPhotoFile[]>
  ): void => setFormValues(values);

  const getAttributeValueByName = (attributeName: string): FormFieldValue => {
    const attribute: CategoryAttributeInterface | undefined = selectedCategoryAttributes
      .find((item: CategoryAttributeInterface): boolean => item.name === attributeName);
    
    if (attribute === undefined) return '';
    else {
      const value: string = itemData?.attributes.find((item: ItemAttributeInterface) => item.attributeId === attribute.id)?.value ?? '';

      return attribute.type === ItemAttributeTypeEnum.MultipleChoice
        ? value.split(',').filter((splitedValue: string): boolean => splitedValue !== '')
        : value;
    }
  };

  const validationSchemaUpdate: (schemaObject: BaseSchema) => void = (
    schemaObject: BaseSchema
  ): void => setFormValidationSchema(schemaObject);

  const save: () => Promise<void> = async (): Promise<void> => {
    setFormNotSubmitted();
    setTimeout((): void => setFormSubmitted(), 0);
    if (formValidationSchema && !!selectedDeliveryOptionsIds.length) {
      const isValid: boolean = formValidationSchema.isValidSync(formValues);
      if (isValid) {
        if (!id || !itemData) {
          return;
        }

        const selectedCategoryId: number = parseInt(getSingleValueFromFormFieldValue<string>(formValues['category']), 10);
        const attributesFromCategory: CategoryAttributeInterface[] = itemCategories
          .find((category: CategoryWithAttributesInterface): boolean => category.id === selectedCategoryId)
          ?.attributes ?? [];
        let attachments: AttachmentModel[] = [];
        const attributes: ItemAttributeModel[] = [];

        artworkDetailsForm.forEach((section: FormSectionData): void => {
          section.children.forEach((field: FormFieldData): void => {
            if (field.type === FormFieldType.AddPhoto && formValues[field.name] !== undefined && Array.isArray(formValues[field.name])) {
              const clearAttachments: AttachmentModel[] = (formValues[field.name] as AddPhotoFile[])
                .map((item: AddPhotoFile): AttachmentModel => transformAddPhotoFileToAttachmentModel(item));
              attachments = [...attachments, ...clearAttachments];
            }
          });
        });

        attributesFromCategory
          .filter((attr: CategoryAttributeInterface): boolean => formValues[attr.name] !== undefined)
          .forEach((attr: CategoryAttributeInterface): void => {
            if (Array.isArray(formValues[attr.name]) && (formValues[attr.name] as [])?.length > 1) {
              attributes.push({
                attributeId: attr.id,
                value: (formValues[attr.name] as string[]).map((value: string): string => value).join(','),
              });
            } else if (getSingleValueFromFormFieldValue<string>(formValues[attr.name]) !== '') {
              attributes.push({
                attributeId: attr.id,
                value: getSingleValueFromFormFieldValue<string>(formValues[attr.name])
              });
            }
          });

        const manufacturerValue: SuggestionItem = getSingleValueFromFormFieldValue<SuggestionItem>(formValues['manufacturer']);
        Promise.allSettled([
          new Promise<void>((
            editItemDeliveryResolve: (() => void),
            editItemDeliveryReject: ((error: string) => void)
          ): void => {
            editItemAvailableDeliveryOptions(
              id,
              { selected: selectedDeliveryOptionsIds.map((value: string): number => parseInt(value, 10)) }
            )
              .then((): void => editItemDeliveryResolve())
              .catch((error: ApiError): void => editItemDeliveryReject(error.message));
          }),
          new Promise<void>((
            editItemDetailsResolve: (() => void),
            editItemDetailsReject: ((error: string) => void)
          ): void => {
            editItemDetails(
              id,
              {
                attributes,
                attachments,
                type: itemData.type,
                categoryId: selectedCategoryId,
                name: getSingleValueFromFormFieldValue<string>(formValues['name']),
                year: getSingleValueFromFormFieldValue<string>(formValues['year']),
                manufacturer: {
                  name: manufacturerValue.label,
                  ...(manufacturerValue.id ? { id: manufacturerValue.id } : {}),
                },
                locationId: itemData.location.id,
                description: getSingleValueFromFormFieldValue<string>(formValues['description']),
                curatorialCommentary: getSingleValueFromFormFieldValue<string>(formValues['curatorialCommentary']),
                ownerId: itemData?.owner.id ?? '',
                blogPostIds: selectedBlogPosts.map((blogPost: SelectedNewsElement): number => Number(blogPost.id))
              }
            )
              .then((): void => editItemDetailsResolve())
              .catch((error: ApiError): void => editItemDetailsReject(error.message));
          }),
        ])
          .then((operationsStatuses: PromiseSettledResult<void>[]): void => {
            if (operationsStatuses.find((operationStatus: PromiseSettledResult<void>): boolean => operationStatus.status === 'rejected')) {
              operationsStatuses.forEach((operationStatus: PromiseSettledResult<void>): void => {
                if ((operationStatus as PromiseRejectedResult).reason) {
                  addToast({ content: t((operationStatus as PromiseRejectedResult).reason) });
                }
              });
            } else {
              addToast({ content: t('onarte.management.artworkEditForm.saveSuccess', { name: itemData.name }) });
            }
          })
          .finally((): void => {
            navigate(getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/');
          });
      }
    }
  };

  const formFiles: Record<string, AddPhotoFile[]> = useMemo(
    (): Record<string, AddPhotoFile[]> => {
      if (!itemData) {
        return {
          artworkPhotos: [],
          signaturePhoto: [],
          authenticityCertificatePhoto: [],
        };
      }

      return {
        artworkPhotos: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemPhoto)
          .map(transformAttachmentInterfaceToAddPhotoFile),
        signaturePhoto: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemSignature)
          .map(transformAttachmentInterfaceToAddPhotoFile),
        authenticityCertificatePhoto: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemCertificate)
          .map(transformAttachmentInterfaceToAddPhotoFile),
      };
    },
    [itemData?.attachments]
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: itemData?.name ?? '',
        buttonLabel: t('onarte.common.cancel'),
        buttonAction: (): void => navigate(getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/'),
        secondButtonLabel: t('onarte.management.authorsAddForm.secondButtonLabel'),
        secondButtonAction: save,
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.artworksList.title'), path: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/' },
        { label: itemData?.manufacturer.name ?? '', path: '/' },
      ]}
    >
      {!!(itemCategories.length && itemData && selectedCategoryAttributes.length) && (
        <FormContainer>
          <ArtworkAddForm
            initialValues={{
              manufacturer: {
                id: itemData.manufacturer.id,
                label: itemData.manufacturer.name,
              },
              name: itemData.name,
              year: itemData.year?.toString() ?? '',
              category: [itemData.category.id.toString()],
              description: itemData.description ?? '',
              curatorialCommentary: itemData.curatorialCommentary ?? '',
              artworkPhotos: formFiles.artworkPhotos,
              signaturePhoto: formFiles.signaturePhoto,
              authenticityCertificatePhoto: formFiles.authenticityCertificatePhoto,
              [AttributeNameEnum.SerialNumber]: getAttributeValueByName(AttributeNameEnum.SerialNumber),
              [AttributeNameEnum.SignedByArtist]: getAttributeValueByName(AttributeNameEnum.SignedByArtist),
              [AttributeNameEnum.SignatureDescription]: getAttributeValueByName(AttributeNameEnum.SignatureDescription),
              [AttributeNameEnum.Height]: getAttributeValueByName(AttributeNameEnum.Height),
              [AttributeNameEnum.Width]: getAttributeValueByName(AttributeNameEnum.Width),
              [AttributeNameEnum.Depth]: getAttributeValueByName(AttributeNameEnum.Depth),
              [AttributeNameEnum.Weight]: getAttributeValueByName(AttributeNameEnum.Weight),
              [AttributeNameEnum.IsFramed]: getAttributeValueByName(AttributeNameEnum.IsFramed),
              [AttributeNameEnum.HeightWithFrame]: getAttributeValueByName(AttributeNameEnum.HeightWithFrame),
              [AttributeNameEnum.WidthWithFrame]: getAttributeValueByName(AttributeNameEnum.WidthWithFrame),
              [AttributeNameEnum.DepthWithFrame]: getAttributeValueByName(AttributeNameEnum.DepthWithFrame),
              [AttributeNameEnum.FrameMaterial]: getAttributeValueByName(AttributeNameEnum.FrameMaterial),
              [AttributeNameEnum.Medium]: getAttributeValueByName(AttributeNameEnum.Medium),
              [AttributeNameEnum.Technique]: getAttributeValueByName(AttributeNameEnum.Technique),
              [AttributeNameEnum.TechniqueOther]: getAttributeValueByName(AttributeNameEnum.TechniqueOther),
              [AttributeNameEnum.Material]: getAttributeValueByName(AttributeNameEnum.Material),
              [AttributeNameEnum.MaterialOther]: getAttributeValueByName(AttributeNameEnum.MaterialOther),
              [AttributeNameEnum.Style]: getAttributeValueByName(AttributeNameEnum.Style),
              [AttributeNameEnum.StyleOther]: getAttributeValueByName(AttributeNameEnum.StyleOther),
              [AttributeNameEnum.Subject]: getAttributeValueByName(AttributeNameEnum.Subject),
              [AttributeNameEnum.SubjectOther]: getAttributeValueByName(AttributeNameEnum.SubjectOther),
              [AttributeNameEnum.Color]: getAttributeValueByName(AttributeNameEnum.Color),
              [AttributeNameEnum.AuthenticityCertificate]: getAttributeValueByName(AttributeNameEnum.AuthenticityCertificate),
            }}
            onValuesChange={handleFormChange}
            categories={itemCategories}
            isFormSubmitted={isFormSubmitted}
            formStructure={artworkDetailsForm}
            files={formFiles}
            validationSchemaUpdate={validationSchemaUpdate}
          />
          <FormSectionWithTopSpace title={t('onarte.management.artworkEditForm.magazine')}>
            <RelatedNews 
              selectedElements={selectedBlogPosts}
              onSelectedElementsChange={setSelectedBlogPosts}
              onValueInputChangeAsync={searchBlogPosts}
            />
          </FormSectionWithTopSpace>
          <FormSectionWithTopSpace title={t('onarte.management.artworkEditForm.delivery')}>
            <DeliveryOptionsChoose
              options={deliveryOptions}
              onChange={setSelectedDeliveryOptionsIds}
              activeOptions={selectedDeliveryOptionsIds}
            />
            <StyledValidationBar
              message={(isFormSubmitted && !selectedDeliveryOptionsIds.length)
                ? t('onarte.common.requiredField')
                : ''
              }
            />
          </FormSectionWithTopSpace>
        </FormContainer>
      )}
    </BaseView>
  );
};
