import { Color, StyledComponent, ValidationBar } from '@on-arte/ui';
import styled from 'styled-components';

export const FormContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.White};
  padding: 0 0 200px;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 12px;
`;
