import { useNotifications, UseNotifications } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';

import { revokeEvent } from '@onArte/api';
import { QueryKey } from '@onArte/enums';

import { EventsListMethods } from './eventsList.types';

export const useEventsListMethods: () => EventsListMethods = (): EventsListMethods => {
  const { t }: TransProps<never> = useTranslation();
  const queryClient: QueryClient = useQueryClient();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();

  const removeItem: (itemId: string, itemName: string) => void = (itemId: string, itemName: string): void => {
    showSmallDialog({
      header: t('onarte.management.notifications.eventRevocation.header'),
      content: t('onarte.management.notifications.eventRevocation.content', { itemName }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          revokeEvent(itemId)
            .then((): void => {
              hideSmallDialog();
              addToast({ content: t('onarte.management.notifications.eventRevocation.success', { itemName }) });
              void queryClient.invalidateQueries(QueryKey.EventsList);
            })
            .catch((): void => {
              addToast({ content: t('onarte.management.notifications.eventRevocation.failure', { itemName }) });
            });
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel'),
      },
    });
  };

  return { removeItem };
};
