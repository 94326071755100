import { Navigate, useLocation, Location, useMatch } from 'react-router-dom';

import { plRouting } from '@onArte/constants';
import { RouteNameEnum } from '@onArte/enums';
import { useAuth } from '@onArte/hooks';
import { RouteInfo, UseAuth } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

import { CheckAuthProps } from './checkAuth.types';

export const CheckAuth: React.FC<CheckAuthProps> = (props: CheckAuthProps): JSX.Element => {
  const { children }: CheckAuthProps = props;
  const { token }: UseAuth = useAuth();
  const location: Location = useLocation();
  const pathObject: RouteInfo[] = plRouting.filter(
    (route: RouteInfo): boolean => !!useMatch(route.url) && route.name !== RouteNameEnum.NotFound
  );

  if (!token && pathObject[0]?.secured) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/'} state={{ from: location }} replace />
    );
  }

  if (!!token && location.pathname === getRouteDetailsByName(RouteNameEnum.SignIn)?.url) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.OrdersList)?.url ?? '/'} replace />
    );
  }

  return (
    <>{children}</>
  );
};
