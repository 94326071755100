import { UseState, useLogger, UseLogger, ElementsTable, PaginationDetails, usePagination, ApiError } from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getSellersReports } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

export const ReportsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const [sellersReport, setSellersReport]: UseState<string> = useState<string>('');
  const { setPage, maxItems, page }: PaginationDetails = usePagination(10, true);

  useQuery(
    [QueryKey.ReportsList],
    (): Promise<string> => getSellersReports(),
    {
      onSuccess: (data: string): void => {
        setSellersReport(window.URL.createObjectURL(new Blob([data])));
      },
      onError: (error: ApiError): void => logger(QueryKey.ReportsList, error, 'error')
    }
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.reportsList.title'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.reportsList.title'), path: getRouteDetailsByName(RouteNameEnum.ReportsList)?.url ?? '/' },
      ]}
    >
      <ElementsTable
        columns={[
          { name: 'reportsTitle', label: t('onarte.management.reportsList.tableColumns.reportsTitle') },
          { name: 'reportsLink', label: '' }
        ]}
        rows={[
          {
            cells: [
              { name: 'reportsTitile', content: t('onarte.management.reportsList.sellersReport') },
              {
                name: 'reportsLink',
                content: t('onarte.management.reportsList.download'),
                linkData: { download: 'dane_sprzedawcow_do_wyplaty.csv', externalUrl: sellersReport }
              }
            ],
          },
        ]}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        initialPageCount={page}
      />
    </BaseView>
  );
};
