import { ListPaginationInterface, OrderDetailsDTO, OrderListElementDTO, OrderListQuery } from '@on-arte/core-types';
import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { OrderCourierData } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const getOrders: (
  params: Partial<OrderListQuery>
) => Promise<ListPaginationInterface<OrderListElementDTO>> = (
  params: Partial<OrderListQuery>
): Promise<ListPaginationInterface<OrderListElementDTO>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<OrderListElementDTO>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/orders?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<OrderListElementDTO>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getOrderDetails: (orderId: string) => Promise<OrderDetailsDTO> = (
  orderId: string
): Promise<OrderDetailsDTO> => new Promise(
  (resolve: ApiResolve<OrderDetailsDTO>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/orders/${orderId}`).then(
      (response: AxiosResponse<OrderDetailsDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const setParcelInAuctionAsDelivered: (orderId: string, auctionId: string) => Promise<void> = (
  orderId: string, auctionId: string
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/orders/${orderId}/auctions/${auctionId}/delivered`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const orderCourier: (orderId: string, auctionId: string, data: OrderCourierData) => Promise<void> = (
  orderId: string, auctionId: string, data: OrderCourierData
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/orders/${orderId}/auctions/${auctionId}/courier`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
