import { Breadcrumbs, Color, PageTitle, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: calc(100% - 87px);
  background-color: ${Color.White};
  min-height: 100vh;
  padding: 32px 80px 100px;
  margin-left: 87px;
`;

export const StyledBreadcrumbs: StyledComponent<typeof Breadcrumbs> = styled(Breadcrumbs)`
  padding: 0 0 85px;
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 32px;
`;
