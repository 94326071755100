import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import plTranslations from './resources/pl_PL.json';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const resources: Resource = { pl: { translation: plTranslations } };
const show: boolean = false;
void i18n
  .use(initReactI18next)
  .use({
    type: 'postProcessor',
    name: 'emptyKey',
    process: (value: string, keys: string[]): string => {
      if (!keys[0].match(/^([a-zA-Z0-9_]+\.{1}[a-zA-Z0-9_]+)+$/)) {
        return keys[0];
      }
      if (!!keys && keys[0].length !== 0 && value.length === 0) {
        return `No translation (${keys[0]})`;
      }
      if (!!keys && keys[0].length !== 0 && value.length !== 0) {
        if (show) {
          return `${value}(${keys[0]})`;
        }

        return value;
      }

      return '';
    },
  })
  .init({
    resources,
    debug: false,
    lng: 'pl',
    supportedLngs: ['pl'],
    preload: ['pl'],
    postProcess: ['emptyKey'],
  });

export default i18n;
