import { WebsiteSliderTypeEnum } from '@on-arte/core-types';
import React from 'react';

import { RouteNameEnum } from '@onArte/enums';

import { RecommendationsBaseView } from '../components';

export const RecommendationsContactEditFormView: React.FC = (): JSX.Element => {
  return (
    <RecommendationsBaseView 
      type={WebsiteSliderTypeEnum.RecommendationsContact} 
      routeNameEnum={RouteNameEnum.ContentRecommendationsContactEditForm} 
      metaTitle='onarte.management.meta.contentRecommendationsContactEditForm.title'
    />
  );
};
