export enum QueryKey {
  AuctionsList = 'auctionsList',
  AuthorDetails = 'authorDetails',
  AuthorsList = 'authorsList',
  ArtworksList = 'artworksList',
  ArtworkDetails = 'artworkDetails',
  BlogPosts = 'blogPosts',
  CollectionDetails = 'collectionDetails',
  CollectionsList = 'collectionsList',
  ItemCategories = 'itemCategories',
  EventsList = 'eventsList',
  EventDetails = 'eventDetails',
  OrangeStripeDetails = 'orangeStripeDetails',
  OrderDetails = 'orderDetails',
  OrdersList = 'ordersList',
  PublicProfileDetails = 'publicProfileDetails',
  PublicProfilesList = 'publicProfilesList',
  ReportsList = 'reportsList',
  SliderDetails = 'sliderDetails',
  SlidersList = 'slidersList',
  UsersList = 'usersList',
  UserData = 'userData'
}
