import {
  ListPaginationInterface,
  PublicProfileActivityUpdateManagementModel,
  PublicProfileCreateManagementModel,
  PublicProfileHighlightedUpdateManagementModel,
  PublicProfileListElementInterface,
  PublicProfileManagementModel,
  PublicProfileUpdateManagementModel,
  PublicProfileVerifyManagementModel
} from '@on-arte/core-types';
import { ApiError, DirectionEnum } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { PublicProfilesListParams } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const getPublicProfiles: (
  params: Partial<PublicProfilesListParams>
) => Promise<ListPaginationInterface<PublicProfileListElementInterface>> = (
  params: Partial<PublicProfilesListParams>
): Promise<ListPaginationInterface<PublicProfileListElementInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<PublicProfileListElementInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/public-profiles?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<PublicProfileListElementInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updatePublicProfilesSettings: (
  profileId: string, data: PublicProfileHighlightedUpdateManagementModel
) => Promise<void> = (
  profileId: string, data: PublicProfileHighlightedUpdateManagementModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/public-profiles/${profileId}/highlighted`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updatePublicProfileSortIndex: (
  profileId: string, direction: DirectionEnum
) => Promise<void> = (
  profileId: string, direction: DirectionEnum
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/public-profiles/${profileId}/sort/${direction}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getPublicProfileDetails: (profileId: string) => Promise<PublicProfileManagementModel> = (
  profileId: string
): Promise<PublicProfileManagementModel> => new Promise(
  (resolve: ApiResolve<PublicProfileManagementModel>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/public-profiles/${profileId}`).then(
      (response: AxiosResponse<PublicProfileManagementModel>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const createPublicProfile: (data: PublicProfileCreateManagementModel) => Promise<PublicProfileManagementModel> = (
  data: PublicProfileCreateManagementModel
): Promise<PublicProfileManagementModel> => new Promise(
  (resolve: ApiResolve<PublicProfileManagementModel>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post('/public-profiles', data).then(
      (response: AxiosResponse<PublicProfileManagementModel>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updatePublicProfile: (
  profileId: string, data: PublicProfileUpdateManagementModel
) => Promise<PublicProfileManagementModel> = (
  profileId: string, data: PublicProfileUpdateManagementModel
): Promise<PublicProfileManagementModel> => new Promise(
  (resolve: ApiResolve<PublicProfileManagementModel>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/public-profiles/${profileId}`, data).then(
      (response: AxiosResponse<PublicProfileManagementModel>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const verifyPublicProfileVersion: (
  profileId: string, data: PublicProfileVerifyManagementModel
) => Promise<PublicProfileManagementModel> = (
  profileId: string, data: PublicProfileVerifyManagementModel
): Promise<PublicProfileManagementModel> => new Promise(
  (resolve: ApiResolve<PublicProfileManagementModel>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/public-profiles/verify/${profileId}`, data).then(
      (response: AxiosResponse<PublicProfileManagementModel>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const changePublicProfileActiveStatus: (
  profileId: string, data: PublicProfileActivityUpdateManagementModel
) => Promise<PublicProfileManagementModel> = (
  profileId: string, data: PublicProfileActivityUpdateManagementModel
): Promise<PublicProfileManagementModel> => new Promise(
  (resolve: ApiResolve<PublicProfileManagementModel>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/public-profiles/${profileId}/activity`, data).then(
      (response: AxiosResponse<PublicProfileManagementModel>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
