import { Container, StyledBreadcrumbs, StyledPageTitle } from './baseView.styled';
import { BaseViewProps } from './baseView.types';

export const BaseView: React.FC<BaseViewProps> = (props: BaseViewProps): JSX.Element => {
  const { breadcrumbs, children, pageTitleSettings, pageTitleChildren }: BaseViewProps = props;

  return (
    <Container>
      <StyledBreadcrumbs breadcrumbs={breadcrumbs} />
      <StyledPageTitle pageTitleSettings={pageTitleSettings}>{pageTitleChildren}</StyledPageTitle>
      {children}
    </Container>
  );
};
