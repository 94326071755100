import { UseLocalStorage, useLocalStorage } from '@on-arte/ui';
import { useContext } from 'react';
import { useLocation, Location, useNavigate, NavigateFunction } from 'react-router-dom';

import { logoutRequest } from '@onArte/api';
import { AuthContext } from '@onArte/contexts';
import { AuthReducerAction, LocalStorageKey, RouteNameEnum } from '@onArte/enums';
import { AuthContextState, UseAuth, User } from '@onArte/interfaces';
import { AuthContextData } from '@onArte/types';
import { getRouteDetailsByName } from '@onArte/utils';

export const useAuth: () => UseAuth = (): UseAuth => {
  const [state, dispatch]: AuthContextData = useContext(AuthContext);
  const [, storeAuthData]: UseLocalStorage<AuthContextState | null> = useLocalStorage<AuthContextState | null>(
    LocalStorageKey.AuthData, null
  );
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-explicit-any
  const pathnameAfterSignIn: string = (location.state as any)?.from?.pathname || getRouteDetailsByName(
    RouteNameEnum.OrdersList
  )?.url;

  const signIn: (
    userData: User, token: string, tokenExpiration: number, pathnameToRedirect?: string
  ) => void = (
    userData: User, token: string, tokenExpiration: number, pathnameToRedirect?: string
  ): void => {
    dispatch({ type: AuthReducerAction.SignIn, payload: { userData, token, tokenExpiration } });
    storeAuthData({ userData, token, tokenExpiration });
    navigate(pathnameToRedirect ?? pathnameAfterSignIn);
  };

  const signOut: () => void = (): void => {
    const logoutCleanup: () => void = (): void => {
      dispatch({ type: AuthReducerAction.SignOut, payload: null });
      storeAuthData(null);
      navigate(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/');
    };

    void logoutRequest()
      .then((): void => logoutCleanup())
      .catch((): void => logoutCleanup());
  };

  return {
    signIn,
    signOut,
    token: state?.token ?? null,
    userData: state?.userData ?? null
  };
};
