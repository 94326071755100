import { PublicProfileTypeEnum } from '@on-arte/core-types';
import { SuggestionItem } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { EditPublicProfileFormData } from '@onArte/interfaces';

export const usePublicProfileEditValidation: () => Yup.SchemaOf<EditPublicProfileFormData>
  = (): Yup.SchemaOf<EditPublicProfileFormData> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      name: Yup.string()
        .required(t('onarte.common.requiredField')),
      relationId: Yup.object()
        .shape({
          id: Yup.string(),
          label: Yup.string(),
        })
        .required(t('onarte.common.requiredField')) as Yup.SchemaOf<SuggestionItem>,
      type: Yup.mixed<PublicProfileTypeEnum>()
        .oneOf(Object.values(PublicProfileTypeEnum))
        .required(t('onarte.common.requiredField')),
      description: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(2, t('onarte.common.requiredField'))
        .max(350, t('onarte.common.validations.maxLength')),
      bio: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(2, t('onarte.common.requiredField'))
        .max(2048, t('onarte.common.validations.maxLength')),
      avatar: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(t('onarte.common.requiredField')),
            type: Yup.string().required(t('onarte.common.requiredField')),
            path: Yup.string().required(t('onarte.common.requiredField')),
            name: Yup.string().required(t('onarte.common.requiredField')),
            objectName: Yup.string().defined().nullable(),
          })
        )
        .min(1, t('onarte.common.requiredField'))
        .required(t('onarte.common.requiredField')),
      cover: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(t('onarte.common.requiredField')),
            type: Yup.string().required(t('onarte.common.requiredField')),
            path: Yup.string().required(t('onarte.common.requiredField')),
            name: Yup.string().required(t('onarte.common.requiredField')),
            objectName: Yup.string().defined().nullable(),
          })
        )
        .min(1, t('onarte.common.requiredField'))
        .required(t('onarte.common.requiredField')),
      galleryPhoto: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(t('onarte.common.requiredField')),
            type: Yup.string().required(t('onarte.common.requiredField')),
            path: Yup.string().required(t('onarte.common.requiredField')),
            name: Yup.string().required(t('onarte.common.requiredField')),
            objectName: Yup.string().defined().nullable(),
          })
        )
        .min(1, t('onarte.common.requiredField'))
        .required(t('onarte.common.requiredField'))
    });
  };
