import { GlobalStyle, NotificationsProvider, UseLocalStorage, useLocalStorage } from '@on-arte/ui';
import React, { useMemo, useReducer } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';

import { AppWrapper } from '@onArte/components';
import { CheckAuth } from '@onArte/components/checkAuth/checkAuth.component';
import { AuthContext } from '@onArte/contexts';
import { LocalStorageKey, RouteNameEnum } from '@onArte/enums';
import { AuthContextState } from '@onArte/interfaces';
import { authReducer } from '@onArte/reducers';
import { AuthContextData } from '@onArte/types';
import { getRouteDetailsByName } from '@onArte/utils';
import {
  AuthorsAddFormView,
  AuthorsListView,
  ArtworkDetailsView,
  ArtworkEditChangesView,
  ArtworkEditFormView,
  ArtworksListView,
  DefaultView,
  NotFoundView,
  SignInView,
  EventsListView,
  EventAddFormView,
  EventAuctionsEditView,
  LogoutView,
  OrdersListView,
  OrderDetailsView,
  UsersListView,
  UserEditFormView,
  CollectionsListView,
  CollectionAddFormView,
  CollectionEditFormView,
  PublicProfilesListView,
  PublicProfileDetailsView,
  CollectionItemsFormView,
  SliderAddFormView,
  SliderEditFormView,
  SlidersListView,
  RecommendationsHomeEditFormView,
  RecommendationsArtistsEditFormView,
  RecommendationsCollectorsEditFormView,
  RecommendationsContactEditFormView,
  AuthorEditFormView,
  HighlightedBiddingListView,
  HighlightedPublicProfilesListView,
  HighlightedBuyNowListView,
  OrangeStripeEditFormView,
  PublicProfileAddFormView,
  PublicProfileEditFormView,
  PublicProfileVerifyChangesView,
  ReportsListView
} from '@onArte/views';

const App: React.FC = (): JSX.Element => {
  const queryClient: QueryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 1 } },
  });
  const [authData]: UseLocalStorage<AuthContextState> = useLocalStorage<AuthContextState>(
    LocalStorageKey.AuthData, { userData: null, token: null, tokenExpiration: null }
  );
  const [authState, authDispatch]: AuthContextData = useReducer(
    authReducer,
    authData
      ? { userData: authData.userData, token: authData.token, tokenExpiration: authData.tokenExpiration }
      : { userData: null, token: null, tokenExpiration: null},
  );
  const valueForAuthContext: AuthContextData = useMemo((): AuthContextData => [authState, authDispatch], [authState]);

  return (
    <AuthContext.Provider value={valueForAuthContext}>
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <NotificationsProvider>
            <GlobalStyle />
            <Routes>
              <Route path='/' element={
                <CheckAuth>
                  {!authState.token ? <SignInView /> : <OrdersListView />}
                </CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url} element={
                <CheckAuth><ArtworkDetailsView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ArtworkEditChanges)?.url} element={
                <CheckAuth><ArtworkEditChangesView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ArtworkEditForm)?.url} element={
                <CheckAuth><ArtworkEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url} element={
                <CheckAuth><ArtworksListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url} element={
                <CheckAuth><AuthorsListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.AuthorsAddForm)?.url} element={
                <CheckAuth><AuthorsAddFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.AuthorEditForm)?.url} element={
                <CheckAuth><AuthorEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url} element={
                <CheckAuth><CollectionsListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentCollectionAddForm)?.url} element={
                <CheckAuth><CollectionAddFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentCollectionEditForm)?.url} element={
                <CheckAuth><CollectionEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentCollectionItemsForm)?.url} element={
                <CheckAuth><CollectionItemsFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url} element={
                <CheckAuth><SlidersListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentSliderAddForm)?.url} element={
                <CheckAuth><SliderAddFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentSliderEditForm)?.url} element={
                <CheckAuth><SliderEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentOrangeStripeEditForm)?.url} element={
                <CheckAuth><OrangeStripeEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentRecommendationsHomeEditForm)?.url} element={
                <CheckAuth><RecommendationsHomeEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentRecommendationsArtistsEditForm)?.url} element={
                <CheckAuth><RecommendationsArtistsEditFormView /></CheckAuth>
              } />
              {/* <Route path={getRouteDetailsByName(RouteNameEnum.ContentRecommendationsCollectorsEditForm)?.url} element={
                <CheckAuth><RecommendationsCollectorsEditFormView /></CheckAuth>
              } /> */}
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentRecommendationsContactEditForm)?.url} element={
                <CheckAuth><RecommendationsContactEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentHighlightedBiddingList)?.url} element={
                <CheckAuth><HighlightedBiddingListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentHighlightedBuyNowList)?.url} element={
                <CheckAuth><HighlightedBuyNowListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentHighlightedPublicProfilesList)?.url} element={
                <CheckAuth><HighlightedPublicProfilesListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentTextPages)?.url} element={
                <CheckAuth><DefaultView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ContentFiles)?.url} element={
                <CheckAuth><DefaultView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.EventAddForm)?.url} element={
                <CheckAuth><EventAddFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.EventEditForm)?.url} element={
                <CheckAuth><EventAddFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.EventAuctionsEdit)?.url} element={
                <CheckAuth><EventAuctionsEditView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.EventsList)?.url} element={
                <CheckAuth><EventsListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.Logout)?.url} element={
                <CheckAuth><LogoutView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.OrderDetails)?.url} element={
                <CheckAuth><OrderDetailsView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.OrdersList)?.url} element={
                <CheckAuth><OrdersListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.PublicProfileAddForm)?.url} element={
                <CheckAuth><PublicProfileAddFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.PublicProfileEditForm)?.url} element={
                <CheckAuth><PublicProfileEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.PublicProfileDetails)?.url} element={
                <CheckAuth><PublicProfileDetailsView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url} element={
                <CheckAuth><PublicProfilesListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.PublicProfileVerifyChanges)?.url} element={
                <CheckAuth><PublicProfileVerifyChangesView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.ReportsList)?.url} element={
                <CheckAuth><ReportsListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.Sale)?.url} element={
                <CheckAuth><DefaultView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.Settings)?.url} element={
                <CheckAuth><DefaultView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.SignIn)?.url} element={
                <CheckAuth><SignInView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.UserEditForm)?.url} element={
                <CheckAuth><UserEditFormView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.UsersList)?.url} element={
                <CheckAuth><UsersListView /></CheckAuth>
              } />
              <Route path={getRouteDetailsByName(RouteNameEnum.NotFound)?.url} element={<NotFoundView />} />
            </Routes>
          </NotificationsProvider>
        </AppWrapper>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
};

export default App;
