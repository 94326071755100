import { Button, Color, Input, Logo, StyledComponent, ValidationBar } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${Color.White};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 408px;
  max-width: 100%;
  margin: 0 20px;
`;

export const StyledLogo: StyledComponent<typeof Logo> = styled(Logo)`
  margin-bottom: 36px;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 24px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 24px;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar>  = styled(ValidationBar)`
  margin-top: 12px;
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
`;
