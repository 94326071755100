import { CategoryWithAttributesInterface, ItemWithChangesInterface } from '@on-arte/core-types';
import { DetailsTable, TableDetailsRowData, UseState } from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getItemCategories, getItemDetails } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useArtworkTableDetails } from '@onArte/hooks';
import { UseArtworkTableDetails } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

export const ArtworkDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const [itemData, setItemData]: UseState<ItemWithChangesInterface | null> = useState<ItemWithChangesInterface | null>(null);
  const [itemCategories, setItemCategories]: UseState<CategoryWithAttributesInterface[]> = useState<CategoryWithAttributesInterface[]>([]);
  const { getTableRows }: UseArtworkTableDetails = useArtworkTableDetails();

  useQuery(
    [QueryKey.ItemCategories],
    (): Promise<CategoryWithAttributesInterface[]> => getItemCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => setItemCategories(data),
      // TODO: add logger
      onError: () => undefined
    }
  );

  useQuery(
    [QueryKey.ArtworkDetails],
    (): Promise<ItemWithChangesInterface> => getItemDetails(id ?? ''),
    {
      onSuccess: (data: ItemWithChangesInterface): void => setItemData(data),
      // TODO: add logger
      onError: () => undefined
    }
  );

  const tableData: TableDetailsRowData[] = useMemo(
    (): TableDetailsRowData[] => {
      if (!itemData || !itemCategories.length) {
        return [];
      }

      return getTableRows(itemData, itemCategories);
    },
    [itemData, itemCategories]
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: itemData?.manufacturer.name ?? ''
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.artworksList.title'), path: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/' },
        { label: itemData?.manufacturer.name ?? '', path: '/' },
      ]}
    >
      <DetailsTable rows={tableData} />
    </BaseView>
  );
};
