import { ManufacturerUpdateModel } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useAuthorEditFormValidation: () => Yup.SchemaOf<ManufacturerUpdateModel> = (): Yup.SchemaOf<ManufacturerUpdateModel> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    name: Yup.string()
      .required(t('onarte.common.requiredField')),
    active: Yup.boolean()
      .required(t('onarte.common.requiredField')),
    userId: Yup.string().optional(),
  });
};
