import { plRouting } from '@onArte/constants';
import { RouteNameEnum } from '@onArte/enums';
import { RouteInfo } from '@onArte/interfaces';

export function getRouteDetailsByName(routeName: RouteNameEnum): RouteInfo | undefined {
  return plRouting.find((route: RouteInfo) => route.name === routeName);
}

export function getRouteDetailsByUrl(url: string): RouteInfo | undefined {
  return plRouting.find((route: RouteInfo) => route.url === url);
}
