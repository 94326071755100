import { ListPaginationInterface, SliderListElementInterface, SliderStatusEnum, WebsiteSliderTypeEnum } from '@on-arte/core-types';
import { 
  PaginationDetails, 
  usePagination, 
  ElementsTable, 
  ElementsTableRow, 
  IconName, 
  DirectionEnum, 
  useNotifications, 
  UseNotifications, 
  useRedirect,
  UseRedirect,
  getPathWithParams,
  ApiError,
  UseLogger,
  UseState,
  useLogger,
  SortIndexElement
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';

import { deleteSlider, getSliders, updateSliderSortPosition, updateSliderVisibility } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { sliderStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

export const SlidersListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);
  const [sliders, setSliders]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const [sortIndexArray, setSortIndexArray]: UseState<SortIndexElement[]> = useState<SortIndexElement[]>([]);

  const { refetch }: QueryObserverResult = useQuery(
    [QueryKey.SlidersList, offset],
    (): Promise<ListPaginationInterface<SliderListElementInterface>> => getSliders(
      { offset, limit: itemsPerPage }, WebsiteSliderTypeEnum.Homepage
    ),
    {
      onSuccess: (data: ListPaginationInterface<SliderListElementInterface>): void => {
        setMaxItems(data.amount);
        setSortIndexArray(data.list);
        setSliders(data.list.map((slider: SliderListElementInterface): ElementsTableRow => {
          return {
            cells: [
              { name: 'header', content: slider.header },
              { name: 'position', content: `${slider.sortIndex}`, withArrows: true },
              {
                name: 'status',
                content: {
                  label: t(sliderStatusConfig[slider.status].label),
                  background: sliderStatusConfig[slider.status].background,
                  color: sliderStatusConfig[slider.status].color,
                },
              },
            ],
            contextMenuPositions: [
              {
                label: t('onarte.management.slidersList.actions.edit'),
                internalPath: getPathWithParams(
                  getRouteDetailsByName(RouteNameEnum.ContentSliderEditForm)?.url ?? '/', { id: `${slider.id}` }
                ),
                icon: IconName.Edit
              },
              {
                label: t('onarte.management.slidersList.actions.delete'),
                action: (): void => deleteSliderAction(slider),
                icon: IconName.Delete
              },
              {
                label: t(`onarte.management.slidersList.actions.visibility.${slider.status}`),
                action: (): void => updateVisibilityAction(slider),
                icon: IconName.Visibility
              },
            ],
          };
        }));
      },
      onError: (error: ApiError): void => logger(QueryKey.CollectionsList, error, 'error')
    }
  );

  const displayValueAction: (header: string) => string = (header: string): string => {
    return header.length > 30 ? `${header.slice(0, 30)}...` : header;
  };

  const updateVisibilityAction: (slider: SliderListElementInterface) => void = (
    slider: SliderListElementInterface
  ): void => {
    showSmallDialog({
      header: t('onarte.management.slidersList.smallDialog.header.visibility'),
      content: t('onarte.management.slidersList.smallDialog.content.visibility', { value: displayValueAction(slider.header) }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          updateSliderVisibility(slider.id, slider.status === SliderStatusEnum.Active ? false : true)
            .then((): void => {
              hideSmallDialog();
              void refetch();
              addToast({ content: t('onarte.management.updateVisibility.success') });
            })
            .catch((error: ApiError): void => addToast({ content: t(error.message) }));
        },
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  const deleteSliderAction: (
    slider: SliderListElementInterface
  ) => void = (slider: SliderListElementInterface): void => {
    showSmallDialog({
      header: t('onarte.management.slidersList.smallDialog.header.delete'),
      content: t('onarte.management.slidersList.smallDialog.content.delete', { value: displayValueAction(slider.header) }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          deleteSlider(slider.id)
            .then((): void => {
              hideSmallDialog();
              void refetch();
              addToast({ content: t('onarte.management.deleteCollection.success') });
            })
            .catch((error: ApiError): void => addToast({ content: t(error.message) }));
        },
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  const sortSlidersAction: (index: number, direction: DirectionEnum) => Promise<SortIndexElement[]> = (
    index: number, direction: DirectionEnum
  ): Promise<SortIndexElement[]> => {
    return new Promise((resolve: (sliders: SortIndexElement[]) => void, reject: () => void): void => {
      updateSliderSortPosition(Number(sortIndexArray[index].id), direction)
        .then((): void => {
          void refetch()
            .then((): void => {
              resolve(sortIndexArray);
              addToast({ content: t('onarte.management.updateSliderSortPosition.success') });
            });
        })
        .catch((error: ApiError): void => {
          reject();
          addToast({ content: t(error.message) });
        });
    });
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.contentSlidersList.title'),
        withUnderline: false,
        buttonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ContentSliderAddForm)?.url ?? '/'),
        buttonLabel: t('onarte.management.slidersList.actions.add')
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentSlidersList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/' 
        },
      ]}
    >
      <ElementsTable
        columns={[
          { name: 'header', label: t('onarte.management.slidersList.tableColumns.header') },
          { name: 'position', label: t('onarte.management.slidersList.tableColumns.position') },
          { name: 'status', label: t('onarte.management.slidersList.tableColumns.status') },
        ]}
        rows={sliders}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        onPositionChangeAsync={sortSlidersAction}
        isFirst={offset === 0}
        isLast={(offset / itemsPerPage) === Math.floor(maxItems / itemsPerPage)}
        initialPageCount={page}
      />
    </BaseView>
  );
};
