import { 
  PublicProfileTypeEnum,
} from '@on-arte/core-types';
import { 
  UseState,
  FormSectionData,
  FormFieldData,
  DropdownOption,
  useFormikForm,
  UseFormikForm,
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useAutosuggestActions, useFiles } from '@onArte/hooks';
import { AutosuggestActions, UseFiles } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormikForm, FormSectionWithStandardSpace } from '@onArte/theme';
import { ProfileFormDataType } from '@onArte/types';

import { Container } from './publicProfileForm.styled';
import { PublicProfileFormProps } from './publicProfileForm.types';

export const PublicProfileForm: React.FC<PublicProfileFormProps> = forwardRef((
  props: PublicProfileFormProps, ref: React.Ref<FormikProps<ProfileFormDataType>>
): JSX.Element => {
  const {
    initialValues,
    isFormSubmitted,
    files,
    submitAction,
    formDefinition,
    formValidationSchema,
    className
  }: PublicProfileFormProps = props;
  const formRef: React.RefObject<FormikProps<ProfileFormDataType>> = useRef<FormikProps<ProfileFormDataType>>(null);
  const { t }: TransProps<never> = useTranslation();
  const { searchUsersAction, searchManufacturersAction }: AutosuggestActions = useAutosuggestActions();
  const { getFormikFieldError }: UseFormikForm = useFormikForm();
  const { addFile }: UseFiles = useFiles();
  const [activeProfileType, setActiveProfileType]: UseState<PublicProfileTypeEnum> = useState<PublicProfileTypeEnum>(initialValues.type);

  // TODO: fixme asap
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  useImperativeHandle(ref, (): FormikProps<ProfileFormDataType> => formRef?.current!, [formRef.current]);

  const form: FormSectionData[] = useMemo(
    (): FormSectionData[] => {
      setActiveProfileType(initialValues.type);
      return formDefinition.map((section: FormSectionData): FormSectionData => ({
        ...section,
        children: section.children.map((field: FormFieldData): FormFieldData => ({
          ...field,
          additionalFieldProps: {
            ...field.additionalFieldProps,
            ...((files && files[field.name]) ? { files: files[field.name] } : { files: [] }),
            options: field.additionalFieldProps?.options
              ? field.additionalFieldProps.options.map((option: DropdownOption): DropdownOption => ({
                ...option,
                label: t(option.label),
              }))
              : undefined,
            initialValueLabel: field.name === 'relationId' && initialValues.relationId.id
              ? initialValues.relationId.id
              : undefined,
          },
          description: field.description
            ? {
              ...field.description,
              content: t(field.description.content),
            }
            : undefined
        }))
      }));
    },
    [files, formDefinition]
  );

  const handleFormChange: (values: ProfileFormDataType) => void = (values: ProfileFormDataType): void => {
    if (values.type !== activeProfileType) {
      formRef.current?.setFieldValue('relationId', { id: '', label: '' });
    }
    setActiveProfileType(values.type);
  };

  return (
    <Container className={className}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={submitAction}
        validate={handleFormChange}
        validationSchema={formValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, errors, values }: FormikProps<ProfileFormDataType>): JSX.Element => (
          <FormikForm onSubmit={handleSubmit}>
            {form.map((section: FormSectionData): JSX.Element => (
              <FormSectionWithStandardSpace title={t(section.label)} key={section.label}>
                {section.children.map((field: FormFieldData): JSX.Element => (
                  <FormFieldWithStandardSpace
                    key={field.name}
                    fieldType={field.type}
                    fieldName={field.name}
                    setFieldValue={setFieldValue}
                    value={values[field.name as keyof ProfileFormDataType]}
                    label={t(field.label ?? '')}
                    description={field.description}
                    disabled={field.disabled}
                    additionalFieldProps={field.additionalFieldProps}
                    validationMessage={isFormSubmitted
                      ? getFormikFieldError(errors[field.name as keyof ProfileFormDataType])
                      : undefined
                    }
                    actions={{
                      addFile,
                      autosuggestValueInputAsync: values.type === PublicProfileTypeEnum.Collector
                        ? searchUsersAction
                        : searchManufacturersAction,
                    }}
                  />
                ))}
              </FormSectionWithStandardSpace>
            ))}
          </FormikForm>
        )}
      </Formik>
    </Container>
  );
});
