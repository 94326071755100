export const getAttachmentNewValueOrUndefined: (oldAttachments: string[], newAttachments: string[]) => string[] | undefined = (
  oldAttachments: string[], newAttachments: string[]
): string[] | undefined => {
  if (
    oldAttachments.length !== newAttachments.length
    || oldAttachments.some((oldA: string): boolean => !newAttachments.find((newA: string) => newA === oldA))
  ) {
    return newAttachments;
  }
};
