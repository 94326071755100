import { ManufacturerTypeEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const authorStatusConfig: Record<ManufacturerTypeEnum, Status> = {
  [ManufacturerTypeEnum.Confirmed]: {
    label: 'onarte.common.authorStatuses.confirmed',
    background: Color.Success100,
    color: Color.Success500
  },
  [ManufacturerTypeEnum.Unconfirmed]: {
    label: 'onarte.common.authorStatuses.unconfirmed',
    background: Color.Gray200,
    color: Color.Gray500
  },
  [ManufacturerTypeEnum.Virtual]: {
    label: 'onarte.common.authorStatuses.virtual',
    background: Color.Success200,
    color: Color.Success500
  },
};
