import { 
  AttachmentInterface,
  AttachmentTypeEnum,
  CollectionDTO,
  ItemInterface,
  ThumbnailAttachmentTypeEnum, 
} from '@on-arte/core-types';
import { 
  ApiError,
  SuggestionItem, 
  UseLogger, 
  UseNotifications, 
  UseState, 
  getPathWithParams, 
  useLogger, 
  useNotifications, 
} from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';
import { Params, useParams } from 'react-router-dom';

import { addItemToCollection, getCollectionDetails, removeItemFromCollection } from '@onArte/api';
import { ItemsSelectionBaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions } from '@onArte/hooks';
import { AutosuggestActions } from '@onArte/interfaces';
import { getImageThumbnail, getRouteDetailsByName } from '@onArte/utils';

import { NoDataMessage, StyledAuctionDetailsBox } from './collectionItemsForm.styled';

export const CollectionItemsFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { id }: Readonly<Params<string>> = useParams();
  const { logger }: UseLogger = useLogger();
  const { searchItemsAction }: AutosuggestActions = useAutosuggestActions();
  const [collectionData, setCollectionData]: UseState<CollectionDTO | null> = useState<CollectionDTO | null>(null);

  const { refetch, isFetching }: QueryObserverResult = useQuery(
    [QueryKey.CollectionDetails],
    (): Promise<CollectionDTO> => getCollectionDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: CollectionDTO): void => setCollectionData(data),
      onError: (error: ApiError): void => logger(QueryKey.CollectionDetails, error, 'error')
    }
  );

  const itemsIdsInCollection: string[] = useMemo(
    (): string[] => {
      if (!collectionData) {
        return [];
      }
      
      return collectionData.items.map((item: ItemInterface): string => item.id);
    },
    [collectionData]
  );

  const onSelectNewItemHandler: (suggestion: SuggestionItem) => void = (suggestion: SuggestionItem): void => {
    if (!suggestion.id || !id) {
      return;
    }

    addItemToCollection(id, suggestion.id)
      .then((): void => {
        addToast({ content: t('onarte.management.collectionItemsForm.addItemToCollection.success') });
        void refetch();
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  const removeItemFromCollectionAction: (itemId: string) => void = (itemId: string): void => {
    if (!id) {
      return;
    }

    removeItemFromCollection(id, itemId)
      .then((): void => {
        addToast({ content: t('onarte.management.collectionItemsForm.removeItemFromCollection.success') });
        void refetch();
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  return (
    <ItemsSelectionBaseView 
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentCollectionsList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/' 
        },
        { 
          label: t('onarte.management.meta.contentCollectionItemsForm.title'), 
          path: getPathWithParams(getRouteDetailsByName(RouteNameEnum.ContentCollectionItemsForm)?.url ?? '/', { id: id ?? '' })
        },
      ]}
      pageTitleSettings={{ title: t('onarte.management.meta.contentCollectionItemsForm.title') }}
      onSelectNewItemHandler={onSelectNewItemHandler}
      children={
        !isFetching && (
          !collectionData?.items.length ? (
            <NoDataMessage>{t('onarte.common.noArtworks')}</NoDataMessage>
          ) : (
            collectionData?.items.map((item: ItemInterface, index: number): JSX.Element => (
              <StyledAuctionDetailsBox
                key={index}
                boxTitleDetails={{
                  author: item.manufacturer.name,
                  itemName: item.name,
                  itemDescriptiveAttributes: [t(item.label)],
                }}
                image={getImageThumbnail(
                  item.attachments.find((attachment: AttachmentInterface): boolean => (
                    attachment.type === AttachmentTypeEnum.ItemPhoto
                  )),  
                  ThumbnailAttachmentTypeEnum.Size_340xAuto
                )}
                onRemove={(): void => removeItemFromCollectionAction(item.id)}
              />
            ))
          )
        )
      }
      autosuggestAction={(inputValue: string): Promise<SuggestionItem[]> => searchItemsAction(inputValue, itemsIdsInCollection)}
    />
  );
};
