import {
  AttachmentInterface,
  AttachmentTypeEnum,
  PublicProfileManagementModel,
  PublicProfileTypeEnum,
  SimpleBlogPostInterface
} from '@on-arte/core-types';
import { DetailsTableValueType, MenuPositionData, TableDetailsRowData } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { UsePublicProfileTableDetails } from '@onArte/interfaces';
import { getAttachmentNewValueOrUndefined } from '@onArte/utils';

export const usePublicProfileTableDetails: () => UsePublicProfileTableDetails = (): UsePublicProfileTableDetails => {
  const { t }: TransProps<never> = useTranslation();

  const getTableRows: (profileData: PublicProfileManagementModel, withChanges?: boolean) => TableDetailsRowData[] = (
    profileData: PublicProfileManagementModel, withChanges: boolean = false
  ): TableDetailsRowData[] => {
    const shouldShowNewValue: boolean = withChanges && !!profileData.versions.length;

    const getNewValueOrUndefined: (
      oldValue: string | null | undefined, newValue: string | null | undefined
    ) => string | undefined = (
      oldValue: string | null | undefined, newValue: string | null | undefined
    ): string | undefined => {
      if (shouldShowNewValue && oldValue !== newValue && newValue) {
        return newValue;
      }
    };

    const getAttachmentPathsByType: (attachments: AttachmentInterface[], type: AttachmentTypeEnum) => string[] = (
      attachments: AttachmentInterface[], type: AttachmentTypeEnum
    ): string[] => {
      return attachments
        .filter((attachment: AttachmentInterface): boolean => attachment.type === type)
        .map((attachment: AttachmentInterface): string => attachment.path);
    };

    return [
      {
        label: t('onarte.common.publicProfileDetails.avatar.label'),
        type: DetailsTableValueType.Image,
        value: getAttachmentPathsByType(profileData.attachments, AttachmentTypeEnum.PublicProfileAvatar),
        newValue: shouldShowNewValue
          ? getAttachmentNewValueOrUndefined(
            getAttachmentPathsByType(profileData.attachments, AttachmentTypeEnum.PublicProfileAvatar),
            getAttachmentPathsByType(profileData.versions[0].data.attachments, AttachmentTypeEnum.PublicProfileAvatar),
          )
          : undefined
      },
      {
        label: t('onarte.common.publicProfileDetails.type.label'),
        value: t(`onarte.management.publicProfileDetails.${profileData.type}`)
      },
      {
        label: t('onarte.common.publicProfileDetails.name.label'),
        value: profileData.name,
        newValue: profileData.versions.length
          ? getNewValueOrUndefined(profileData.name, profileData.versions[0].data.name)
          : undefined,
      },
      {
        label: t('onarte.common.publicProfileDetails.description.label'),
        value: profileData.description,
        newValue: profileData.versions.length
          ? getNewValueOrUndefined(profileData.description, profileData.versions[0].data.description)
          : undefined,
      },
      {
        label: t('onarte.common.publicProfileDetails.bio.label'),
        value: profileData.bio,
        newValue: profileData.versions.length
          ? getNewValueOrUndefined(profileData.bio, profileData.versions[0].data.bio)
          : undefined,
      },
      {
        label: t(
          `onarte.common.publicProfileDetails.${
            profileData.type === PublicProfileTypeEnum.Collector ? 'relationUser' : 'relationAuthor'
          }.label`
        ),
        value: profileData.relation.name,
      },
      {
        label: t('onarte.common.publicProfileDetails.cover.label'),
        type: DetailsTableValueType.Image,
        value: getAttachmentPathsByType(profileData.attachments, AttachmentTypeEnum.PublicProfileCoverPhoto),
        newValue: shouldShowNewValue
          ? getAttachmentNewValueOrUndefined(
            getAttachmentPathsByType(profileData.attachments, AttachmentTypeEnum.PublicProfileCoverPhoto),
            getAttachmentPathsByType(profileData.versions[0].data.attachments, AttachmentTypeEnum.PublicProfileCoverPhoto),
          )
          : undefined
      },
      {
        label: t('onarte.common.publicProfileDetails.galleryPhotos.label'),
        type: DetailsTableValueType.Image,
        value: getAttachmentPathsByType(profileData.attachments, AttachmentTypeEnum.PublicProfileGalleryPhoto),
        newValue: shouldShowNewValue
          ? getAttachmentNewValueOrUndefined(
            getAttachmentPathsByType(profileData.attachments, AttachmentTypeEnum.PublicProfileGalleryPhoto),
            getAttachmentPathsByType(profileData.versions[0].data.attachments, AttachmentTypeEnum.PublicProfileGalleryPhoto),
          )
          : undefined
      },
      {
        label: t('onarte.common.publicProfileDetails.blogPosts.label'),
        value: profileData.blogPosts.map((post: SimpleBlogPostInterface): MenuPositionData => ({
          label: post.title,
          // externalUrl: post.link,
          action: (): void => undefined, // TODO: remove this line and uncomment the one above when BE will be ready
        })),
        type: DetailsTableValueType.Url,
      },
    ];
  };

  return { getTableRows };
};
