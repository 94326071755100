import { 
  AttachmentTypeEnum,
  ItemWithChangesInterface,
  WebsiteSliderButtonPathTypeEnum 
} from '@on-arte/core-types';
import { 
  AddPhotoFile, 
  AddPhotoTheme, 
  ApiError, 
  BooleanValue, 
  FormFieldType, 
  FormFieldValue,
  SuggestionItem,
  UseLogger,
  UseState,
  ValidationBarTheme,
  getSingleValueFromFormFieldValue,
  useLogger, 
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { getItemDetails } from '@onArte/api';
import { QueryKey } from '@onArte/enums';
import { useAutosuggestActions, useFiles } from '@onArte/hooks';
import { AddOrUpdateSliderForm, AutosuggestActions, UseFiles } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormikForm, FormSectionWithStandardSpace } from '@onArte/theme';

import { useAddOrUpdateSliderForm } from './sliderForm.hooks';
import { Container, StyledValidationBar, StyledFormFieldsWrapper } from './sliderForm.styled';
import { SliderFormProps } from './sliderForm.types';

export const SliderForm: React.FC<SliderFormProps> = forwardRef((
  props: SliderFormProps, ref: React.Ref<FormikProps<AddOrUpdateSliderForm>>
): JSX.Element => {
  const {
    initialValues,
    isFormSubmitted,
    files,
    formWithDescription = false,
    className
  }: SliderFormProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { searchItemsAction }: AutosuggestActions = useAutosuggestActions();
  const [linkedItem, setLinkedItem]: UseState<ItemWithChangesInterface | null> = useState<ItemWithChangesInterface | null>(null);
  const SliderFormValidationSchema: Yup.SchemaOf<AddOrUpdateSliderForm> = useAddOrUpdateSliderForm();
  const { addFile }: UseFiles = useFiles();

  useQuery(
    [QueryKey.ArtworkDetails, initialValues.linkedItemId],
    (): Promise<ItemWithChangesInterface> => getItemDetails(initialValues.linkedItemId ?? ''),
    {
      enabled: !!initialValues.linkedItemId,
      onSuccess: (data: ItemWithChangesInterface): void => setLinkedItem(data),
      onError: (error: ApiError): void => logger(QueryKey.ArtworkDetails, error, 'error')
    }
  );

  return (
    <Container className={className}>
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={(): void => undefined}
        validationSchema={SliderFormValidationSchema}
        validateOnChange={isFormSubmitted}
        validateOnBlur={isFormSubmitted}
      >
        {({ handleSubmit, setFieldValue, errors, values }: FormikProps<AddOrUpdateSliderForm>): JSX.Element => (
          <FormikForm onSubmit={handleSubmit}>
            <FormSectionWithStandardSpace title={t('onarte.common.sliderForm.infoSection.label')}>
              <FormFieldWithStandardSpace
                label={t('onarte.common.sliderForm.header.label')}
                fieldType={FormFieldType.Textarea}
                fieldName='header'
                setFieldValue={setFieldValue}
                value={values.header}
                validationMessage={errors.header || ''}
                additionalFieldProps={{
                  maxCharsCount: 500
                }}
              />
              {formWithDescription && (
                <FormFieldWithStandardSpace
                  label={t('onarte.common.sliderForm.description.label')}
                  fieldType={FormFieldType.Textarea}
                  value={values.description ?? ''}
                  setFieldValue={setFieldValue}
                  fieldName='description'
                  validationMessage={errors.description || ''}
                  additionalFieldProps={{
                    maxCharsCount: 500
                  }}
                />
              )}
              <FormFieldWithStandardSpace
                label={t('onarte.common.sliderForm.isButtonVisible.label')}
                fieldType={FormFieldType.Dropdown}
                setFieldValue={(field: string, value: FormFieldValue): void => setFieldValue(field, value === BooleanValue.Yes)}
                value={values.isButtonVisible ? BooleanValue.Yes : BooleanValue.No}
                fieldName='isButtonVisible'
                validationMessage={errors.isButtonVisible || ''}
                additionalFieldProps={{
                  options: [
                    { name: BooleanValue.Yes, label: t('onarte.common.yes') },
                    { name: BooleanValue.No, label: t('onarte.common.no') }
                  ],
                }}
              />
              {!!values.isButtonVisible && (
                <StyledFormFieldsWrapper>
                  <FormFieldWithStandardSpace
                    label={t('onarte.common.sliderForm.buttonLabel.label')}
                    fieldType={FormFieldType.Input}
                    fieldName='buttonLabel'
                    setFieldValue={setFieldValue}
                    value={values.buttonLabel}
                    validationMessage={errors.buttonLabel || ''}
                  />
                  <StyledValidationBar 
                    message={t('onarte.management.sliderForm.buttonPathType.validation')}
                    barTheme={ValidationBarTheme.Information} 
                  />
                  <FormFieldWithStandardSpace
                    label={t('onarte.common.sliderForm.buttonPathType.label')}
                    fieldType={FormFieldType.Dropdown}
                    setFieldValue={setFieldValue}
                    value={values.buttonPathType}
                    fieldName='buttonPathType'
                    validationMessage={errors.buttonPathType || ''}
                    additionalFieldProps={{
                      options: [
                        { name: WebsiteSliderButtonPathTypeEnum.Internal, label: t('onarte.common.internal') },
                        { name: WebsiteSliderButtonPathTypeEnum.External, label: t('onarte.common.external') }
                      ],
                    }}
                  />
                  <FormFieldWithStandardSpace
                    label={t('onarte.common.sliderForm.buttonPath.label')}
                    fieldType={FormFieldType.Input}
                    fieldName='buttonPath'
                    setFieldValue={setFieldValue}
                    value={values.buttonPath}
                    validationMessage={errors.buttonPath || ''}
                  />
                </StyledFormFieldsWrapper>
              )}
              <FormFieldWithStandardSpace
                label={t('onarte.common.sliderForm.isSecondButtonVisible.label')}
                fieldType={FormFieldType.Dropdown}
                setFieldValue={(field: string, value: FormFieldValue): void => setFieldValue(field, value === BooleanValue.Yes)}
                value={values.isSecondButtonVisible ? BooleanValue.Yes : BooleanValue.No}
                fieldName='isSecondButtonVisible'
                validationMessage={errors.isSecondButtonVisible || ''}
                additionalFieldProps={{
                  options: [
                    { name: BooleanValue.Yes, label: t('onarte.common.yes') },
                    { name: BooleanValue.No, label: t('onarte.common.no') }
                  ],
                }}
              />
              {!!values.isSecondButtonVisible && (
                <StyledFormFieldsWrapper>
                  <FormFieldWithStandardSpace
                    label={t('onarte.common.sliderForm.secondButtonLabel.label')}
                    fieldType={FormFieldType.Input}
                    fieldName='secondButtonLabel'
                    setFieldValue={setFieldValue}
                    value={values.secondButtonLabel}
                    validationMessage={errors.secondButtonLabel || ''}
                  />
                  <StyledValidationBar 
                    message={t('onarte.management.sliderForm.buttonPathType.validation')}
                    barTheme={ValidationBarTheme.Information} 
                  />
                  <FormFieldWithStandardSpace
                    label={t('onarte.common.sliderForm.secondButtonPathType.label')}
                    fieldType={FormFieldType.Dropdown}
                    setFieldValue={setFieldValue}
                    value={values.secondButtonPathType}
                    fieldName='secondButtonPathType'
                    validationMessage={errors.secondButtonPathType || ''}
                    additionalFieldProps={{
                      options: [
                        { name: WebsiteSliderButtonPathTypeEnum.Internal, label: t('onarte.common.internal') },
                        { name: WebsiteSliderButtonPathTypeEnum.External, label: t('onarte.common.external') }
                      ],
                    }}
                  />
                  <FormFieldWithStandardSpace
                    label={t('onarte.common.sliderForm.secondButtonPath.label')}
                    fieldType={FormFieldType.Input}
                    fieldName='secondButtonPath'
                    setFieldValue={setFieldValue}
                    value={values.secondButtonPath}
                    validationMessage={errors.secondButtonPath || ''}
                  />
                </StyledFormFieldsWrapper>
              )}
            </FormSectionWithStandardSpace>
            <FormSectionWithStandardSpace title={t('onarte.common.sliderForm.photosSection.label')}>
              <FormFieldWithStandardSpace
                fieldType={FormFieldType.AddPhoto}
                value={values.imageId}
                setFieldValue={(field: string, value: FormFieldValue): void => {
                  setFieldValue(field, getSingleValueFromFormFieldValue<AddPhotoFile>(value).id);
                }}
                fieldName='imageId'
                validationMessage={errors.imageId || ''}
                additionalFieldProps={{
                  addPhotoTheme: AddPhotoTheme.WithBoxLoaderOnInit,
                  requirements: [
                    t('onarte.common.sliderForm.imageId.requirements.1'),
                    t('onarte.common.sliderForm.imageId.requirements.2'),
                    t('onarte.common.sliderForm.imageId.requirements.3'),
                    t('onarte.common.sliderForm.imageId.requirements.4'),
                  ],
                  attachmentType: AttachmentTypeEnum.SliderPhoto,
                  acceptedFilesTypes: 'image/png, image/jpeg',
                  maxFileSizeInBytes: 5 * 1024 * 1024,
                  maxNumberOfFiles: 1,
                  files: files ?? []
                }}
                actions={{ addFile }}
              />
            </FormSectionWithStandardSpace>
            <FormSectionWithStandardSpace title={t('onarte.common.sliderForm.linkedItem.label')}>
              <FormFieldWithStandardSpace
                label={t('onarte.common.sliderForm.isLinkedItemVisible.label')}
                fieldType={FormFieldType.Dropdown}
                setFieldValue={(field: string, value: FormFieldValue): void => setFieldValue(field, value === BooleanValue.Yes)}
                value={values.isLinkedItemVisible ? BooleanValue.Yes : BooleanValue.No}
                fieldName='isLinkedItemVisible'
                validationMessage={errors.isLinkedItemVisible || ''}
                additionalFieldProps={{
                  options: [
                    { name: BooleanValue.Yes, label: t('onarte.common.yes') },
                    { name: BooleanValue.No, label: t('onarte.common.no') }
                  ],
                }}
              />
              {!!values.isLinkedItemVisible && (
                <FormFieldWithStandardSpace
                  label={t('onarte.common.sliderForm.linkedItemId.label')}
                  fieldType={FormFieldType.Autosuggest}
                  fieldName='linkedItemId'
                  setFieldValue={(field: string, value: FormFieldValue): void => {
                    setFieldValue(field, getSingleValueFromFormFieldValue<SuggestionItem>(value).id);
                  }}
                  validationMessage={errors.linkedItemId || ''}
                  actions={{ autosuggestValueInputAsync: searchItemsAction }}
                  additionalFieldProps={{
                    initialValueLabel: linkedItem?.name || ''
                  }}
                />
              )}
            </FormSectionWithStandardSpace>
          </FormikForm>
        )}
      </Formik>
    </Container>
  );
});
