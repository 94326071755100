import {
  AttachmentInterface,
  AttachmentTypeEnum,
  ExternalAttachmentInterface,
  PublicProfileManagementModel,
  PublicProfileTypeEnum,
  SimpleBlogPostInterface,
  UploadedAttachmentInterface
} from '@on-arte/core-types';
import {
  ApiError,
  UseLogger,
  UseState,
  useLogger,
  UseNotifications,
  useNotifications,
  useFormikForm,
  UseFormikForm,
  useRedirect,
  UseRedirect,
  AddPhotoFile,
  RelatedNews,
  SelectedNewsElement,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Params, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { getPublicProfileDetails, updatePublicProfile } from '@onArte/api';
import { BaseView, PublicProfileForm } from '@onArte/components';
import { editPublicProfileForm } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions, useObjectsTransformations } from '@onArte/hooks';
import { AutosuggestActions, EditPublicProfileFormData, UseObjectsTranformations } from '@onArte/interfaces';
import { FormSectionWithTopSpace } from '@onArte/theme';
import { ProfileFormDataType } from '@onArte/types';
import { getRouteDetailsByName } from '@onArte/utils';

import { usePublicProfileEditValidation } from './publicProfileEditForm.hooks';

export const PublicProfileEditFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Params<string>> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();
  const formRef: React.Ref<FormikProps<EditPublicProfileFormData>> = useRef(null);
  const [formFiles, setFormFiles]: UseState<Record<string, AddPhotoFile[]>> = useState<Record<string, AddPhotoFile[]>>({});
  const [profileData, setProfileData]: UseState<PublicProfileManagementModel | null> = useState<PublicProfileManagementModel | null>(null);
  const { transformAttachmentInterfaceToAddPhotoFile }: UseObjectsTranformations = useObjectsTransformations();
  const PublicProfileEditValidation: Yup.SchemaOf<EditPublicProfileFormData>  = usePublicProfileEditValidation();
  const [selectedBlogPosts, setSelectedBlogPosts]: UseState<SelectedNewsElement[]> = useState<SelectedNewsElement[]>([]);
  const { searchBlogPosts }: AutosuggestActions = useAutosuggestActions();

  useQuery(
    [QueryKey.PublicProfileDetails, id],
    (): Promise<PublicProfileManagementModel | void> => getPublicProfileDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: PublicProfileManagementModel): void => {
        setProfileData(data);
        let formFilesValue: Record<string, AddPhotoFile[]> = {};
        data.attachments.forEach((attachment: ExternalAttachmentInterface | UploadedAttachmentInterface): void => {
          if (!attachment.hasOwnProperty('objectName')) {
            return;
          }
          const attachmentObject: UploadedAttachmentInterface = attachment as UploadedAttachmentInterface;
          switch (attachment.type) {
            case AttachmentTypeEnum.PublicProfileAvatar:
              formFilesValue = { ...formFilesValue, avatar: [...(formFilesValue.avatar ?? []), attachmentObject] };
              break;
            case AttachmentTypeEnum.PublicProfileCoverPhoto:
              formFilesValue = { ...formFilesValue, cover: [...(formFilesValue.cover ?? []), attachmentObject] };
              break;
            case AttachmentTypeEnum.PublicProfileGalleryPhoto:
              formFilesValue = { ...formFilesValue, galleryPhoto: [...(formFilesValue.galleryPhoto ?? []), attachmentObject] };
              break;
          }
        });
        setFormFiles(formFilesValue);
        setSelectedBlogPosts(data.blogPosts
          .map((post: SimpleBlogPostInterface): SelectedNewsElement => ({ label: post.title, id: String(post.id) }))
        );
      },
      onError: (error: ApiError): void => {
        logger(QueryKey.PublicProfileDetails, error);
        addToast({ content: t(error.message) });
      }
    }
  );

  const save: () => Promise<void> = async (): Promise<void> => {
    if (!formRef.current || !id) {
      return;
    }

    const formObject: FormikProps<EditPublicProfileFormData> = formRef.current;
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (isValid) {
      const { name, description, bio, avatar, cover, galleryPhoto }: EditPublicProfileFormData = formObject.values;
      const attachments: string[] = [...avatar, ...cover, ...galleryPhoto].map((attachment: AddPhotoFile): string => attachment.id);

      updatePublicProfile(id, {
        name,
        description,
        bio,
        attachments,
        blogPostIds: selectedBlogPosts.map((post: SelectedNewsElement): number => Number(post.id))
      })
        .then((): void => {
          addToast({ content: t('onarte.management.publicProfileEditFormView.updateSuccessfully', { name }) });
          redirect(getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/');
        })
        .catch((error: ApiError): void => logger('updatePublicProfile', error, 'error'));
    }
  };

  const submit: () => void = (): void => {
    if (formRef.current) {
      setFormSubmitted();
      formRef.current.handleSubmit();
    }
  };
  
  return (
    <BaseView
      pageTitleSettings={{
        title: profileData?.name ?? '',
        buttonLabel: t('onarte.common.save'),
        buttonAction: submit,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        {
          label: t('onarte.management.meta.publicProfilesList.title'),
          path: getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/'
        },
        { 
          label: t('onarte.management.meta.publicProfileEditForm.title'), 
          path: getRouteDetailsByName(RouteNameEnum.PublicProfileEditForm)?.url ?? '/' 
        },
      ]}
    >
      <PublicProfileForm
        initialValues={{
          type: profileData?.type ?? PublicProfileTypeEnum.Manufacturer,
          name: profileData?.name ?? '',
          description: profileData?.description ?? '',
          bio: profileData?.bio ?? '',
          relationId: { id: profileData?.relation.id ?? '', label: '' },
          avatar: profileData?.attachments
            ?.filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.PublicProfileAvatar)
            ?.map(transformAttachmentInterfaceToAddPhotoFile) ?? [],
          cover: profileData?.attachments
            ?.filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.PublicProfileCoverPhoto)
            ?.map(transformAttachmentInterfaceToAddPhotoFile) ?? [],
          galleryPhoto: profileData?.attachments
            ?.filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.PublicProfileGalleryPhoto)
            ?.map(transformAttachmentInterfaceToAddPhotoFile) ?? [],
        }}
        isFormSubmitted={isFormSubmitted}
        ref={formRef as React.RefObject<FormikProps<ProfileFormDataType>>}
        files={formFiles}
        formDefinition={editPublicProfileForm}
        submitAction={save}
        formValidationSchema={PublicProfileEditValidation}
      />
      <FormSectionWithTopSpace title={t('onarte.management.publicProfileEditFormView.magazine')}>
        <RelatedNews 
          selectedElements={selectedBlogPosts}
          onSelectedElementsChange={setSelectedBlogPosts}
          onValueInputChangeAsync={searchBlogPosts}
        />
      </FormSectionWithTopSpace>
    </BaseView>
  );
};
