import { AddressInterface } from '@on-arte/core-types';
import { BillingAddressFormData, DeliveryCompanyOrderFormData, ShippingAddressFormData } from '@on-arte/ui';

export const transformAddressInterfaceToShippingAddressFormData: (values: AddressInterface) => ShippingAddressFormData = (
  values: AddressInterface
): ShippingAddressFormData => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    street: values.location.address.street ?? '',
    houseNumber: values.location.address.houseNumber ?? '',
    apartmentNumber: values.location.address.apartmentNumber,
    postalCode: values.location.address.postalCode ?? '',
    city: values.location.address.city,
    country: values.location.address.country,
    phone: values.phone ?? '',
  };
};

export const transformAddressInterfaceToBillingAddressFormData: (values: AddressInterface) => BillingAddressFormData = (
  values: AddressInterface
): BillingAddressFormData => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    street: values.location.address.street ?? '',
    houseNumber: values.location.address.houseNumber ?? '',
    apartmentNumber: values.location.address.apartmentNumber,
    postalCode: values.location.address.postalCode ?? '',
    city: values.location.address.city,
    country: values.location.address.country,
    taxId: values.taxId ?? '',
    companyName: values.companyName ?? '',
  };
};

export const transformAddressInterfaceToDeliveryCompanyOrderFormData: (values: AddressInterface) => DeliveryCompanyOrderFormData = (
  values: AddressInterface
): DeliveryCompanyOrderFormData => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone ?? '',
    email: values.email ?? '',
    street: values.location.address.street ?? '',
    houseNumber: values.location.address.houseNumber ?? '',
    apartmentNumber: values.location.address.apartmentNumber ?? '',
    postalCode: values.location.address.postalCode ?? '',
    city: values.location.address.city,
    country: values.location.address.country,
  };
};
