import { AuctionTypeSearchEnum, ItemSearchInterface, ItemStatusEnum, ListPaginationInterface } from '@on-arte/core-types';
import { 
  ApiError,
  Color,
  ElementsTable, 
  ElementsTableRow, 
  getPathWithParams, 
  IconName, 
  Input, 
  InputTheme, 
  PaginationDetails, 
  TabButton, 
  useLogger, 
  UseLogger, 
  usePagination, 
  UseState 
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { searchItems } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { itemStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

import { auctionTypeFilters } from './artworksList.configs';
import { useArtworkListMethods } from './artworksList.hook';
import { TabFiltersContainer } from './artworksList.styled';
import { ArtworkListMethods, AuctionTypeFilter } from './artworksList.types';

export const ArtworksListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const [artworks, setArtworks]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const [activeFilter, setActiveFilter]: UseState<AuctionTypeSearchEnum | undefined> 
    = useState<AuctionTypeSearchEnum | undefined>(undefined);
  const [searchInputText, setSearchInputText]: UseState<string> = useState<string>('');
  const { approveArtwork, rejectArtwork, removeItem }: ArtworkListMethods = useArtworkListMethods();
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);

  useQuery(
    [QueryKey.ArtworksList, offset, activeFilter, searchInputText],
    (): Promise<ListPaginationInterface<ItemSearchInterface>> => searchItems({
      limit: itemsPerPage,
      offset,
      auctionType: activeFilter,
      searchQuery: searchInputText,
    }),
    {
      onSuccess: (data: ListPaginationInterface<ItemSearchInterface>): void => {
        setMaxItems(data.amount);
        setArtworks(data.list.map((item: ItemSearchInterface) => {
          return {
            cells: [
              { name: 'name', content: item.name },
              { name: 'owner', content: item.owner.name },
              { name: 'category', content: t(`onarte.common.itemsCategories.${item.category.name}`) },
              { name: 'manufacturer', content: item.manufacturer.name },
              {
                name: 'status',
                content: item.hasChangesToVerify
                  ? {
                    label: t('onarte.common.itemStatuses.hasChangesToVerify'),
                    background: Color.Warning100,
                    color: Color.Warning500,
                  }
                  : {
                    label: t(itemStatusConfig[item.status].label ?? item.status),
                    background: itemStatusConfig[item.status].background,
                    color: itemStatusConfig[item.status].color,
                  }
              },
            ],
            contextMenuPositions: [
              ...(item.status === ItemStatusEnum.WaitingForApproval
                ? [
                  {
                    label: t('onarte.management.artworksList.actions.confirm'),
                    action: (): void => approveArtwork(item.id, item.name),
                    icon: IconName.CheckCircle },
                  {
                    label: t('onarte.common.discard'),
                    action: (): void => rejectArtwork(item.id, item.name),
                    icon: IconName.Cancel
                  }
                ]
                : []
              ),
              {
                label: t('onarte.management.artworksList.actions.details'),
                internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: item.id }),
                icon: IconName.Description
              },
              ...(item.hasChangesToVerify
                ? [{
                  label: t('onarte.management.artworksList.actions.checkTheChanges'),
                  internalPath: getPathWithParams(
                    getRouteDetailsByName(RouteNameEnum.ArtworkEditChanges)?.url ?? '/', { id: item.id }
                  ),
                  icon: IconName.Description
                }]
                : []
              ),
              ...(item.status === ItemStatusEnum.Approved
                ? [{
                  label: t('onarte.management.artworksList.actions.edit'),
                  internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkEditForm)?.url ?? '/', { id: item.id }),
                  icon: IconName.Edit
                }]
                : []
              ),
              ...(item.status === ItemStatusEnum.Rejected
                ? [{
                  label: t('onarte.management.artworksList.actions.delete'),
                  action: (): void => removeItem(item.id, item.name),
                  icon: IconName.Delete
                }]
                : []
              )
            ]
          };
        }));
      },
      onError: (error: ApiError): void => logger(QueryKey.ArtworksList, error, 'error')
    }
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.artworksList.title'),
        withUnderline: false
      }}
      pageTitleChildren={
        <Input
          label={t('onarte.common.searchInput.label')}
          icon={IconName.Loupe}
          inputTheme={InputTheme.Small}
          value={searchInputText}
          onChange={(value: string): void => {
            setSearchInputText(value);
            setPage(1);  
          }}
        />
      }
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.artworksList.title'), path: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/' },
      ]}
    >
      <TabFiltersContainer>
        {auctionTypeFilters.map((type: AuctionTypeFilter): JSX.Element => (
          <TabButton 
            key={type.label}
            label={t(type.label)} 
            isActive={activeFilter === type.name} 
            onClick={(): void => {
              setActiveFilter(type.name);
              setPage(1);
            }} 
          />
        ))}
      </TabFiltersContainer>
      <ElementsTable
        columns={[
          { name: 'name', label: t('onarte.management.artworksList.tableColumns.name') },
          { name: 'owner', label: t('onarte.management.artworksList.tableColumns.owner') },
          { name: 'category', label: t('onarte.management.artworksList.tableColumns.category') },
          { name: 'manufacturer', label: t('onarte.management.artworksList.tableColumns.author') },
          { name: 'status', label: t('onarte.management.artworksList.tableColumns.status') },
        ]}
        rows={artworks}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        initialPageCount={page}
      />
    </BaseView>
  );
};
