import {
  AuctionDetailsInterface,
  EventAssignItemModel,
  EventCreateModel,
  EventDetailsInterface,
  EventListElementInterface,
  EventUpdateModel,
  ListPaginationInterface
} from '@on-arte/core-types';
import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { ListParams } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

// TODO: add correct type when BE will be ready
export const searchEvents: (
  params: Partial<ListParams & { search: string }>
) => Promise<ListPaginationInterface<EventListElementInterface>> = (
  params: Partial<ListParams & { search: string }>
): Promise<ListPaginationInterface<EventListElementInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<EventListElementInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/events?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<EventListElementInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const createEvent: (data: EventCreateModel) => Promise<EventDetailsInterface> = (
  data: EventCreateModel
): Promise<EventDetailsInterface> => new Promise(
  (resolve: ApiResolve<EventDetailsInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post('/events', data).then(
      (response: AxiosResponse<EventDetailsInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getEventDetails: (id: string) => Promise<EventDetailsInterface<AuctionDetailsInterface>> = (
  id: string
): Promise<EventDetailsInterface<AuctionDetailsInterface>> => new Promise(
  (resolve: ApiResolve<EventDetailsInterface<AuctionDetailsInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/events/${id}`).then(
      (response: AxiosResponse<EventDetailsInterface<AuctionDetailsInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updateEventDetails: (id: string, data: EventUpdateModel) => Promise<EventDetailsInterface> = (
  id: string, data: EventUpdateModel
): Promise<EventDetailsInterface> => new Promise(
  (resolve: ApiResolve<EventDetailsInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/events/${id}`, data).then(
      (response: AxiosResponse<EventDetailsInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const revokeEvent: (id: string) => Promise<void> = (id: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.delete(`/events/${id}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const assignItemToEvent: (id: string, itemId: string, data: EventAssignItemModel) => Promise<void> = (
  id: string, itemId: string, data: EventAssignItemModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/events/${id}/items/${itemId}`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const detachItemFromEvent: (id: string, itemId: string) => Promise<void> = (
  id: string, itemId: string
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.delete(`/events/${id}/items/${itemId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
