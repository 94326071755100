import { AppConfig } from '@onArte/interfaces';

declare const window: {
  APP_VERSION: string;
  APP_INSTANCE: string;
};

export const appConfig: AppConfig = {
  api: process.env.REACT_APP_API_URL || '/api',
  applicationName: 'on*arte management',
  loggerSettings: {
    version: process.env.REACT_APP_VERSION
      ? process.env.REACT_APP_VERSION
      : (typeof window !== 'undefined')
        ? window.APP_VERSION || 'unknown'
        : 'unknown',
    env: process.env.REACT_APP_INSTANCE
      ? process.env.REACT_APP_INSTANCE
      : (typeof window !== 'undefined')
        ? window.APP_INSTANCE || 'unknown'
        : 'unknown',
    applicationId: process.env.REACT_APP_ID ?? 'on*arte management FE',
    service: process.env.REACT_APP_SERVICE ?? 'on*arte',
    clientToken: process.env.REACT_APP_FE_LOGGER_TOKEN
      ? process.env.REACT_APP_FE_LOGGER_TOKEN
      : '',
  }
};
