import { CollectionStatusEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const collectionStatusConfig: Record<CollectionStatusEnum, Status> = {
  [CollectionStatusEnum.Active]: {
    label: 'onarte.common.collectionStatuses.active',
    background: Color.Success100,
    color: Color.Success500
  },
  [CollectionStatusEnum.Inactive]: {
    label: 'onarte.common.collectionStatuses.inactive',
    background: Color.Gray200,
    color: Color.Gray500
  }
};
