import { AttachmentInterface, AttachmentModel, AttachmentTypeEnum } from '@on-arte/core-types';
import { AddPhotoFile } from '@on-arte/ui';

import { UseObjectsTranformations } from '../interfaces';

export const useObjectsTransformations: () => UseObjectsTranformations = (): UseObjectsTranformations => {
  const transformAttachmentInterfaceToAddPhotoFile: (item: AttachmentInterface) => AddPhotoFile = (
    item: AttachmentInterface
  ): AddPhotoFile => ({
    id: item.id,
    type: item.type,
    path: item.path,
    name: item.name ?? '',
    objectName: null
  });

  const transformAddPhotoFileToAttachmentModel: (item: AddPhotoFile) => AttachmentModel = (
    item: AddPhotoFile
  ): AttachmentModel => ({
    id: item.id,
    type: item.type as AttachmentTypeEnum,
    path: item.path,
    name: item.name ?? '',
    objectName: null,
  });

  return { 
    transformAttachmentInterfaceToAddPhotoFile,
    transformAddPhotoFileToAttachmentModel,
  };
};
