import { OrangeStripeSectionInterface, OrangeStripeSectionUpdateModel } from '@on-arte/core-types';
import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';

import { ApiResolve, ApiReject } from '@onArte/types';

import { api } from '../api';

export const getOrangeStripe: () => Promise<OrangeStripeSectionInterface> = (): Promise<OrangeStripeSectionInterface> => new Promise(
  (resolve: ApiResolve<OrangeStripeSectionInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get('/settings/website/orange-section').then(
      (response: AxiosResponse<OrangeStripeSectionInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const editOrangeStripe: (
  data: OrangeStripeSectionUpdateModel
) => Promise<void> = (data: OrangeStripeSectionUpdateModel): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch('/settings/website/orange-section', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
