import { 
  AddressInterface,
  CartCostTypeEnum, 
  DeliveryTypeEnum, 
  LocationTypeEnum, 
  OrderAuctionDetailsDTO, 
  OrderDetailsDTO,
  OrderStatusEnum
} from '@on-arte/core-types';
import { 
  ApiError,
  DeliveryCompanyOrderForm, 
  DeliveryCompanyOrderFormData, 
  DialogTheme, 
  getPathWithParams, 
  PurchasedArtworkSummary, 
  SmallDialog, 
  Status, 
  UseFormikForm, 
  useFormikForm, 
  UseNotifications, 
  useNotifications, 
  UseState 
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getOrderDetails, orderCourier, setParcelInAuctionAsDelivered } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { initialDeliveryCompanyOrderForm, orderStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { OrderCourierData } from '@onArte/interfaces';
import { 
  getCostFinalPriceByType, 
  getRouteDetailsByName, 
  transformAddressInterfaceToBillingAddressFormData, 
  transformAddressInterfaceToDeliveryCompanyOrderFormData, 
  transformAddressInterfaceToShippingAddressFormData 
} from '@onArte/utils';

import { StyledArtworkBoxSoldSummary } from './orderDetails.styled';

export const OrderDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted, setFormNotSubmitted }: UseFormikForm = useFormikForm();
  const [orderData, setOrderData]: UseState<OrderDetailsDTO | null> = useState<OrderDetailsDTO | null>(null);
  const [orderDeliveryPopupOpen, setOrderDeliveryPopupOpen]: UseState<boolean> = useState<boolean>(false);
  const [addressFormValues, setAddressFormValues]: UseState<DeliveryCompanyOrderFormData> 
    = useState<DeliveryCompanyOrderFormData>(initialDeliveryCompanyOrderForm);
  const [orderCourierAuctionId, setOrderCourierAuctionId]: UseState<string> = useState<string>('');
  const [isOrderButtonLoading, setIsOrderButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const formRef: React.RefObject<FormikProps<DeliveryCompanyOrderFormData>> = useRef(null);

  const { refetch }: QueryObserverResult = useQuery(
    [QueryKey.OrderDetails],
    (): Promise<OrderDetailsDTO> => getOrderDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: OrderDetailsDTO): void => setOrderData(data)
    }
  );

  const deliveryConfirmAction: (orderId: string, auctionId: string) => void = (orderId: string, auctionId: string): void => {
    setParcelInAuctionAsDelivered(orderId, auctionId)
      .then((): void => {
        if (orderData) {
          setOrderData({ ...orderData, auctions: orderData.auctions.map((auction: OrderAuctionDetailsDTO): OrderAuctionDetailsDTO => ({
            ...auction,
            delivered: auction.auction.auctionId === auctionId ? true : auction.delivered
          }))});
        }
        addToast({ content: t('onarte.management.orderDetails.deliveryConfirmActionSuccess') });
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  const onOpenPopup: (auctionId: string) => void = (auctionId: string): void => {
    const address: AddressInterface | undefined = orderData?.auctions.find(
      (auction: OrderAuctionDetailsDTO): boolean => auction.auction.auctionId === auctionId
    )?.pickupAddress ?? undefined;
    if (address) {
      setAddressFormValues(transformAddressInterfaceToDeliveryCompanyOrderFormData(address));
    }
    setOrderCourierAuctionId(auctionId);
    setOrderDeliveryPopupOpen(true);
  };

  const onClosePopup: () => void = (): void => {
    setAddressFormValues(initialDeliveryCompanyOrderForm);
    setFormNotSubmitted();
    setOrderDeliveryPopupOpen(false);
  };

  const onOrderDeliveryAction: () => void = async (): Promise<void> => {
    if (!formRef?.current || !orderCourierAuctionId || !orderData?.id) {
      return;
    }

    setFormSubmitted();
    const formObject: FormikProps<DeliveryCompanyOrderFormData> = formRef.current;
    const isValid: boolean = !Object.keys(await formObject.validateForm()).length;

    if (isValid) {
      setIsOrderButtonLoading(true);
      const formValues: DeliveryCompanyOrderFormData = formObject.values;
      const pickupAddress: AddressInterface | undefined = orderData.auctions.find(
        (auction: OrderAuctionDetailsDTO): boolean => auction.auction.auctionId === orderCourierAuctionId
      )?.pickupAddress ?? undefined;
      const courierData: OrderCourierData = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        phone: formValues.phone,
        email: formValues.email, 
        location: {
          street: formValues.street,
          houseNumber: formValues.houseNumber,
          apartmentNumber: formValues.apartmentNumber ?? '',
          postalCode: formValues.postalCode,
          city: formValues.city,
          country: formValues.country,
          type: pickupAddress?.location.type ?? LocationTypeEnum.Approximate,
          latitude: pickupAddress?.location.coordinates.latitude ?? 0,
          longitude: pickupAddress?.location.coordinates.longitude ?? 0
        }
      };
    
      orderCourier(orderData.id, orderCourierAuctionId, courierData)
        .then((): void => {
          void refetch();
          setOrderDeliveryPopupOpen(false);
          setIsOrderButtonLoading(false);
          addToast({ content: t('onarte.management.orderDetails.orderCourier.success') });
        })
        .catch((error: ApiError): void => {
          setIsOrderButtonLoading(false);
          addToast({ content: t(error.message) });
        });
    }
  };

  return (
    <BaseView
      pageTitleSettings={{ 
        title: t('onarte.management.meta.orderDetails.title'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.ordersList.title'), path: getRouteDetailsByName(RouteNameEnum.OrdersList)?.url ?? '/' },
        { 
          label: t('onarte.management.meta.orderDetails.title'), 
          path: getPathWithParams(getRouteDetailsByName(RouteNameEnum.OrderDetails)?.url ?? '/', { id: id ?? '' })
        },
      ]}
    >
      {!!orderData && (
        <StyledArtworkBoxSoldSummary 
          createdDate={orderData.orderedAt}
          badges={[orderStatusConfig[orderData.status]].map((status: Status): Status => ({ ...status, label: t(status.label) }))}
          items={orderData.auctions.map((auction: OrderAuctionDetailsDTO): PurchasedArtworkSummary => ({
            artworkDetails: {
              author: auction.auction.manufacturer.name,
              itemName: auction.auction.name,
              itemDescriptiveAttributes: [t(auction.auction.label)],
            },
            image: auction.auction.coverPhoto?.path ?? '',
            costsDetails: {
              price: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Item),
              commission: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Commission),
              deliveryCost: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Delivery),
              deliveryTypeLabel: t(`onarte.common.deliveryType.${auction.deliveryType}.name`),
            },
            commentToSeller: auction.commentToSeller ?? '',
            trackingId: auction.trackingCode ?? undefined,
            trackingUrl: auction.trackingUrl ?? undefined,
            isDeliveryCompleted: auction.delivered ?? undefined,
            actions: {
              orderDeliveryAction: (
                (!auction.trackingCode
                  && !auction.delivered
                  && auction.deliveryType === DeliveryTypeEnum.Courier
                  && [OrderStatusEnum.Paid, OrderStatusEnum.InProgress].includes(orderData.status)
                )
                  ? (): void => onOpenPopup(auction.auction.auctionId) 
                  : undefined
              ),
              deliveryConfirmAction: (
                (!auction.delivered
                  && [DeliveryTypeEnum.OnArteTransport, DeliveryTypeEnum.SelfPickup].includes(auction.deliveryType)
                  && orderData.status === OrderStatusEnum.InProgress
                )
                  ? (): void => deliveryConfirmAction(orderData.id, auction.auction.auctionId) 
                  : undefined
              )
            }
          }))}
          ordersDetails={{
            id: orderData.no,
            hasInvoice: orderData.auctions[0]?.invoiceRequired ?? false,
            paymentStatusLabel: orderData.payment.paymentStatus
              ? t(`onarte.common.paymentStatus.${orderData.payment.paymentStatus}`)
              : '-',
            paymentTypeLabel: t(`onarte.common.paymentType.${orderData.payment.paymentType}`),
            commentToOrder: orderData.commentToOrder ?? '',
            shippingAddress: transformAddressInterfaceToShippingAddressFormData(orderData.auctions[0].deliveryAddress!),
            billingAddress: transformAddressInterfaceToBillingAddressFormData(orderData.auctions[0].billingAddress!),
            sourceParcelAddress: orderData.auctions[0].pickupAddress
              ? transformAddressInterfaceToDeliveryCompanyOrderFormData(orderData.auctions[0].pickupAddress)
              : undefined,
          }}
          finalPrice={orderData.finalPrice}
          key={orderData.id}
        />
      )}
      <SmallDialog
        settings={{
          theme: DialogTheme.Medium,
          closeButtonAction: onClosePopup,
          acceptButton: {
            label: t('onarte.management.orderDetails.smallDialog.accept.buttonLabel'),
            action: onOrderDeliveryAction,
            isLoading: isOrderButtonLoading
          },
          cancelButton: {
            label: t('onarte.common.cancel'),
            action: onClosePopup
          },
          isActive: orderDeliveryPopupOpen,
          header: t('onarte.management.orderDetails.smallDialog.header'),
        }}
      >
        <DeliveryCompanyOrderForm 
          initialValues={addressFormValues}
          isFormSubmitted={isFormSubmitted}
          ref={formRef}
        />
      </SmallDialog>
    </BaseView>
  );
};
