import { 
  ListPaginationInterface, 
  SliderCreateModel, 
  SliderDTO, 
  SliderListElementInterface, 
  SliderUpdateModel, 
  WebsiteSliderTypeEnum 
} from '@on-arte/core-types';
import { ApiError, DirectionEnum } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { ListParams } from '@onArte/interfaces';
import { ApiResolve, ApiReject } from '@onArte/types';

import { api } from '../api';

export const getSliders: (
  params: Partial<ListParams>, type: WebsiteSliderTypeEnum
) => Promise<ListPaginationInterface<SliderListElementInterface>> = (
  params: Partial<ListParams>, type: WebsiteSliderTypeEnum
): Promise<ListPaginationInterface<SliderListElementInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<SliderListElementInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/sliders/list/${type}?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<SliderListElementInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getSliderDetails: (sliderId: number) => Promise<SliderDTO> = (
  sliderId: number
): Promise<SliderDTO> => new Promise(
  (resolve: ApiResolve<SliderDTO>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/sliders/${sliderId}`).then(
      (response: AxiosResponse<SliderDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updateSliderSortPosition: (
  sliderId: number, direction: DirectionEnum
) => Promise<void> = (sliderId: number, direction: DirectionEnum): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/sliders/${sliderId}/sort/${direction}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updateSliderVisibility: (sliderId: number, visible: boolean) => Promise<void> = (
  sliderId: number, visible: boolean
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/sliders/${sliderId}/visibility`, { visible }).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const deleteSlider: (sliderId: number) => Promise<void> = (sliderId: number): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.delete(`/sliders/${sliderId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const createSlider: (
  data: SliderCreateModel
) => Promise<SliderDTO> = (data: SliderCreateModel): Promise<SliderDTO> => new Promise(
  (resolve: ApiResolve<SliderDTO>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post('/sliders', data).then(
      (response: AxiosResponse<SliderDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const editSlider: (
  data: SliderUpdateModel, sliderId: number
) => Promise<SliderDTO> = (data: SliderUpdateModel, sliderId: number): Promise<SliderDTO> => new Promise(
  (resolve: ApiResolve<SliderDTO>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/sliders/${sliderId}`, data).then(
      (response: AxiosResponse<SliderDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
