import { DeliveryCompanyOrderFormData } from '@on-arte/ui';

export const initialDeliveryCompanyOrderForm: DeliveryCompanyOrderFormData = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  postalCode: '',
  city: '',
  country: ''
};
