import { 
  ListPaginationInterface, 
  PublicProfileListElementInterface, 
  PublicProfileTypeEnum, 
} from '@on-arte/core-types';
import { 
  ApiError, 
  DirectionEnum, 
  SuggestionItem, 
  UseLogger, 
  UseNotifications, 
  UseState, 
  useLogger, 
  useNotifications,
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';

import { getPublicProfiles, updatePublicProfileSortIndex, updatePublicProfilesSettings } from '@onArte/api';
import { ItemsSelectionBaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions } from '@onArte/hooks';
import { AutosuggestActions } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

import { NoDataMessage, StyledInformationBox } from '../highlighted.styled';

export const HighlightedPublicProfilesListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const { searchPublicProfilesAction }: AutosuggestActions = useAutosuggestActions();
  const [publicProfliesList, setPublicProfliesList]: UseState<PublicProfileListElementInterface[]>
    = useState<PublicProfileListElementInterface[]>([]);
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);

  const { refetch, isFetching }: QueryObserverResult = useQuery(
    [QueryKey.PublicProfilesList],
    (): Promise<ListPaginationInterface<PublicProfileListElementInterface>> => getPublicProfiles(
      { limit: 100, offset: 0, sortByHighlighted: true, highlighted: true, type: PublicProfileTypeEnum.Manufacturer }
    ),
    {
      onSuccess: (data: ListPaginationInterface<PublicProfileListElementInterface>): void => setPublicProfliesList(data.list),
      onError: (error: ApiError): void => logger(QueryKey.PublicProfilesList, error)
    }
  );

  const onSelectNewPublicProfileHandler: (suggestion: SuggestionItem) => void = (suggestion: SuggestionItem): void => {
    if (!suggestion.id) {
      return;
    }

    updatePublicProfilesSettings(suggestion.id, { highlighted: true })
      .then((): void => {
        addToast({ content: t('onarte.management.highlightedPublicProfilesList.updateHighlightedPublicProfilesSettings.success') });
        void refetch();
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  const onPositionChangeAction: (
    dir: DirectionEnum, auctionId: string
  ) => Promise<void> = (dir: DirectionEnum, profileId: string): Promise<void> => {
    return new Promise((resolve: () => void, reject: () => void): void => {
      updatePublicProfileSortIndex(profileId, dir)
        .then((): void => {
          void refetch()
            .then((): void => resolve());
        })
        .catch((): void => reject());
    });
  };

  const onRemoveAction: (auctionId: string) => void = (auctionId: string): void => {
    updatePublicProfilesSettings(auctionId, { highlighted: false })
      .then((): void => {
        addToast({ content: t('onarte.management.highlightedPublicProfilesList.updateHighlightedPublicProfilesSettings.removeSuccess') });
        void refetch();
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  return (
    <ItemsSelectionBaseView 
      pageTitleSettings={{
        title: t('onarte.management.meta.contentHighlightedPublicProfilesList.title'),
        withUnderline: true
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentHighlightedPublicProfilesList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentHighlightedPublicProfilesList)?.url ?? '/' 
        },
      ]}
      children={
        !isFetching && (
          !publicProfliesList.length ? (
            <NoDataMessage>{t('onarte.management.highlightedPublicProfilesList.noProfiles')}</NoDataMessage>
          ) : (
            publicProfliesList.map((profile: PublicProfileListElementInterface, index: number): JSX.Element => (
              <StyledInformationBox
                key={index}
                informationDetails={{
                  name: profile.name,
                  avatar: profile.avatar?.path
                }}
                onRemove={(): void => onRemoveAction(profile.id)}
                isFirst={index === 0}
                isLast={index === (publicProfliesList.length - 1)}
                onPositionChangeAsync={(dir: DirectionEnum): Promise<void> => onPositionChangeAction(dir, profile.id)}
                setIsLoading={setIsLoading}
              />
            ))
          )
        )
      }
      onSelectNewItemHandler={onSelectNewPublicProfileHandler}
      autosuggestAction={(
        inputValue: string
      ): Promise<SuggestionItem[]> => searchPublicProfilesAction(inputValue, PublicProfileTypeEnum.Manufacturer)}
      isLoading={isLoading}
      autosuggestSectionTitle={t('onarte.management.highlightedPublicProfilesList.profilesAdd')}
      listSectionTitle={t('onarte.management.highlightedPublicProfilesList.profilesList')}
    />
  );
};
