import { PublicProfileStatusEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const publicProfilesStatusConfig: Record<PublicProfileStatusEnum, Status> = {
  [PublicProfileStatusEnum.Active]: {
    label: 'onarte.common.publicProfileStatuses.active',
    background: Color.Success100,
    color: Color.Success500
  },
  [PublicProfileStatusEnum.InActive]: {
    label: 'onarte.common.publicProfileStatuses.inActive',
    background: Color.Blue100,
    color: Color.Blue500
  },
  [PublicProfileStatusEnum.Unverified]: {
    label: 'onarte.common.publicProfileStatuses.unverified',
    background: Color.Error100,
    color: Color.Error500
  },
};
