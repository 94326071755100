import { OrangeStripeSectionUpdateModel } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useOrangeStripeEditForm: () => Yup.SchemaOf<OrangeStripeSectionUpdateModel> 
  = (): Yup.SchemaOf<OrangeStripeSectionUpdateModel> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      text: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(2)
        .max(255),
      link: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(2)
        .max(255),
    });
  };
