import React from 'react';

import { Container, MessageContainer } from './notFound.styled';

export const NotFoundView: React.FC = (): JSX.Element => {
  return (
    <Container>
      <MessageContainer>
        <span>Przepraszamy! Nie znaleziono poszukiwanej strony</span>
      </MessageContainer>
    </Container>
  );
};
