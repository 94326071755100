import { ManufacturerCreateModel } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useAuthorsAddFormValidation: () => Yup.SchemaOf<ManufacturerCreateModel> = (): Yup.SchemaOf<ManufacturerCreateModel> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    name: Yup.string()
      .required(t('onarte.common.requiredField')),
    userId: Yup.string().optional(),
  });
};
