import { ApiError, useNotifications, UseNotifications } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';

import { deleteItem, verifyItem } from '@onArte/api';
import { QueryKey } from '@onArte/enums';

import { ArtworkListMethods } from './artworksList.types';

export const useArtworkListMethods: () => ArtworkListMethods = (): ArtworkListMethods => {
  const { t }: TransProps<never> = useTranslation();
  const queryClient: QueryClient = useQueryClient();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();

  const approveArtwork: (itemId: string, itemName: string) => void = (itemId: string, itemName: string): void => {
    showSmallDialog({
      header: t('onarte.management.notifications.artworkApproval.header'),
      content: t('onarte.management.notifications.artworkApproval.content', { itemName }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          verifyItem(itemId, { accepted: true })
            .then((): void => {
              hideSmallDialog();
              addToast({ content: t('onarte.management.notifications.artworkApproval.success', { itemName }) });
              void queryClient.invalidateQueries(QueryKey.ArtworksList);
            })
            .catch((): void => {
              addToast({ content: t('onarte.management.notifications.artworkApproval.failure', { itemName }) });
            });
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel'),
      },
    });
  };

  const rejectArtwork: (itemId: string, itemName: string) => void = (itemId: string, itemName: string): void => {
    showSmallDialog({
      header: t('onarte.management.notifications.artworkRejection.header'),
      content: t('onarte.management.notifications.artworkRejection.content', { itemName }),
      acceptButton: {
        label: t('onarte.common.discard'),
        action: (): void => {
          verifyItem(itemId, { accepted: false })
            .then((): void => {
              hideSmallDialog();
              addToast({ content: t('onarte.management.notifications.artworkRejection.success', { itemName }) });
              void queryClient.invalidateQueries(QueryKey.ArtworksList);
            })
            .catch((): void => {
              addToast({ content: t('onarte.management.notifications.artworkRejection.failure', { itemName }) });
            });
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel'),
      },
    });
  };

  const removeItem: (itemId: string, itemName: string) => void = (itemId: string, itemName: string): void => {
    showSmallDialog({
      header: t('onarte.management.notifications.artworkRemoval.header'),
      content: t('onarte.management.notifications.artworkRemoval.content', { itemName }),
      acceptButton: {
        label: t('onarte.common.delete'),
        action: (): void => {
          deleteItem(itemId)
            .then((): void => {
              hideSmallDialog();
              addToast({ content: t('onarte.management.notifications.artworkRemoval.success', { itemName }) });
              void queryClient.invalidateQueries(QueryKey.ArtworksList);
            })
            .catch((error: ApiError): void => {
              addToast({ content: t(error.message) });
            });
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel'),
      },
    });
  };

  return {
    approveArtwork,
    rejectArtwork,
    removeItem
  };
};
