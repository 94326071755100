import {
  ChangePasswordModel,
  InternalUserProfileInterface,
  ListPaginationInterface,
  UserListElementInterface,
  UserManagementUpdateModel
} from '@on-arte/core-types';
import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { UsersListParams } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const getUsers: (
  params: Partial<UsersListParams>
) => Promise<ListPaginationInterface<UserListElementInterface>> = (
  params: Partial<UsersListParams>
): Promise<ListPaginationInterface<UserListElementInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<UserListElementInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/users?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<UserListElementInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getUser: (
  userId: string
) => Promise<InternalUserProfileInterface> = (
  userId: string
): Promise<InternalUserProfileInterface> => new Promise(
  (resolve: ApiResolve<InternalUserProfileInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/users/${userId}`).then(
      (response: AxiosResponse<InternalUserProfileInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const editUser: (
  userId: string, data: UserManagementUpdateModel
) => Promise<InternalUserProfileInterface> = (
  userId: string, data: UserManagementUpdateModel
): Promise<InternalUserProfileInterface> => new Promise(
  (resolve: ApiResolve<InternalUserProfileInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/users/${userId}`, data).then(
      (response: AxiosResponse<InternalUserProfileInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const changeUserPassword: (
  userId: string, data: ChangePasswordModel
) => Promise<InternalUserProfileInterface> = (
  userId: string, data: ChangePasswordModel
): Promise<InternalUserProfileInterface> => new Promise(
  (resolve: ApiResolve<InternalUserProfileInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/users/${userId}/password`, data).then(
      (response: AxiosResponse<InternalUserProfileInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
