import { WebsiteSliderButtonPathTypeEnum } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AddOrUpdateSliderForm } from '@onArte/interfaces';

export const useAddOrUpdateSliderForm: () => Yup.SchemaOf<AddOrUpdateSliderForm> = (): Yup.SchemaOf<AddOrUpdateSliderForm> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    header: Yup.string()
      .required(t('onarte.common.requiredField'))
      .min(3)
      .max(500),
    imageId: Yup.string()
      .required(t('onarte.common.requiredField'))
      .min(32)
      .max(36),
    isButtonVisible: Yup.boolean()
      .required(t('onarte.common.requiredField')),
    buttonLabel: Yup.string().when('isButtonVisible', {
      is: true,
      then: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(3)
        .max(1000),
    }),
    buttonPath: Yup.string().when('isButtonVisible', {
      is: true,
      then: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(3)
        .max(1000),
    }),
    buttonPathType: Yup.mixed().when('isButtonVisible', {
      is: true,
      then: Yup.mixed<WebsiteSliderButtonPathTypeEnum>()
        .oneOf(Object.values(WebsiteSliderButtonPathTypeEnum))
        .required(t('onarte.common.requiredField')),
    }),
    isSecondButtonVisible: Yup.boolean()
      .required(t('onarte.common.requiredField')),
    secondButtonLabel: Yup.string().when('isSecondButtonVisible', {
      is: true,
      then: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(3)
        .max(1000),
    }),
    secondButtonPath: Yup.string().when('isSecondButtonVisible', {
      is: true,
      then: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(3)
        .max(1000),
    }),
    secondButtonPathType: Yup.mixed().when('isSecondButtonVisible', {
      is: true,
      then: Yup.mixed<WebsiteSliderButtonPathTypeEnum>()
        .oneOf(Object.values(WebsiteSliderButtonPathTypeEnum))
        .required(t('onarte.common.requiredField')),
    }),
    description: Yup.string()
      .notRequired()
      .nullable()
      .min(3)
      .max(500),
    isLinkedItemVisible: Yup.boolean()
      .required(t('onarte.common.requiredField')),
    linkedItemId: Yup.string().when('isLinkedItemVisible', {
      is: true,
      then: Yup.string()
        .required(t('onarte.common.requiredField'))
        .min(32)
        .max(36)
    })
  });
};
