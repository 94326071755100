import { Autosuggest } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FormSectionWithStandardSpace } from '@onArte/theme';

import { BaseView } from '../baseView/baseView.component';

import { LoadingOverlay, LoadingWrapper } from './itemsSelectionBaseView.styled';
import { ItemsSelectionBaseViewProps } from './itemsSelectionBaseView.types';

export const ItemsSelectionBaseView: React.FC<ItemsSelectionBaseViewProps> = (props: ItemsSelectionBaseViewProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    pageTitleSettings, 
    breadcrumbs, 
    children, 
    onSelectNewItemHandler, 
    isLoading,
    autosuggestAction,
    autosuggestSectionTitle = t('onarte.management.itemsSelectionBaseView.artworkAdd'),
    listSectionTitle = t('onarte.management.itemsSelectionBaseView.artworksList')
  }: ItemsSelectionBaseViewProps = props;

  return (
    <BaseView
      pageTitleSettings={pageTitleSettings}
      breadcrumbs={breadcrumbs}
    >
      <FormSectionWithStandardSpace title={autosuggestSectionTitle ?? ''}>
        <Autosuggest
          label={t('onarte.management.itemsSelectionBaseView.artworkChoose.label')}
          onValueInputChangeAsync={autosuggestAction}
          onSelect={onSelectNewItemHandler}
        />
      </FormSectionWithStandardSpace>
      <FormSectionWithStandardSpace title={listSectionTitle ?? ''}>
        <LoadingWrapper>
          <LoadingOverlay $isLoading={!!isLoading} />
          {children}
        </LoadingWrapper>
      </FormSectionWithStandardSpace>
    </BaseView>
  );
};
