import React from 'react';

import { useAuth } from '@onArte/hooks';
import { UseAuth } from '@onArte/interfaces';

import { Container, MessageContainer } from './default.styled';

export const DefaultView: React.FC = (): JSX.Element => {
  const { signOut, userData }: UseAuth = useAuth();

  return (
    <Container>
      <MessageContainer>
        <span>Witaj {userData?.firstName} {userData?.lastName}!</span>
        <span>Strona: {window.location.pathname}</span>
        <button onClick={signOut}>Wyloguj</button>
      </MessageContainer>
    </Container>
  );
};
