import { EventListElementInterface, EventStatusEnum, ListPaginationInterface } from '@on-arte/core-types';
import {
  ApiError,
  ElementsTable,
  ElementsTableRow,
  getPathWithParams,
  IconName,
  Input,
  InputTheme,
  Language,
  PaginationDetails,
  useFormatDate,
  UseFormatDate,
  useLogger,
  UseLogger,
  UseNotifications,
  useNotifications,
  usePagination,
  UseState
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { searchEvents } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { eventStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

import { useEventsListMethods } from './eventsList.hook';
import { EventsListMethods } from './eventsList.types';

export const EventsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [events, setEvents]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const { removeItem }: EventsListMethods = useEventsListMethods();
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);
  const navigate: NavigateFunction = useNavigate();
  const { formatDate }: UseFormatDate = useFormatDate(Language.Pl);
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const [searchInputText, setSearchInputText]: UseState<string> = useState<string>('');

  useQuery(
    [QueryKey.EventsList, offset, searchInputText],
    (): Promise<ListPaginationInterface<EventListElementInterface>> => searchEvents({
      limit: itemsPerPage, search: searchInputText, offset
    }),
    {
      onSuccess: (data: ListPaginationInterface<EventListElementInterface>): void => {
        setMaxItems(data.amount);
        setEvents(data.list.map((item: EventListElementInterface): ElementsTableRow => {
          return {
            cells: [
              { name: 'name', content: item.name },
              { name: 'startDate', content: item.startTimestamp ? formatDate(item.startTimestamp, 'DD MMMM YYYY') : '' },
              { name: 'endDate', content: item.endTimestamp ? formatDate(item.endTimestamp, 'DD MMMM YYYY') : '' },
              {
                name: 'status',
                content: {
                  label: t(eventStatusConfig[item.status].label ?? item.status),
                  background: eventStatusConfig[item.status].background,
                  color: eventStatusConfig[item.status].color,
                },
              },
            ],
            contextMenuPositions: [
              {
                label: t('onarte.management.eventsList.actions.auctionsEdit'),
                internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.EventAuctionsEdit)?.url ?? '/', { id: item.id }),
                icon: IconName.Gavel
              },
              {
                label: t('onarte.management.eventsList.actions.edit'),
                internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.EventEditForm)?.url ?? '/', { id: item.id }),
                icon: IconName.Edit
              },
              ...(item.status !== EventStatusEnum.Finished
                ? [{
                  label: t('onarte.management.eventsList.actions.revoke'),
                  action: (): void => removeItem(item.id, item.name),
                  icon: IconName.Delete
                }]
                : []
              ),
            ]
          };
        }));
      },
      onError: (error: ApiError): void => {
        logger(QueryKey.EventsList, error, 'error');
        addToast({ content: t(error.message) });
      },
    }
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.eventsList.title'),
        buttonLabel: t('onarte.management.authorsList.actions.add'),
        buttonAction: (): void => navigate(getRouteDetailsByName(RouteNameEnum.EventAddForm)?.url ?? '/'),
      }}
      pageTitleChildren={
        <Input
          label={t('onarte.common.searchInput.label')}
          icon={IconName.Loupe}
          inputTheme={InputTheme.Small}
          value={searchInputText}
          onChange={(value: string): void => {
            setSearchInputText(value);
            setPage(1);
          }}
        />
      }
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.eventsList.title'), path: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/' },
      ]}
    >
      <ElementsTable
        columns={[
          { name: 'name', label: t('onarte.management.eventsList.tableColumns.name') },
          { name: 'startDate', label: t('onarte.management.eventsList.tableColumns.startDate') },
          { name: 'endDate', label: t('onarte.management.eventsList.tableColumns.endDate') },
          { name: 'status', label: t('onarte.management.eventsList.tableColumns.status') },
        ]}
        rows={events}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        initialPageCount={page}
      />
    </BaseView>
  );
};
