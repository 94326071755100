import { AttachmentTypeEnum, CollectionCreateModel } from '@on-arte/core-types';
import { 
  AddPhotoFile, 
  AddPhotoTheme, 
  FormFieldType, 
  FormFieldValue,
  getSingleValueFromFormFieldValue, 
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useFiles } from '@onArte/hooks';
import { UseFiles } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormikForm, FormSectionWithStandardSpace } from '@onArte/theme';

import { useCollectionFormValidation } from './collectionForm.hooks';
import { Container } from './collectionForm.styled';
import { CollectionFormProps } from './collectionForm.types';

export const CollectionForm: React.FC<CollectionFormProps> = forwardRef((
  props: CollectionFormProps, ref: React.Ref<FormikProps<CollectionCreateModel>>
): JSX.Element => {
  const {
    initialValues,
    isFormSubmitted,
    files,
    className
  }: CollectionFormProps = props;
  const { t }: TransProps<never> = useTranslation();
  const CollectionFormValidationSchema: Yup.SchemaOf<CollectionCreateModel> = useCollectionFormValidation();
  const { addFile }: UseFiles = useFiles();

  return (
    <Container className={className}>
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={(): void => undefined}
        validationSchema={CollectionFormValidationSchema}
        validateOnChange={isFormSubmitted}
        validateOnBlur={isFormSubmitted}
      >
        {({ handleSubmit, setFieldValue, errors, values }: FormikProps<CollectionCreateModel>): JSX.Element => (
          <FormikForm onSubmit={handleSubmit}>
            <FormSectionWithStandardSpace title={t('onarte.common.collectionForm.infoSection.label')}>
              <FormFieldWithStandardSpace
                label={t('onarte.common.collectionForm.name.label')}
                fieldType={FormFieldType.Input}
                fieldName='name'
                setFieldValue={setFieldValue}
                value={values.name}
                validationMessage={errors.name || ''}
                additionalFieldProps={{
                  maxCharsCount: 255
                }}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.common.collectionForm.title.label')}
                fieldType={FormFieldType.Textarea}
                value={values.title}
                setFieldValue={setFieldValue}
                fieldName='title'
                validationMessage={errors.title || ''}
                additionalFieldProps={{
                  maxCharsCount: 255
                }}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.common.collectionForm.description.label')}
                fieldType={FormFieldType.Textarea}
                value={values.description}
                setFieldValue={setFieldValue}
                fieldName='description'
                validationMessage={errors.description || ''}
              />
            </FormSectionWithStandardSpace>
            <FormSectionWithStandardSpace title={t('onarte.common.collectionForm.photosSection.label')}>
              <FormFieldWithStandardSpace
                fieldType={FormFieldType.AddPhoto}
                value={values.coverId}
                setFieldValue={(field: string, value: FormFieldValue): void => {
                  setFieldValue(field, getSingleValueFromFormFieldValue<AddPhotoFile>(value).id);
                }}
                fieldName='coverId'
                validationMessage={errors.coverId || ''}
                additionalFieldProps={{
                  addPhotoTheme: AddPhotoTheme.WithBoxLoaderOnInit,
                  requirements: [
                    t('onarte.common.collectionForm.coverId.requirements.1'),
                    t('onarte.common.collectionForm.coverId.requirements.2'),
                    t('onarte.common.collectionForm.coverId.requirements.3'),
                    t('onarte.common.collectionForm.coverId.requirements.4'),
                  ],
                  attachmentType: AttachmentTypeEnum.CollectionPhoto,
                  acceptedFilesTypes: 'image/png, image/jpeg',
                  maxFileSizeInBytes: 5 * 1024 * 1024,
                  maxNumberOfFiles: 1,
                  files: files ?? []
                }}
                actions={{ addFile }}
              />
            </FormSectionWithStandardSpace>
          </FormikForm>
        )}
      </Formik>
    </Container>
  );
});
