import { AuctionTypeEnum } from '@on-arte/core-types';
import { FilterOption } from '@on-arte/ui';

export const auctionTypeConfig: Record<AuctionTypeEnum, FilterOption> = {
  [AuctionTypeEnum.Bidding]: {
    name: AuctionTypeEnum.Bidding,
    label: 'onarte.common.bidding',
  },
  [AuctionTypeEnum.BuyNow]: {
    name: AuctionTypeEnum.BuyNow,
    label: 'onarte.common.buyNow',
  },
  [AuctionTypeEnum.PriceProposal]: {
    name: AuctionTypeEnum.PriceProposal,
    label: 'onarte.common.priceProposal',
  }
};
