import {
  AttachmentInterface,
  AttachmentTypeEnum,
  CategoryAttributeInterface,
  CategoryWithAttributesInterface,
  ItemAttributeInterface,
  ItemWithChangesInterface,
  SimpleBlogPostInterface
} from '@on-arte/core-types';
import { DetailsTableValueType, MenuPositionData, TableDetailsRowData } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { itemStatusConfig } from '@onArte/constants';
import { ItemsHelpers, UseArtworkTableDetails } from '@onArte/interfaces';
import { getAttachmentNewValueOrUndefined } from '@onArte/utils';

import { useItemsHelpers } from './useItemsHelpers.hook';

export const useArtworkTableDetails: () => UseArtworkTableDetails = (): UseArtworkTableDetails => {
  const { t }: TransProps<never> = useTranslation();
  const { getAttributeValueByType }: ItemsHelpers = useItemsHelpers();

  const getAttachmentPathsByType: (attachments: AttachmentInterface[], type: AttachmentTypeEnum) => string[] = (
    attachments: AttachmentInterface[], type: AttachmentTypeEnum
  ): string[] => {
    return attachments
      .filter((attachment: AttachmentInterface): boolean => attachment.type === type)
      .map((attachment: AttachmentInterface): string => attachment.path);
  };

  const getTableRows: (
    itemData: ItemWithChangesInterface, itemCategories: CategoryWithAttributesInterface[], withChanges?: boolean
  ) => TableDetailsRowData[] = (
    itemData: ItemWithChangesInterface, itemCategories: CategoryWithAttributesInterface[], withChanges: boolean = false
  ): TableDetailsRowData[] => {
    const shouldShowNewValue: boolean = withChanges && !!itemData.changes.length;

    const getNewValueOrUndefined: (
      oldValue: string | null | undefined, newValue: string | null | undefined
    ) => string | undefined = (
      oldValue: string | null | undefined, newValue: string | null | undefined
    ): string | undefined => {
      if (shouldShowNewValue && oldValue !== newValue && newValue) {
        return newValue;
      }
    };

    const tableRows: TableDetailsRowData[] = [
      {
        label: t('onarte.common.itemsAttributes.author.label'),
        value: itemData.manufacturer.name,
        newValue: itemData.changes.length
          ? getNewValueOrUndefined(itemData.manufacturer.name, itemData.changes[0].data.manufacturer.name)
          : undefined,
      },
      {
        label: t('onarte.common.itemsAttributes.name.label'),
        value: itemData.name,
        newValue: itemData.changes.length
          ? getNewValueOrUndefined(itemData.name, itemData.changes[0].data.name)
          : undefined,
      },
      {
        label: t('onarte.common.itemsAttributes.status.label'),
        value: {
          label: t(itemStatusConfig[itemData.status].label ?? itemData.status),
          background: itemStatusConfig[itemData.status].background,
          color: itemStatusConfig[itemData.status].color,
        },
        type: DetailsTableValueType.Status
      },
      {
        label: t('onarte.common.itemsAttributes.description.label'),
        value: itemData.description ?? '',
        newValue: itemData.changes.length
          ? getNewValueOrUndefined(itemData.description, itemData.changes[0].data.description)
          : undefined,
      },
      {
        label: t('onarte.common.itemsAttributes.owner.label'),
        value: itemData.owner.name,
      },
      {
        label: t('onarte.common.itemsAttributes.year.label'),
        value: itemData.year?.toString() ?? '',
        newValue: itemData.changes.length
          ? getNewValueOrUndefined(itemData.year?.toString(), itemData.changes[0].data.year?.toString())
          : undefined,
      },
      {
        label: t('onarte.common.itemsAttributes.category.label'),
        value: t(`onarte.common.itemsCategories.${itemData.category.name}`),
      },
    ];
    const categoryProperties: CategoryWithAttributesInterface | undefined = itemCategories.find(
      (category: CategoryWithAttributesInterface) => category.id === itemData.category.id
    );
    if (categoryProperties) {
      const tableRowUpdateAction = (attribute: ItemAttributeInterface): void => {
        const attributeProperties: CategoryAttributeInterface | undefined = categoryProperties.attributes.find(
          (attributeItem: CategoryAttributeInterface) => attributeItem.id === attribute.attributeId
        );
        const attributeOldValue: string | undefined = itemData.attributes
          .find((attributeItem: ItemAttributeInterface): boolean => attributeItem.attributeId === attribute.attributeId)?.value;
        const attributeNewValue: string | undefined = shouldShowNewValue
          ? itemData.changes[0].data.attributes
            .find((attributeItem: ItemAttributeInterface): boolean => attributeItem.attributeId === attribute.attributeId)?.value
          : undefined;
        if (attributeProperties) {
          tableRows.push({
            label: t(attributeProperties.label),
            value: getAttributeValueByType(attributeOldValue ?? '-', attributeProperties),
            newValue: shouldShowNewValue && attributeNewValue
              ? getNewValueOrUndefined(
                getAttributeValueByType(attributeOldValue ?? '', attributeProperties),
                getAttributeValueByType(attributeNewValue, attributeProperties)
              )
              : undefined,
          });
        }
      };
      if (withChanges) {
        itemData.changes[0].data.attributes.forEach((attribute: ItemAttributeInterface): void => tableRowUpdateAction(attribute));
      } else {
        itemData.attributes.forEach((attribute: ItemAttributeInterface): void => tableRowUpdateAction(attribute));
      }
    }
    tableRows.push({
      label: t('onarte.common.itemsAttributes.uploaded_itemPhoto.label'),
      type: DetailsTableValueType.Image,
      value: getAttachmentPathsByType(itemData.attachments, AttachmentTypeEnum.ItemPhoto),
      newValue: shouldShowNewValue
        ? getAttachmentNewValueOrUndefined(
          getAttachmentPathsByType(itemData.attachments, AttachmentTypeEnum.ItemPhoto),
          getAttachmentPathsByType(itemData.changes[0].data.attachments, AttachmentTypeEnum.ItemPhoto),
        )
        : undefined
    });
    tableRows.push({
      label: t('onarte.common.itemsAttributes.uploaded_itemCertificate.label'),
      type: DetailsTableValueType.Image,
      value: getAttachmentPathsByType(itemData.attachments, AttachmentTypeEnum.ItemCertificate),
      newValue: shouldShowNewValue
        ? getAttachmentNewValueOrUndefined(
          getAttachmentPathsByType(itemData.attachments, AttachmentTypeEnum.ItemCertificate),
          getAttachmentPathsByType(itemData.changes[0].data.attachments, AttachmentTypeEnum.ItemCertificate),
        )
        : undefined
    });
    tableRows.push({
      label: t('onarte.common.itemsAttributes.uploaded_itemSignature.label'),
      type: DetailsTableValueType.Image,
      value: getAttachmentPathsByType(itemData.attachments, AttachmentTypeEnum.ItemSignature),
      newValue: shouldShowNewValue
        ? getAttachmentNewValueOrUndefined(
          getAttachmentPathsByType(itemData.attachments, AttachmentTypeEnum.ItemSignature),
          getAttachmentPathsByType(itemData.changes[0].data.attachments, AttachmentTypeEnum.ItemSignature),
        )
        : undefined,
    });
    tableRows.push({
      label: t('onarte.common.publicProfileDetails.blogPosts.label'),
      value: itemData.blogPosts.map((post: SimpleBlogPostInterface): MenuPositionData => ({
        label: post.title,
        // externalUrl: post.link,
        action: (): void => undefined, // TODO: remove this line and uncomment the one above when BE will be ready
      })),
      type: DetailsTableValueType.Url,
    });
  
    return tableRows;
  };

  return { getTableRows };
};
