import { SliderStatusEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const sliderStatusConfig: Record<SliderStatusEnum, Status> = {
  [SliderStatusEnum.Active]: {
    label: 'onarte.common.sliderStatuses.active',
    background: Color.Success100,
    color: Color.Success500
  },
  [SliderStatusEnum.Inactive]: {
    label: 'onarte.common.sliderStatuses.inactive',
    background: Color.Gray200,
    color: Color.Gray500
  }
};
