import { Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { LoadingOverlayProps } from './itemsSelectionBaseView.types';

export const LoadingWrapper: StyledComponent<'div'> = styled.div`
  position: relative;
`;

export const LoadingOverlay: StyledComponent<'div', LoadingOverlayProps> = styled.div<LoadingOverlayProps>`
  transition: .3s;
  display: ${({ $isLoading }: LoadingOverlayProps): string => !$isLoading ? 'none' : 'block'};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Color.Black};
  opacity: 0.2;
`;
