import { WebsiteSliderTypeEnum } from '@on-arte/core-types';
import React from 'react';

import { RouteNameEnum } from '@onArte/enums';

import { RecommendationsBaseView } from '../components';

export const RecommendationsArtistsEditFormView: React.FC = (): JSX.Element => {
  return (
    <RecommendationsBaseView 
      type={WebsiteSliderTypeEnum.RecommendationsArtists} 
      routeNameEnum={RouteNameEnum.ContentRecommendationsArtistsEditForm} 
      metaTitle='onarte.management.meta.contentRecommendationsArtistsEditForm.title'
    />
  );
};
