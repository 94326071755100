export enum RouteNameEnum {
  // artworks
  ArtworkDetails = 'artworkDetails',
  ArtworkEditChanges = 'artworkEditChanges',
  ArtworkEditForm = 'artworkEditForm',
  ArtworksList = 'artworksList',
  // authors
  AuthorsAddForm = 'authorsAddForm',
  AuthorEditForm = 'authorEditForm',
  AuthorsList = 'authorsList',
  // content collections
  ContentCollectionsList = 'contentCollectionsList',
  ContentCollectionAddForm = 'contentCollectionAddForm',
  ContentCollectionEditForm = 'contentCollectionEditForm',
  ContentCollectionItemsForm = 'contentCollectionItemsForm',
  // content sliders
  ContentSliderAddForm = 'contentSliderAddForm',
  ContentSliderEditForm = 'contentSliderEditForm',
  ContentSlidersList = 'contentSlidersList',
  // content orange stripe
  ContentOrangeStripeEditForm = 'contentOrangeStripeEditForm',
  // content recommendations
  ContentRecommendationsHomeEditForm = 'contentRecommmendationsHomeEditForm',
  ContentRecommendationsContactEditForm = 'contentRecommmendationsContactEditForm',
  ContentRecommendationsArtistsEditForm = 'contentRecommmendationsArtistsEditForm',
  ContentRecommendationsCollectorsEditForm = 'contentRecommmendationsCollectorsEditForm',
  // content highlighted
  ContentHighlightedBiddingList = 'contentHighlightedBiddingList',
  ContentHighlightedBuyNowList = 'contentHighlightedBuyNowList',
  ContentHighlightedPublicProfilesList = 'contentHighlightedPublicProfilesList',
  // content others
  ContentTextPages = 'contentTextPages',
  ContentFiles = 'contentFiles',
  // events
  EventAddForm = 'eventAddForm',
  EventEditForm = 'eventEditForm',
  EventAuctionsEdit = 'eventAuctionsEdit',
  EventsList = 'eventsList', 
  // orders
  OrderDetails = 'orderDetails',
  OrdersList = 'ordersList',
  // public profiles
  PublicProfileAddForm = 'publicProfileAddForm',
  PublicProfileEditForm = 'publicProfileEditForm',
  PublicProfileDetails = 'publicProfileDetails',
  PublicProfileVerifyChanges = 'publicProfileVerifyChanges',
  PublicProfilesList = 'publicProfilesList',
  // users
  UserEditForm = 'userEditForm',
  UsersList = 'usersList',
  // others
  Logout = 'logout',
  NotFound = 'notFound',
  ReportsList = 'reportsList',
  Sale = 'sale',
  Settings = 'settings',
  SignIn = 'signIn',
}
