import { PublicProfileManagementModel, PublicProfileVersionModel } from '@on-arte/core-types';
import {
  ApiError,
  DetailsTable,
  getPathWithParams,
  TableDetailsRowData,
  UseLogger,
  useLogger,
  UseNotifications,
  useNotifications,
  UseState
} from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { getPublicProfileDetails, verifyPublicProfileVersion } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { usePublicProfileTableDetails } from '@onArte/hooks';
import { UsePublicProfileTableDetails } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

export const PublicProfileVerifyChangesView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const [profileData, setProfileData]: UseState<PublicProfileManagementModel | null> = useState<PublicProfileManagementModel | null>(null);
  const { getTableRows }: UsePublicProfileTableDetails = usePublicProfileTableDetails();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();
  const { logger }: UseLogger = useLogger();

  useQuery(
    [QueryKey.PublicProfileDetails, id],
    (): Promise<PublicProfileManagementModel | void> => getPublicProfileDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: PublicProfileManagementModel): void => {
        if (!data.versions.length) {
          addToast({ content: t('onarte.common.noVersionForApproval', { name: data.name }) });
          navigate(getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/');
        }
        setProfileData(data);
      },
      onError: (error: ApiError): void => {
        logger(QueryKey.PublicProfileDetails, error);
        addToast({ content: t(error.message) });
      }
    }
  );

  const tableData: TableDetailsRowData[] = useMemo(
    (): TableDetailsRowData[] => {
      if (!profileData) {
        return [];
      }

      return getTableRows(profileData, true);
    },
    [profileData]
  );

  const rejectVersion: () => void = (): void => {
    if (!id || !profileData?.versions?.length) {
      return;
    }
    const lastVersion: PublicProfileVersionModel = profileData.versions[0];

    showSmallDialog({
      header: t('onarte.management.publicProfileVerifyChangesView.versionRejection.header'),
      content: t('onarte.management.publicProfileVerifyChangesView.versionRejection.content', { name: lastVersion.data.name }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          verifyPublicProfileVersion(id, { accepted: false, versionId: lastVersion.id })
            .then((): void => {
              hideSmallDialog();
              addToast({
                content: t('onarte.management.publicProfileVerifyChangesView.versionRejection.success', { name: lastVersion.data.name })
              });
              navigate(getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/');
            })
            .catch((error: ApiError) => addToast({ content: t(error.message) }));
        }
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  const approveVersion: () => void = (): void => {
    if (!id || !profileData?.versions?.length) {
      return;
    }
    const lastVersion: PublicProfileVersionModel = profileData.versions[0];

    showSmallDialog({
      header: t('onarte.management.publicProfileVerifyChangesView.versionApprovement.header'),
      content: t('onarte.management.publicProfileVerifyChangesView.versionApprovement.content', { name: profileData.name }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          verifyPublicProfileVersion(id, { accepted: true, versionId: lastVersion.id })
            .then((): void => {
              hideSmallDialog();
              addToast({
                content: t('onarte.management.publicProfileVerifyChangesView.versionApprovement.success', { name: lastVersion.data.name })
              });
              navigate(getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/');
            })
            .catch((error: ApiError) => addToast({ content: t(error.message) }));
        }
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: profileData?.versions?.[0]?.data?.name ?? '',
        secondButtonAction: rejectVersion,
        secondButtonLabel: t('onarte.management.publicProfileVerifyChangesView.secondButtonLabel'),
        buttonAction: approveVersion,
        buttonLabel: t('onarte.management.publicProfileVerifyChangesView.buttonLabel')
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        {
          label: t('onarte.management.meta.publicProfilesList.title'),
          path: getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/'
        },
        {
          label: profileData?.versions?.[0]?.data?.name ?? '',
          path: getPathWithParams(getRouteDetailsByName(RouteNameEnum.PublicProfileDetails)?.url ?? '/', { id: profileData?.id ?? '' })
        },
        { label: t('onarte.management.meta.publicProfileVerifyChanges.title'), path: '/' },
      ]}
    >
      <DetailsTable rows={tableData} />
    </BaseView>
  );
};
