import { 
  CollectionCreateModel, 
  CollectionDTO, 
  CollectionListElementInterface, 
  ListPaginationInterface 
} from '@on-arte/core-types';
import { ApiError, DirectionEnum } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { ListParams } from '@onArte/interfaces';
import { ApiResolve, ApiReject } from '@onArte/types';

import { api } from '../api';

export const getCollections: (
  params: Partial<ListParams>
) => Promise<ListPaginationInterface<CollectionListElementInterface>> = (
  params: Partial<ListParams>
): Promise<ListPaginationInterface<CollectionListElementInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<CollectionListElementInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/collections?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<CollectionListElementInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getCollectionDetails: (collectionId: string) => Promise<CollectionDTO> = (
  collectionId: string
): Promise<CollectionDTO> => new Promise(
  (resolve: ApiResolve<CollectionDTO>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/collections/${collectionId}`).then(
      (response: AxiosResponse<CollectionDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const sortCollections: (
  collectionId: string, direction: DirectionEnum
) => Promise<void> = (collectionId: string, direction: DirectionEnum): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/collections/${collectionId}/sort/${direction}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updateVisibility: (collectionId: string, visible: boolean) => Promise<void> = (
  collectionId: string, visible: boolean
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/collections/${collectionId}/visibility`, { visible }).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const deleteCollection: (collectionId: string) => Promise<void> = (collectionId: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.delete(`/collections/${collectionId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const createCollection: (
  data: CollectionCreateModel
) => Promise<CollectionDTO> = (data: CollectionCreateModel): Promise<CollectionDTO> => new Promise(
  (resolve: ApiResolve<CollectionDTO>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post('/collections', data).then(
      (response: AxiosResponse<CollectionDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const editCollection: (
  data: CollectionCreateModel, collectionId: string
) => Promise<CollectionDTO> = (data: CollectionCreateModel, collectionId: string): Promise<CollectionDTO> => new Promise(
  (resolve: ApiResolve<CollectionDTO>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/collections/${collectionId}`, data).then(
      (response: AxiosResponse<CollectionDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const addItemToCollection: (
  collectionId: string, itemId: string
) => Promise<void> = (collectionId: string, itemId: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/collections/${collectionId}/items/${itemId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const removeItemFromCollection: (
  collectionId: string, itemId: string
) => Promise<void> = (collectionId: string, itemId: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.delete(`/collections/${collectionId}/items/${itemId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
