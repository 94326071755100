import { AuthReducerAction } from '@onArte/enums';
import { AuthContextState } from '@onArte/interfaces';
import { AuthReducerActions } from '@onArte/types';

export const authReducer = (state: AuthContextState, action: AuthReducerActions): AuthContextState => {
  switch (action.type) {
    case AuthReducerAction.SignIn:
      return { ...state, ...action.payload };
    case AuthReducerAction.SignOut:
      return {} as AuthContextState;
    default:
      return state;
  }
};
