import {
  ListPaginationInterface,
  PublicProfileListElementInterface,
  PublicProfileStatusEnum,
  PublicProfileTypeEnum
} from '@on-arte/core-types';
import { 
  ApiError,
  ElementsTable, 
  ElementsTableRow, 
  IconName, 
  Input, 
  InputTheme, 
  PaginationDetails, 
  TabButton, 
  UseLogger, 
  UseState, 
  getPathWithParams, 
  useLogger, 
  usePagination, 
  useRedirect,
  UseRedirect,
  useNotifications,
  UseNotifications
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQuery, useQueryClient } from 'react-query';

import { changePublicProfileActiveStatus, getPublicProfiles } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { publicProfilesStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

import { publicProfilesTypeFilters } from './publicProfilesList.configs';
import { TabFiltersContainer } from './publicProfilesList.styled';
import { PublicProfilesTypeFilter } from './publicProfilesList.types';

export const PublicProfilesListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);
  const { logger }: UseLogger = useLogger();
  const [activeFilter, setActiveFilter]: UseState<PublicProfileTypeEnum> 
    = useState<PublicProfileTypeEnum>(PublicProfileTypeEnum.Manufacturer);
  const [searchInputValue, setSearchInputValue]: UseState<string> = useState<string>('');
  const [profiles, setProfiles]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const queryClient: QueryClient = useQueryClient();

  const changeActiveStatus: (profileId: string, shouldBeActivate: boolean, profileName: string) => void = (
    profileId: string, shouldBeActivate: boolean, profileName: string
  ): void => {
    showSmallDialog({
      header: t(`onarte.common.changePublicProfileActiveFlagDialog.${shouldBeActivate ? 'show' : 'hide'}.header`),
      content: t(
        `onarte.common.changePublicProfileActiveFlagDialog.${shouldBeActivate ? 'show' : 'hide'}.content`,
        { name: profileName }
      ),
      acceptButton: {
        label: t(`onarte.common.changePublicProfileActiveFlagDialog.${shouldBeActivate ? 'show' : 'hide'}.acceptButton`),
        action: (): void => {
          changePublicProfileActiveStatus(profileId, { active: shouldBeActivate })
            .then((): void => {
              hideSmallDialog();
              void queryClient.invalidateQueries(QueryKey.PublicProfilesList);
              addToast({
                content: t(
                  `onarte.common.changePublicProfileActiveFlagDialog.actionStatus.${shouldBeActivate ? 'published' : 'hidden'}`,
                  { name: profileName }
                )
              });
            })
            .catch((error: ApiError): void => addToast({ content: t(error.message) }));
        },
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  useQuery(
    [QueryKey.PublicProfilesList, offset, activeFilter, searchInputValue],
    (): Promise<ListPaginationInterface<PublicProfileListElementInterface>> => getPublicProfiles(
      { offset, type: activeFilter, limit: itemsPerPage, phrase: searchInputValue }
    ),
    {
      onSuccess: (data: ListPaginationInterface<PublicProfileListElementInterface>): void => {
        setMaxItems(data.amount);
        setProfiles(data.list.map((profile: PublicProfileListElementInterface): ElementsTableRow => ({
          cells: [
            { name: 'name', content: profile.name },
            {
              name: 'status',
              content: {
                label: t(publicProfilesStatusConfig[profile.status].label),
                background: publicProfilesStatusConfig[profile.status].background,
                color: publicProfilesStatusConfig[profile.status].color,
              },
            },
            {
              name: 'hasChangesToVerified',
              content: profile.hasChangesToVerified
                ? t('onarte.common.yes')
                : t('onarte.common.no')
            },
          ],
          contextMenuPositions: [
            {
              label: t('onarte.management.publicProfilesList.actions.details'),
              internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.PublicProfileDetails)?.url ?? '/', { id: profile.id }),
              icon: IconName.Description
            },
            ...(profile.status !== PublicProfileStatusEnum.Unverified ? [{
              label: t('onarte.common.edit'),
              internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.PublicProfileEditForm)?.url ?? '/', { id: profile.id }),
              icon: IconName.Edit
            }] : []),
            ...(profile.hasChangesToVerified ? [{
              label: t('onarte.management.publicProfilesList.actions.verifyVersion'),
              internalPath: getPathWithParams(
                getRouteDetailsByName(RouteNameEnum.PublicProfileVerifyChanges)?.url ?? '/',
                { id: profile.id }
              ),
              icon: IconName.Receipt
            }] : []),
            ...(profile.status === PublicProfileStatusEnum.Active ? [{
              label: t('onarte.common.hide'),
              icon: IconName.Visibility,
              action: (): void => changeActiveStatus(profile.id, false, profile.name)
            }] : []),
            ...(profile.status === PublicProfileStatusEnum.InActive ? [{
              label: t('onarte.common.show'),
              icon: IconName.Visibility,
              action: (): void => changeActiveStatus(profile.id, true, profile.name)
            }] : [])
          ]
        })));
      },
      onError: (error: ApiError): void => logger(QueryKey.PublicProfilesList, error, 'error')
    }
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.publicProfilesList.title'),
        withUnderline: false,
        buttonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.PublicProfileAddForm)?.url ?? '/'),
        buttonLabel: t('onarte.management.publicProfilesList.actions.add')
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.publicProfilesList.title'),
          path: getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/' 
        },
      ]}
      pageTitleChildren={
        <Input
          label={t('onarte.common.searchInput.label')}
          icon={IconName.Loupe}
          inputTheme={InputTheme.Small}
          value={searchInputValue}
          onChange={(value: string): void => {
            setSearchInputValue(value);
            setPage(1);
          }}
        />
      }
    >
      <TabFiltersContainer>
        {publicProfilesTypeFilters.map((type: PublicProfilesTypeFilter): JSX.Element => (
          <TabButton 
            key={type.label}
            label={t(type.label)} 
            isActive={activeFilter === type.name} 
            onClick={(): void => {
              setActiveFilter(type.name);
              setPage(1);
            }} 
          />
        ))}
      </TabFiltersContainer>
      <ElementsTable
        columns={[
          { name: 'name', label: t('onarte.management.publicProfilesList.tableColumns.name') },
          { name: 'status', label: t('onarte.management.publicProfilesList.tableColumns.status') },
          { name: 'hasChangesToVerified', label: t('onarte.management.publicProfilesList.tableColumns.hasChangesToVerified') },
        ]}
        rows={profiles}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        initialPageCount={page}
      />
    </BaseView>
  );
};
