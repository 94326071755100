import { AttachmentInterface, ThumbnailAttachmentTypeEnum } from '@on-arte/core-types';

export const getImageThumbnail: (
  attachment: AttachmentInterface | null | undefined, thumbnailType: ThumbnailAttachmentTypeEnum
) => string = (
  attachment: AttachmentInterface | null | undefined, thumbnailType: ThumbnailAttachmentTypeEnum
): string => {
  if (!attachment) {
    return '';
  }

  const thumbnailsAttachment: AttachmentInterface | undefined = attachment.thumbnails?.find(
    (thumbnail: AttachmentInterface): boolean => (thumbnail.type as unknown as ThumbnailAttachmentTypeEnum) === thumbnailType
  );

  return thumbnailsAttachment
    ? thumbnailsAttachment.path
    : attachment.path;
};
