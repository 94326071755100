import { AuctionDetailsBox, BiddingUserData, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const StyledAuctionDetailsBox: StyledComponent<typeof AuctionDetailsBox> = styled(AuctionDetailsBox)`
  & + & {
    margin-top: 20px;
  }
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
`;

export const StyledBiddingUserData: StyledComponent<typeof BiddingUserData> = styled(BiddingUserData)`
  & + & {
    margin-top: 8px;
  }
`;
