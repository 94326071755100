import { phoneRegexp } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { EditUserForm } from '@onArte/interfaces';

export const useEditUserDataValidation: () => Yup.SchemaOf<EditUserForm> = (): Yup.SchemaOf<EditUserForm> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('onarte.common.validations.firstName'))
      .max(64, t('onarte.common.validations.firstName'))
      .required(t('onarte.common.requiredField')),
    lastName: Yup.string()
      .min(2, t('onarte.common.validations.lastName'))
      .max(128, t('onarte.common.validations.lastName'))
      .required(t('onarte.common.requiredField')),
    email: Yup.string()
      .email(t('onarte.common.validations.email'))
      .required(t('onarte.common.requiredField')),
    phone: Yup.string()
      .matches(phoneRegexp, t('onarte.common.validations.phone'))
      .required(t('onarte.common.requiredField')),
  });
};
