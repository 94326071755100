import {
  AttachmentInterface,
  CategoryAttributeInterface,
  CategoryWithAttributesInterface,
  ConditionTypeEnum,
  ItemAttributeAllowedValueInterface,
  ItemAttributeConditionInterface,
  UploadAttachmentTypeEnum,
} from '@on-arte/core-types';
import {
  AddPhotoFile,
  BooleanValue,
  DropdownOption,
  FieldCondition,
  FormFieldData,
  FormFieldType,
  FormFieldValue,
  FormHelpers,
  FormSectionData,
  getSingleValueFromFormFieldValue,
  useFormHelpers,
  UseState
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { BaseSchema } from 'yup';

import { uploadAttachment } from '@onArte/api';
import { useAutosuggestActions } from '@onArte/hooks';
import { AutosuggestActions } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormikForm, FormSectionWithStandardSpace } from '@onArte/theme';

import { Container } from './artworkAddForm.styled';
import { ArtworkAddFormProps } from './artworkAddForm.types';

export const ArtworkAddForm: React.FC<ArtworkAddFormProps> = (props: ArtworkAddFormProps): JSX.Element => {
  const {
    initialValues,
    categories,
    onValuesChange,
    isFormSubmitted,
    validationSchemaUpdate,
    formStructure,
    files,
    className
  }: ArtworkAddFormProps = props;
  const { t }: TransProps<never> = useTranslation();
  const [form, setForm]: UseState<FormSectionData[]> = useState<FormSectionData[]>([]);
  const [formValues, setFormValues]: UseState<Record<string, FormFieldValue>> = useState<Record<string, FormFieldValue>>({});
  const [formFiles, setFormFiles]: UseState<Record<string, AddPhotoFile[]>> = useState<Record<string, AddPhotoFile[]>>({});
  const [selectedCategoryId, setSelectedCategoryId]: UseState<number | null> = useState<number | null>(null);
  const [selectedCategoryAttributes, setSelectedCategoryAttributes]: UseState<CategoryAttributeInterface[]>
    = useState<CategoryAttributeInterface[]>([]);
  const [formValidationSchema, setFormValidationSchema]: UseState<BaseSchema | null> = useState<BaseSchema | null>(null);
  const { getSchemaRules }: FormHelpers = useFormHelpers();
  const formRef: React.Ref<FormikProps<Record<string, FormFieldValue>>> = useRef(null);
  const { searchManufacturersAction }: AutosuggestActions = useAutosuggestActions();

  useEffect(
    (): void => {
      if (files) {
        setFormFiles(files);
      }
    },
    [files]
  );

  useEffect(
    (): void => {
      const formClone: FormSectionData[] = [...formStructure];
      formClone.forEach((section: FormSectionData): void => {
        section.label = t(section.label);
        section.children.forEach((field: FormFieldData): void => {
          field.label = t(field?.label ?? '');
          if (field.description?.content) {
            field.description.content = t(field.description.content);
          }
          if (field.additionalFieldProps?.requirements) {
            field.additionalFieldProps.requirements = field.additionalFieldProps.requirements
              .map((requirement: string): string => t(requirement));
          }
          if (field.additionalFieldProps?.options) {
            field.additionalFieldProps.options = field.additionalFieldProps.options
              .map((option: DropdownOption): DropdownOption => ({ ...option, label: t(option.label) }));
          }
          if (field.name === 'category' && field.additionalFieldProps) {
            field.additionalFieldProps.options = categories.map((category: CategoryWithAttributesInterface): DropdownOption => ({
              label: t(`onarte.common.itemsCategories.${category.name}`),
              name: category.id.toString(),
            }));
          }
        });
      });
      if (isFormSubmitted && formRef.current?.validateForm) {
        void formRef.current.validateForm();
      }
      updateFormValidationSchema(formClone);
      setForm(formClone);
    },
    [formStructure]
  );

  useEffect(
    (): void => {
      if (!form.length) {
        return;
      }

      const formClone: FormSectionData[] = [...form];
      formClone.forEach((section: FormSectionData): void => {
        section.children.forEach((field: FormFieldData): void => {
          if (field.name !== 'category') {
            const attribute: CategoryAttributeInterface | undefined = selectedCategoryAttributes
              .find(
                (attributeObject: CategoryAttributeInterface): boolean => attributeObject.name === (field.conditionFromField ?? field.name)
              );
            if (attribute) {
              if (field.type === FormFieldType.FullscreenDropdown && field.additionalFieldProps) {
                field.additionalFieldProps.options = attribute.values.map(
                  (attributeValue: ItemAttributeAllowedValueInterface): DropdownOption => ({
                    name: attributeValue.value,
                    label: t(`onarte.common.itemsAttributes.${field.name}.options.${attributeValue.value}.label`),
                  }),
                );
              }
              field.label = t(field.label || attribute.label);
            }
          }
        });
      });
      
      setForm(formClone);

      if (isFormSubmitted && formRef.current?.validateForm) {
        setTimeout((): void => void formRef?.current?.validateForm(), 0);
      }
    },
    [selectedCategoryId]
  );

  const updateFormValidationSchema: (formObject: FormSectionData[]) => void = (formObject: FormSectionData[]): void => {
    let schema: Record<string, BaseSchema> = {};
    formObject.forEach((section: FormSectionData): void => {
      section.children.forEach((field: FormFieldData): void => {
        const attribute: CategoryAttributeInterface | undefined = selectedCategoryAttributes
          .find(
            (attributeObject: CategoryAttributeInterface): boolean => attributeObject.name === (field.conditionFromField ?? field.name)
          );
        const available: boolean = checkRenderCondition(field, attribute);
        if (available) {
          schema = { ...schema, [field.name]: getSchemaRules(field.type, field.validationRules) };
        }
        if (field.name === 'category') {
          schema = { ...schema, category: getSchemaRules(field.type, field.validationRules) };
        }
      });
    });
    const schemaObject: BaseSchema = Yup.object().shape(schema);
    setFormValidationSchema(schemaObject);
    validationSchemaUpdate(schemaObject);
  };

  const checkRenderCondition: (formField: FormFieldData, attribute?: CategoryAttributeInterface) => boolean = (
    formField: FormFieldData, attribute?: CategoryAttributeInterface
  ): boolean => {
    if (formField?.alwaysAvailable) {
      return true;
    }

    const conditionFromAttribute: ItemAttributeConditionInterface | null = attribute?.condition ?? null;
    const conditionFromForm: FieldCondition | undefined = formField?.fieldRenderCondition;

    if (conditionFromAttribute) {
      const attributeObjectFromCondition: CategoryAttributeInterface | undefined = selectedCategoryAttributes
        .find((attributeObject: CategoryAttributeInterface): boolean => attributeObject.id === conditionFromAttribute.conditionId);

      if (!attributeObjectFromCondition || !formValues[attributeObjectFromCondition.name]) {
        return false;
      }

      const fieldOnWhichConditionDepends: string = getSingleValueFromFormFieldValue<string>(formValues[attributeObjectFromCondition.name]);
      if (conditionFromAttribute.type === ConditionTypeEnum.VisibleIfAnswerHasValue && conditionFromAttribute.conditionValue) {
        return fieldOnWhichConditionDepends === conditionFromAttribute.conditionValue;
      } else if (conditionFromAttribute.type === ConditionTypeEnum.VisibleIfAnswerIsTruly) {
        return fieldOnWhichConditionDepends === BooleanValue.Yes;
      } else if (conditionFromAttribute.type === ConditionTypeEnum.VisibleIfAnswerIsFalsy) {
        return fieldOnWhichConditionDepends === BooleanValue.No;
      }
    } else if (!conditionFromAttribute && attribute) {
      return true;
    } else if (conditionFromForm && formValues[conditionFromForm.relatedFieldName]) {
      const fieldOnWhichConditionDepends: string
        = getSingleValueFromFormFieldValue<string>(formValues[conditionFromForm.relatedFieldName]);

      if (conditionFromForm.type === ConditionTypeEnum.VisibleIfAnswerHasValue && conditionFromForm.relatedFieldValue) {
        return fieldOnWhichConditionDepends === conditionFromForm.relatedFieldValue;
      } else if (conditionFromForm.type === ConditionTypeEnum.VisibleIfAnswerIsTruly) {
        return fieldOnWhichConditionDepends === BooleanValue.Yes;
      } else if (conditionFromForm.type === ConditionTypeEnum.VisibleIfAnswerIsFalsy) {
        return fieldOnWhichConditionDepends === BooleanValue.No;
      }
    }

    return false;
  };

  const checkFieldsConditions: (values: Record<string, FormFieldValue>) => void = (values: Record<string, FormFieldValue>): void => {
    const formClone: FormSectionData[] = [...form];
    let isAnyFieldChanged: boolean = false;
    formClone.forEach((section: FormSectionData): void => {
      section.children.forEach((field: FormFieldData): void => {
        if (field.name !== 'category') {
          const initialAvailability: boolean = !!field.available;
          if (!field.alwaysAvailable) {
            const attribute: CategoryAttributeInterface | undefined = selectedCategoryAttributes
              .find(
                (attributeObject: CategoryAttributeInterface): boolean => attributeObject.name === (field.conditionFromField ?? field.name)
              );
            field.available = checkRenderCondition(field, attribute);
            if (field.available === false) {
              values[field.name] = '';
            }
          } else {
            field.available = true;
          }

          if (initialAvailability !== field.available) {
            isAnyFieldChanged = true;
          }
        }
      });
    });
    setFormValues(values);

    if (isAnyFieldChanged) {
      updateFormValidationSchema(formClone);
      setForm(formClone);
    }
  };

  const handleFormChange: (values: Record<string, FormFieldValue>) => void = (values: Record<string, FormFieldValue>): void => {
    if (values.category) {
      const categoryId: number = parseInt(getSingleValueFromFormFieldValue<string>(values.category), 10);
      if (selectedCategoryId !== categoryId) {
        const categoryAttributes: CategoryAttributeInterface[] = categories
          .find((category: CategoryWithAttributesInterface): boolean => category.id === categoryId)
          ?.attributes ?? [];
        setSelectedCategoryAttributes(categoryAttributes);
        setSelectedCategoryId(categoryId);
      }
    }
    
    checkFieldsConditions({ ...formValues, ...values });
  };

  useEffect(
    (): void => {
      if (initialValues.category) {
        const categoryId: number = parseInt(getSingleValueFromFormFieldValue<string>(initialValues.category), 10);
        if (selectedCategoryId !== categoryId) {
          const categoryAttributes: CategoryAttributeInterface[] = categories
            .find((category: CategoryWithAttributesInterface): boolean => category.id === categoryId)
            ?.attributes ?? [];
          setSelectedCategoryAttributes(categoryAttributes);
          setSelectedCategoryId(categoryId);
        }
      }

      if (Object.keys(formValues).length === 0) {
        handleFormChange(initialValues);
      }
    },
    [initialValues]
  );

  useEffect(
    (): void => {
      if (form.length) {
        checkFieldsConditions(formValues);
      }
    },
    [formValues, form.length]
  );

  useEffect(
    (): void => {
      onValuesChange(formValues);
    },
    [formValues]
  );

  const isSectionAvailable = (section: FormSectionData): boolean => {
    return section.children.some((field: FormFieldData): boolean => !!field?.available || !!field?.alwaysAvailable);
  };

  const addFile = (
    file: File, onProgress: (progressEvent: ProgressEvent<FileReader>) => void, attachmentType: string, fieldName: string
  ): Promise<AddPhotoFile> => {
    return new Promise<AddPhotoFile>((resolve: (createdFile: AddPhotoFile) => void, reject: () => void): void => {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      uploadAttachment(formData, onProgress, attachmentType as UploadAttachmentTypeEnum)
        .then((response: AttachmentInterface): void => {
          resolve({
            ...response,
            objectName: null
          });
        })
        .catch((): void => reject());
    });
  };

  useEffect(
    (): void => {
      if (isFormSubmitted) {
        void formRef?.current?.validateForm();
      }
    },
    [isFormSubmitted]
  );

  return formValidationSchema ? (
    <Container className={className}>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={(): void => undefined}
        validate={handleFormChange}
        validationSchema={formValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        innerRef={formRef}
      >
        {({ handleSubmit, setFieldValue, errors, values }: FormikProps<Record<string, FormFieldValue>>): JSX.Element => (
          <FormikForm onSubmit={handleSubmit}>
            {form.map((section: FormSectionData): JSX.Element => isSectionAvailable(section) ? (
              <FormSectionWithStandardSpace title={section.label} key={section.label}>
                {section.children.map((field: FormFieldData) => (!!field.available || !!field.alwaysAvailable) && (
                  <FormFieldWithStandardSpace
                    key={field.name}
                    fieldType={field.type}
                    fieldName={field.name}
                    setFieldValue={setFieldValue}
                    value={values[field.name]}
                    label={field.label}
                    description={field.description}
                    disabled={field.disabled}
                    additionalFieldProps={{
                      ...field.additionalFieldProps,
                      ...(formFiles?.hasOwnProperty(field.name) ? { files: formFiles[field.name] } : {}),
                    }}
                    validationMessage={isFormSubmitted ? errors[field.name] as string : undefined}
                    actions={{
                      addFile,
                      ...(field.name === 'manufacturer' ? { autosuggestValueInputAsync: searchManufacturersAction } : {})
                    }}
                  />
                ))}
              </FormSectionWithStandardSpace>
            ) : (
              <></>
            ))}
          </FormikForm>
        )}
      </Formik>
    </Container>
  ) : <></>;
};
