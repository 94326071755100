import { UserStatusEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const userStatusConfig: Record<UserStatusEnum, Status> = {
  [UserStatusEnum.Active]: {
    label: 'onarte.common.userStatuses.active',
    background: Color.Success100,
    color: Color.Success500
  },
  [UserStatusEnum.Inactive]: {
    label: 'onarte.common.userStatuses.inactive',
    background: Color.Gray200,
    color: Color.Gray500
  },
};
