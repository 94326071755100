import { ManufacturerCreateModel } from '@on-arte/core-types';
import {
  ApiError,
  FormFieldType,
  FormFieldValue,
  getSingleValueFromFormFieldValue,
  SuggestionItem,
  useFormikForm,
  UseFormikForm,
  useNotifications,
  UseNotifications
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { createManufacturer } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions } from '@onArte/hooks';
import { AutosuggestActions } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormSectionWithStandardSpace } from '@onArte/theme';
import { getRouteDetailsByName } from '@onArte/utils';

import { useAuthorsAddFormValidation } from './authorsAddForm.hooks';
import { FormikForm } from './authorsAddForm.styled';

export const AuthorsAddFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { searchUsersAction }: AutosuggestActions = useAutosuggestActions();
  const AuthorsAddFormValidationSchema: Yup.SchemaOf<ManufacturerCreateModel> = useAuthorsAddFormValidation();
  const formRef: React.MutableRefObject<FormikProps<ManufacturerCreateModel> | null>
    = useRef<FormikProps<ManufacturerCreateModel> | null>(null);

  const addAuthor: (data: ManufacturerCreateModel) => void = (data: ManufacturerCreateModel): void => {
    createManufacturer(data).then((): void => {
      addToast({ content: t('onarte.management.notifications.authorsAddForm.success') });
      navigate(getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/');
    }).catch((error: ApiError): void => {
      addToast({ content: t(error.message) });
    });
  };

  const save: () => void = (): void => {
    if (formRef.current) {
      setFormSubmitted();
      formRef.current.handleSubmit();
    }
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.authorsAddForm.title'),
        buttonLabel: t('onarte.common.cancel'),
        buttonAction: (): void => navigate(getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/'),
        secondButtonLabel: t('onarte.management.authorsAddForm.secondButtonLabel'),
        secondButtonAction: save,
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.authorsList.title'), path: getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/' },
        { label: t('onarte.management.meta.authorsAddForm.title'), path: getRouteDetailsByName(RouteNameEnum.AuthorsAddForm)?.url ?? '/' },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ name: '', userId: undefined }}
        onSubmit={addAuthor}
        validationSchema={AuthorsAddFormValidationSchema}
        validateOnChange={isFormSubmitted}
        validateOnBlur={isFormSubmitted}
      >
        {({ values, setFieldValue, errors }: FormikProps<ManufacturerCreateModel>): JSX.Element => (
          <FormikForm>
            <FormSectionWithStandardSpace title={t('onarte.management.authorsAddForm.authorName')}>
              <FormFieldWithStandardSpace
                label={t('onarte.management.authorsAddForm.name.label')}
                fieldType={FormFieldType.Input}
                fieldName='name'
                setFieldValue={setFieldValue}
                value={values.name}
                validationMessage={errors.name || ''}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.management.authorsAddForm.userId.label')}
                fieldType={FormFieldType.Autosuggest}
                fieldName='userId'
                setFieldValue={(field: string, value: FormFieldValue): void => {
                  setFieldValue(field, getSingleValueFromFormFieldValue<SuggestionItem>(value).id);
                }}
                validationMessage={errors.userId || ''}
                actions={{ autosuggestValueInputAsync: searchUsersAction }}
              />
            </FormSectionWithStandardSpace>
          </FormikForm>
        )}
      </Formik>
    </BaseView>
  );
};
