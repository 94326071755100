import { InternalUserProfileInterface } from '@on-arte/core-types';
import {
  UseState,
  ApiError,
  FormFieldType,
  useFormikForm,
  UseFormikForm,
  useNotifications,
  UseNotifications,
  UseRedirect,
  useRedirect
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { editUser, getUser, changeUserPassword } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { RouteNameEnum, QueryKey } from '@onArte/enums';
import { EditUserForm, EditUserPasswordForm } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormSectionWithStandardSpace } from '@onArte/theme';
import { getRouteDetailsByName } from '@onArte/utils';

import { useEditUserDataValidation, useEditUserPasswordValidation } from './hooks';
import { Container, FormContainer, FormikForm, StyledButton } from './userEditForm.styled';

export const UserEditFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id: userId }: Record<string, string | undefined> = useParams();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const editUserDataValidationSchema: Yup.SchemaOf<EditUserForm> = useEditUserDataValidation();
  const editUserPasswordValidationSchema: Yup.SchemaOf<EditUserPasswordForm> = useEditUserPasswordValidation();
  const [userData, setUserData]: UseState<InternalUserProfileInterface | null> = useState<InternalUserProfileInterface | null>(null);

  useQuery(
    [QueryKey.UserData],
    (): Promise<InternalUserProfileInterface> => getUser(userId ?? ''),
    {
      onSuccess: (data: InternalUserProfileInterface): void => setUserData(data),
      onError: (error: ApiError): void => addToast({ content: t(error.message) }),
    }
  );

  const editUserData: (data: EditUserForm) => void = (data: EditUserForm): void => {
    if (!userData) {
      return;
    }

    editUser(userData.id, { ...data, hash: userData.hash })
      .then((response: InternalUserProfileInterface): void => {
        setUserData(response);
        addToast({ content: t('onarte.management.userEditForm.success') });
      })
      .catch((error: ApiError): void => addToast({ content: t(error.message) }));
  };

  const editUserPassword: ({ newPassword }: EditUserPasswordForm) => void = ({ newPassword }: EditUserPasswordForm): void => {
    if (!userData) {
      return;
    }

    showSmallDialog({
      header: t('onarte.management.userEditForm.passwordChangeApprovement.title'),
      content: t('onarte.management.userEditForm.passwordChangeApprovement.content'),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          changeUserPassword(userData.id, { hash: userData.hash, newPassword })
            .then((): void => {
              hideSmallDialog();
              addToast({ content: t('onarte.management.userEditForm.success') });
            })
            .catch((error: ApiError): void => addToast({ content: t(error.message) }));
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel'),
      },
    });
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: userData ? `${userData.firstName} ${userData.lastName}` : '',
        secondButtonLabel: t('onarte.management.userEditForm.buttonLabel'),
        secondButtonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.UsersList)?.url ?? '/')
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.menu.usersList'), path: getRouteDetailsByName(RouteNameEnum.UsersList)?.url ?? '/' },
        { label: t('onarte.management.userEditForm.edit'), path: getRouteDetailsByName(RouteNameEnum.UserEditForm)?.url ?? '/' },
      ]}
    >
      {userData && (
        <Container>
          <FormContainer>
            <Formik
              initialValues={{
                firstName: userData.firstName ?? '',
                lastName: userData.lastName ?? '',
                phone: userData.phone ?? '',
                email: userData.email ?? ''
              }}
              onSubmit={editUserData}
              validationSchema={editUserDataValidationSchema}
              validateOnChange={isFormSubmitted}
              validateOnBlur={isFormSubmitted}
            >
              {({ handleSubmit, values, setFieldValue, errors }: FormikProps<EditUserForm>): JSX.Element => (
                <FormikForm onSubmit={handleSubmit}>
                  <FormSectionWithStandardSpace title={t('onarte.management.userEditForm.infoSection.label')}>
                    <FormFieldWithStandardSpace
                      label={t('onarte.common.firstName')}
                      fieldType={FormFieldType.Input}
                      fieldName='firstName'
                      setFieldValue={setFieldValue}
                      value={values['firstName']}
                      validationMessage={errors.firstName || ''}
                    />
                    <FormFieldWithStandardSpace
                      label={t('onarte.common.lastName')}
                      fieldType={FormFieldType.Input}
                      fieldName='lastName'
                      setFieldValue={setFieldValue}
                      value={values['lastName']}
                      validationMessage={errors.lastName || ''}
                    />
                    <FormFieldWithStandardSpace
                      label={t('onarte.common.phone')}
                      fieldType={FormFieldType.Input}
                      fieldName='phone'
                      setFieldValue={setFieldValue}
                      value={values['phone']}
                      validationMessage={errors.phone || ''}
                      additionalFieldProps={{
                        withOnlyNumbersAllowed: true
                      }}
                    />
                    <FormFieldWithStandardSpace
                      label={t('onarte.common.email')}
                      fieldType={FormFieldType.Input}
                      fieldName='email'
                      setFieldValue={setFieldValue}
                      value={values['email']}
                      validationMessage={errors.email || ''}
                    />
                    <StyledButton
                      label={t('onarte.common.saveChanges')}
                      type='submit'
                      onClick={setFormSubmitted}
                    />
                  </FormSectionWithStandardSpace>
                </FormikForm>
              )}
            </Formik>
          </FormContainer>
          <FormContainer>
            <Formik
              initialValues={{ newPassword: '' }}
              onSubmit={editUserPassword}
              validationSchema={editUserPasswordValidationSchema}
              validateOnChange={isFormSubmitted}
              validateOnBlur={isFormSubmitted}
            >
              {({ handleSubmit, values, setFieldValue, errors }: FormikProps<EditUserPasswordForm>): JSX.Element => (
                <FormikForm onSubmit={handleSubmit}>
                  <FormSectionWithStandardSpace title={t('onarte.management.userEditForm.passwordSection.label')}>
                    <FormFieldWithStandardSpace
                      label={t('onarte.common.password')}
                      fieldType={FormFieldType.Input}
                      fieldName='newPassword'
                      setFieldValue={setFieldValue}
                      value={values['newPassword']}
                      validationMessage={errors.newPassword || ''}
                    />
                    <StyledButton
                      label={t('onarte.common.saveChanges')}
                      type='submit'
                      onClick={setFormSubmitted}
                    />
                  </FormSectionWithStandardSpace>
                </FormikForm>
              )}
            </Formik>
          </FormContainer>
        </Container>
      )}
    </BaseView>
  );
};
