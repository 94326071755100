import { ListPaginationInterface, PostDTO } from '@on-arte/core-types';
import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { ListParams } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const getBlogPosts: (
  params: Partial<ListParams & { titleSearch?: string }>
) => Promise<ListPaginationInterface<PostDTO>> = (
  params: Partial<ListParams & { titleSearch?: string }>
): Promise<ListPaginationInterface<PostDTO>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<PostDTO>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/blog/posts?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<PostDTO>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
