import { CollectionListElementInterface, CollectionStatusEnum, ListPaginationInterface } from '@on-arte/core-types';
import { 
  ApiError,
  DirectionEnum, 
  ElementsTable, 
  ElementsTableRow, 
  IconName, 
  PaginationDetails, 
  SortIndexElement, 
  UseLogger, 
  UseNotifications, 
  UseRedirect, 
  UseState, 
  getPathWithParams, 
  useLogger, 
  useNotifications, 
  usePagination, 
  useRedirect 
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';

import { deleteCollection, getCollections, sortCollections, updateVisibility } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { collectionStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

export const CollectionsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);
  const [collections, setCollections]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const [sortIndexArray, setSortIndexArray]: UseState<SortIndexElement[]> = useState<SortIndexElement[]>([]);

  const { refetch }: QueryObserverResult = useQuery(
    [QueryKey.CollectionsList, offset],
    (): Promise<ListPaginationInterface<CollectionListElementInterface>> => getCollections({ limit: itemsPerPage, offset }),
    {
      onSuccess: (data: ListPaginationInterface<CollectionListElementInterface>): void => {
        setMaxItems(data.amount);
        setSortIndexArray(data.list);
        setCollections(data.list.map((collection: CollectionListElementInterface): ElementsTableRow => {
          return {
            cells: [
              { name: 'name', content: collection.name },
              { name: 'position', content: `${collection.sortIndex}`, withArrows: true },
              {
                name: 'status',
                content: {
                  label: t(collectionStatusConfig[collection.status].label),
                  background: collectionStatusConfig[collection.status].background,
                  color: collectionStatusConfig[collection.status].color,
                },
              },
            ],
            contextMenuPositions: [
              {
                label: t('onarte.management.collectionsList.actions.changeItems'),
                internalPath: getPathWithParams(
                  getRouteDetailsByName(RouteNameEnum.ContentCollectionItemsForm)?.url ?? '/', { id: collection.id }
                ),
                icon: IconName.Gavel
              },
              {
                label: t('onarte.management.collectionsList.actions.edit'),
                internalPath: getPathWithParams(
                  getRouteDetailsByName(RouteNameEnum.ContentCollectionEditForm)?.url ?? '/', { id: collection.id }
                ),
                icon: IconName.Edit
              },
              {
                label: t('onarte.management.collectionsList.actions.delete'),
                action: (): void => deleteCollectionAction(collection),
                icon: IconName.Delete
              },
              {
                label: t(`onarte.management.collectionsList.actions.visibility.${collection.status}`),
                action: (): void => updateVisibilityAction(collection),
                icon: IconName.Visibility
              },
            ],
          };
        }));
      },
      onError: (error: ApiError): void => logger(QueryKey.CollectionsList, error, 'error')
    }
  );

  const updateVisibilityAction: (collection: CollectionListElementInterface) => void = (
    collection: CollectionListElementInterface
  ): void => {
    showSmallDialog({
      header: t('onarte.management.collectionsList.smallDialog.header.visibility'),
      content: t('onarte.management.collectionsList.smallDialog.content.visibility', { value: collection.name }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          updateVisibility(collection.id, collection.status === CollectionStatusEnum.Active ? false : true)
            .then((): void => {
              hideSmallDialog();
              void refetch();
              addToast({ content: t('onarte.management.updateVisibility.success') });
            })
            .catch((error: ApiError): void => addToast({ content: t(error.message) }));
        },
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  const deleteCollectionAction: (
    collection: CollectionListElementInterface
  ) => void = (collection: CollectionListElementInterface): void => {
    showSmallDialog({
      header: t('onarte.management.collectionsList.smallDialog.header.delete'),
      content: t('onarte.management.collectionsList.smallDialog.content.delete', { value: collection.name }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          deleteCollection(collection.id)
            .then((): void => {
              hideSmallDialog();
              void refetch();
              addToast({ content: t('onarte.management.deleteCollection.success') });
            })
            .catch((error: ApiError): void => addToast({ content: t(error.message) }));
        },
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  const sortCollectionsAction: (index: number, direction: DirectionEnum) => Promise<SortIndexElement[]> = (
    index: number, direction: DirectionEnum
  ): Promise<SortIndexElement[]> => {
    return new Promise((resolve: (collections: SortIndexElement[]) => void, reject: () => void): void => {
      sortCollections(String(sortIndexArray[index].id), direction)
        .then((): void => {
          void refetch()
            .then((): void => {
              resolve(sortIndexArray);
              addToast({ content: t('onarte.management.sortCollections.success') });
            });
        })
        .catch((error: ApiError): void => {
          reject();
          addToast({ content: t(error.message) });
        });
    });
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.contentCollectionsList.title'),
        withUnderline: false,
        buttonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ContentCollectionAddForm)?.url ?? '/'),
        buttonLabel: t('onarte.management.collectionsList.actions.add')
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentCollectionsList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/' 
        },
      ]}
    >
      <ElementsTable
        columns={[
          { name: 'name', label: t('onarte.management.collectionsList.tableColumns.name') },
          { name: 'position', label: t('onarte.management.collectionsList.tableColumns.position') },
          { name: 'status', label: t('onarte.management.collectionsList.tableColumns.status') },
        ]}
        rows={collections}
        maxItemsCount={maxItems}
        onPageChange={setPage}
        onPositionChangeAsync={sortCollectionsAction}
        isFirst={offset === 0}
        isLast={(offset / itemsPerPage) === Math.floor(maxItems / itemsPerPage)}
        initialPageCount={page}
      />
    </BaseView>
  );
};
