import { PublicProfileTypeEnum, UploadAttachmentTypeEnum } from '@on-arte/core-types';
import { AddPhotoTheme, FormFieldType, FormSectionData } from '@on-arte/ui';

export const createPublicProfileForm: FormSectionData[] = [
  {
    label: 'onarte.common.publicProfilesForm.detailsSection.label',
    children: [
      {
        type: FormFieldType.AddPhoto,
        name: 'avatar',
        description: {
          position: 'bottom',
          content: 'onarte.common.publicProfilesForm.avatar.description',
        },
        additionalFieldProps: {
          addPhotoTheme: AddPhotoTheme.SmallWithAvatar,
          attachmentType: UploadAttachmentTypeEnum.PublicProfileAvatar,
          acceptedFilesTypes: 'image/png, image/jpeg',
          maxFileSizeInBytes: 2 * 1024 * 1024,
          maxNumberOfFiles: 1,
        },
      },
      {
        type: FormFieldType.Dropdown,
        name: 'type',
        label: 'onarte.common.publicProfilesForm.type.label',
        additionalFieldProps: {
          options: [
            { name: PublicProfileTypeEnum.Collector, label: 'onarte.common.collector' },
            { name: PublicProfileTypeEnum.Manufacturer, label: 'onarte.common.manufacturer' },
          ],
        },
      },
      {
        type: FormFieldType.Autosuggest,
        label: 'onarte.common.publicProfilesForm.relationId.label',
        name: 'relationId',
        alwaysAvailable: true,
        additionalFieldProps: {
          withAcceptNotSuggestedValue: false,
        },
      },
      {
        type: FormFieldType.Textarea,
        name: 'description',
        label: 'onarte.common.publicProfilesForm.description.label',
        additionalFieldProps: {
          maxCharsCount: 350
        }
      },
      {
        type: FormFieldType.Textarea,
        name: 'bio',
        label: 'onarte.common.publicProfilesForm.bio.label',
        additionalFieldProps: {
          maxCharsCount: 2048
        }
      },
    ]
  },
  {
    label: 'onarte.common.publicProfilesForm.coverSection.label',
    children: [
      {
        type: FormFieldType.AddPhoto,
        name: 'cover',
        label: 'onarte.common.publicProfilesForm.cover.label',
        description: {
          position: 'top',
          content: 'onarte.common.publicProfilesForm.cover.description',
        },
        additionalFieldProps: {
          addPhotoTheme: AddPhotoTheme.WithBoxLoaderOnInit,
          attachmentType: UploadAttachmentTypeEnum.PublicProfileCoverPhoto,
          acceptedFilesTypes: 'image/png, image/jpeg',
          maxFileSizeInBytes: 1 * 1024 * 1024,
          maxNumberOfFiles: 1,
        },
      },
    ]
  },
  {
    label: 'onarte.common.publicProfilesForm.galleryPhotoSection.label',
    children: [
      {
        type: FormFieldType.AddPhoto,
        name: 'galleryPhoto',
        description: {
          position: 'top',
          content: 'onarte.common.publicProfilesForm.galleryPhoto.description',
        },
        additionalFieldProps: {
          addPhotoTheme: AddPhotoTheme.WithBoxLoaderOnInit,
          attachmentType: UploadAttachmentTypeEnum.PublicProfileGalleryPhoto,
          acceptedFilesTypes: 'image/png, image/jpeg',
          maxFileSizeInBytes: 1 * 1024 * 1024,
          maxNumberOfFiles: 5,
        },
      },
    ]
  }
];
