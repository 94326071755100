import { passwordRegexp } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { EditUserPasswordForm } from '@onArte/interfaces';

export const useEditUserPasswordValidation: () => Yup.SchemaOf<EditUserPasswordForm> = (): Yup.SchemaOf<EditUserPasswordForm> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    newPassword: Yup.string()
      .matches(passwordRegexp, t('onarte.common.validations.passwordDescription'))
      .required(t('onarte.common.requiredField'))
  });
};
