import {
  AuctionDetailsInterface,
  EventCreateModel,
  EventDetailsInterface,
  EventStatusEnum,
  UploadAttachmentTypeEnum
} from '@on-arte/core-types';
import {
  AddPhotoFile,
  AddPhotoTheme,
  ApiError,
  BooleanValue,
  FormFieldType,
  FormFieldValue,
  getSingleValueFromFormFieldValue,
  useFormikForm,
  UseFormikForm,
  UseLogger,
  useLogger,
  useNotifications,
  UseNotifications,
  UseState
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { createEvent, getEventDetails, updateEventDetails } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useFiles, useObjectsTransformations } from '@onArte/hooks';
import { UseFiles, UseObjectsTranformations } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormSectionWithStandardSpace } from '@onArte/theme';
import { getRouteDetailsByName } from '@onArte/utils';

import { useEventAddFormValidation } from './eventAddForm.hooks';
import { FormikForm } from './eventAddForm.styled';

export const EventAddFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();
  const [eventDetails, setEventDetails]: UseState<EventDetailsInterface<AuctionDetailsInterface> | null>
    = useState<EventDetailsInterface<AuctionDetailsInterface> | null>(null);
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const EventAddFormValidationSchema: Yup.SchemaOf<Partial<EventCreateModel>> = useEventAddFormValidation();
  const formRef: React.MutableRefObject<FormikProps<EventCreateModel> | null>
    = useRef<FormikProps<EventCreateModel> | null>(null);
  const { logger }: UseLogger = useLogger();
  const { transformAttachmentInterfaceToAddPhotoFile }: UseObjectsTranformations = useObjectsTransformations();
  const { addFile }: UseFiles = useFiles();

  useQuery(
    [QueryKey.EventDetails],
    (): Promise<EventDetailsInterface<AuctionDetailsInterface>> => getEventDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: EventDetailsInterface<AuctionDetailsInterface>): void => setEventDetails(data),
      onError: (error: ApiError): void => {
        logger(QueryKey.EventDetails, error, 'error');
        addToast({ content: t(error.message) });
        navigate(getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/');
      },
    }
  );

  const onSubmit: (data: EventCreateModel) => void = (data: EventCreateModel): void => {
    if (id) {
      updateEventDetails(id, data).then((): void => {
        addToast({ content: t('onarte.management.notifications.eventEditForm.success') });
        navigate(getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/');
      }).catch((error: ApiError): void => {
        addToast({ content: t(error.message) });
      });
    } else {
      createEvent(data).then((): void => {
        addToast({ content: t('onarte.management.notifications.eventAddForm.success') });
        navigate(getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/');
      }).catch((error: ApiError): void => {
        addToast({ content: t(error.message) });
      });
    }
  };

  const save: () => void = (): void => {
    if (formRef.current) {
      setFormSubmitted();
      formRef.current.handleSubmit();
    }
  };

  const eventDatesEditDisabled: boolean = useMemo(
    (): boolean => {
      if (!eventDetails?.status) {
        return false;
      }

      return [EventStatusEnum.Finished, EventStatusEnum.InProgress].includes(eventDetails.status);
    },
    [eventDetails]
  );

  const coverPhotoFiles: AddPhotoFile[] = useMemo(
    (): AddPhotoFile[] => eventDetails?.coverPhoto
      ? [eventDetails.coverPhoto].map(transformAttachmentInterfaceToAddPhotoFile)
      : [],
    [eventDetails?.coverPhoto]
  );

  return (
    <BaseView
      pageTitleSettings={{
        title: t(`onarte.management.meta.${id ? 'eventEditForm' : 'eventAddForm'}.title`),
        buttonLabel: t('onarte.management.authorsAddForm.secondButtonLabel'),
        buttonAction: save,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => navigate(getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.eventsList.title'), path: getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/' },
        {
          label: t(`onarte.management.meta.${id ? 'eventEditForm' : 'eventAddForm'}.title`),
          path: getRouteDetailsByName(RouteNameEnum.EventAddForm)?.url ?? '/'
        },
      ]}
    >
      {(!id || eventDetails) && (
        <Formik
          innerRef={formRef}
          initialValues={{
            name: eventDetails?.name ?? '',
            published: eventDetails?.published ?? false,
            startTimestamp: eventDetails?.startTimestamp ?? undefined,
            endTimestamp: eventDetails?.endTimestamp ?? undefined,
            description: eventDetails?.description ?? undefined,
            offerCondition: eventDetails?.offerCondition ?? undefined,
            coverPhotoId: eventDetails?.coverPhoto?.id,
          }}
          onSubmit={onSubmit}
          validationSchema={EventAddFormValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ values, setFieldValue, errors }: FormikProps<EventCreateModel>) => (
            <FormikForm>
              <FormSectionWithStandardSpace title={t('onarte.management.eventAddForm.informations')}>
                <FormFieldWithStandardSpace
                  label={t('onarte.management.eventAddForm.name.label')}
                  fieldType={FormFieldType.Input}
                  fieldName='name'
                  setFieldValue={setFieldValue}
                  value={values.name}
                  validationMessage={errors.name || ''}
                />
                <FormFieldWithStandardSpace
                  label={t('onarte.management.eventAddForm.published.label')}
                  fieldType={FormFieldType.Dropdown}
                  fieldName='published'
                  setFieldValue={(field: string, value: FormFieldValue): void => setFieldValue(field, value === BooleanValue.Yes)}
                  value={values.published ? BooleanValue.Yes : BooleanValue.No}
                  disabled={eventDetails?.status === EventStatusEnum.InProgress}
                  additionalFieldProps={{
                    options: [
                      { name: BooleanValue.Yes, label: t('onarte.common.yes') },
                      { name: BooleanValue.No, label: t('onarte.common.no') }
                    ],
                  }}
                  validationMessage={errors.published || ''}
                />
                <FormFieldWithStandardSpace
                  label={t('onarte.management.eventAddForm.startTimestamp.label')}
                  fieldType={FormFieldType.Input}
                  fieldName='startTimestamp'
                  setFieldValue={setFieldValue}
                  value={(values.startTimestamp ?? '').toString()}
                  validationMessage={errors.startTimestamp || ''}
                  disabled={eventDatesEditDisabled}
                  additionalFieldProps={{ 
                    type: 'date',
                  }}
                />
                <FormFieldWithStandardSpace
                  label={t('onarte.management.eventAddForm.endTimestamp.label')}
                  fieldType={FormFieldType.Input}
                  fieldName='endTimestamp'
                  setFieldValue={setFieldValue}
                  value={(values.endTimestamp ?? '').toString()}
                  validationMessage={errors.endTimestamp || ''}
                  disabled={eventDatesEditDisabled}
                  additionalFieldProps={{ type: 'date' }}
                />
                <FormFieldWithStandardSpace
                  label={t('onarte.management.eventAddForm.description.label')}
                  fieldType={FormFieldType.Textarea}
                  fieldName='description'
                  setFieldValue={setFieldValue}
                  value={values.description ?? ''}
                  validationMessage={errors.description || ''}
                  additionalFieldProps={{ maxCharsCount: 500 }}
                />
                <FormFieldWithStandardSpace
                  label={t('onarte.management.eventAddForm.offerCondition.label')}
                  fieldType={FormFieldType.Textarea}
                  fieldName='offerCondition'
                  setFieldValue={setFieldValue}
                  value={values.offerCondition ?? ''}
                  validationMessage={errors.name || ''}
                  additionalFieldProps={{ maxCharsCount: 500 }}
                />
              </FormSectionWithStandardSpace>
              <FormSectionWithStandardSpace title={t('onarte.management.eventAddForm.cover')}>
                <FormFieldWithStandardSpace
                  fieldType={FormFieldType.AddPhoto}
                  value={values.coverPhotoId ?? ''}
                  setFieldValue={(field: string, value: FormFieldValue): void => {
                    setFieldValue(field, getSingleValueFromFormFieldValue<AddPhotoFile>(value).id);
                  }}
                  fieldName='coverPhotoId'
                  validationMessage={errors.coverPhotoId || ''}
                  additionalFieldProps={{
                    addPhotoTheme: AddPhotoTheme.WithBoxLoaderOnInit,
                    requirements: [
                      t('onarte.management.eventAddForm.coverPhoto.requirements.1'),
                      t('onarte.management.eventAddForm.coverPhoto.requirements.2'),
                    ],
                    attachmentType: UploadAttachmentTypeEnum.EventCoverPhoto,
                    acceptedFilesTypes: 'image/png, image/jpeg',
                    maxFileSizeInBytes: 5 * 1024 * 1024,
                    maxNumberOfFiles: 1,
                    files: coverPhotoFiles
                  }}
                  actions={{ addFile }}
                  description={{
                    position: 'top',
                    content: t('onarte.management.eventAddForm.coverPhoto.description')
                  }}
                />
              </FormSectionWithStandardSpace>
            </FormikForm>
          )}
        </Formik>
      )}
    </BaseView>
  );
};
