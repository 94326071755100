import { CollectionCreateModel } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useCollectionFormValidation: () => Yup.SchemaOf<CollectionCreateModel> = (): Yup.SchemaOf<CollectionCreateModel> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    name: Yup.string()
      .required(t('onarte.common.requiredField'))
      .min(2)
      .max(255),
    title: Yup.string()
      .required(t('onarte.common.requiredField'))
      .min(2)
      .max(255),
    coverId: Yup.string()
      .required(t('onarte.common.requiredField'))
      .min(32)
      .max(36),
    description: Yup.string()
      .notRequired()
  });
};
