import { CollectionCreateModel, CollectionDTO } from '@on-arte/core-types';
import {
  ApiError,
  getPathWithParams,
  useFormikForm,
  UseFormikForm,
  useLogger,
  UseLogger,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Params, useParams } from 'react-router-dom';

import { editCollection, getCollectionDetails } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useObjectsTransformations } from '@onArte/hooks';
import { UseObjectsTranformations } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

import { CollectionForm } from '../components';

export const CollectionEditFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();
  const { id }: Readonly<Params<string>> = useParams();
  const { transformAttachmentInterfaceToAddPhotoFile }: UseObjectsTranformations = useObjectsTransformations();
  const [collectionData, setCollectionData]: UseState<CollectionDTO | null> = useState<CollectionDTO | null>(null);
  const formRef: React.Ref<FormikProps<CollectionCreateModel>> = useRef(null);

  useQuery(
    [QueryKey.CollectionDetails],
    (): Promise<CollectionDTO> => getCollectionDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: CollectionDTO): void => setCollectionData(data),
      onError: (error: ApiError): void => logger(QueryKey.CollectionDetails, error, 'error')
    }
  );

  const save: () => Promise<void> = async (): Promise<void> => {
    if (!id || !formRef.current) {
      return;
    }

    const formObject: FormikProps<CollectionCreateModel> = formRef.current;
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (isValid) {
      formObject.handleSubmit();
      setFormSubmitted();
      const { name, title, description, coverId }: CollectionCreateModel = formObject.values;

      editCollection({ name, title, description, coverId }, id)
        .then((): void => {
          addToast({ content: t('onarte.management.collectionEditForm.editCollection') });
          redirect(getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/');
        })
        .catch((error: ApiError): void => logger('editCollection', error, 'error'));
    }
  };
  
  return (
    <BaseView
      pageTitleSettings={{
        title: collectionData?.name ?? '',
        buttonLabel: t('onarte.management.contentCollectionEditForm.buttonLabel'),
        buttonAction: save,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentCollectionsList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/' 
        },
        { 
          label: t('onarte.management.meta.contentCollectionEditForm.title'), 
          path: getPathWithParams(getRouteDetailsByName(RouteNameEnum.ContentCollectionEditForm)?.url ?? '/', { id: id ?? '/' })
        },
      ]}
    >
      {!!collectionData && (
        <CollectionForm
          initialValues={{
            name: collectionData.name,
            title: collectionData.title,
            description: collectionData.description ?? '',
            coverId: collectionData.cover.id,
          }}
          ref={formRef}
          isFormSubmitted={isFormSubmitted}
          files={[transformAttachmentInterfaceToAddPhotoFile(collectionData.cover)]}
        />
      )}
    </BaseView>
  );
};
