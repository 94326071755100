import { CategoryWithAttributesInterface, ItemWithChangesInterface } from '@on-arte/core-types';
import { ApiError, DetailsTable, getPathWithParams, TableDetailsRowData, UseNotifications, useNotifications, UseState } from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { getItemCategories, getItemDetails, verifyItemEditionVersion } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useArtworkTableDetails } from '@onArte/hooks';
import { UseArtworkTableDetails } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

export const ArtworkEditChangesView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const [itemData, setItemData]: UseState<ItemWithChangesInterface | null> = useState<ItemWithChangesInterface | null>(null);
  const [itemCategories, setItemCategories]: UseState<CategoryWithAttributesInterface[]> = useState<CategoryWithAttributesInterface[]>([]);
  const { getTableRows }: UseArtworkTableDetails = useArtworkTableDetails();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();

  useQuery(
    [QueryKey.ItemCategories],
    (): Promise<CategoryWithAttributesInterface[]> => getItemCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => setItemCategories(data),
      // TODO: add logger
      onError: () => undefined
    }
  );

  useQuery(
    [QueryKey.ArtworkDetails],
    (): Promise<ItemWithChangesInterface> => getItemDetails(id ?? ''),
    {
      onSuccess: (data: ItemWithChangesInterface): void => {
        if (!data.changes.length) {
          addToast({
            content: t('onarte.common.noVersionForApproval', { name: data.name })
          });
          navigate(getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/');
        }
        setItemData(data);
      },
      // TODO: add logger
      onError: () => undefined
    }
  );

  const tableData: TableDetailsRowData[] = useMemo(
    (): TableDetailsRowData[] => {
      if (!itemData || !itemCategories.length) {
        return [];
      }

      return getTableRows(itemData, itemCategories, true);
    },
    [itemData, itemCategories]
  );

  const rejectVersion: () => void = (): void => {
    if (!id || !itemData?.changes?.length) {
      return;
    }

    showSmallDialog({
      header: t('onarte.management.artworkEditChangesView.artworkVersionRejection.header'),
      content: t('onarte.management.artworkEditChangesView.artworkVersionRejection.content', { name: itemData.name }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          verifyItemEditionVersion(id, itemData.changes[0].id, { accepted: false })
            .then((): void => {
              hideSmallDialog();
              addToast({
                content: t('onarte.management.artworkEditChangesView.artworkVersionRejection.success', { name: itemData.name })
              });
              navigate(getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/');
            })
            .catch((error: ApiError) => addToast({ content: t(error.message) }));
        }
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  const approveVersion: () => void = (): void => {
    if (!id || !itemData?.changes?.length) {
      return;
    }

    showSmallDialog({
      header: t('onarte.management.artworkEditChangesView.artworkVersionApprovement.header'),
      content: t('onarte.management.artworkEditChangesView.artworkVersionApprovement.content', { name: itemData.name }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          verifyItemEditionVersion(id, itemData.changes[0].id, { accepted: true })
            .then((): void => {
              hideSmallDialog();
              addToast({
                content: t('onarte.management.artworkEditChangesView.artworkVersionApprovement.success', { name: itemData.name })
              });
              navigate(getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/');
            })
            .catch((error: ApiError) => addToast({ content: t(error.message) }));
        }
      },
      cancelButton: { label: t('onarte.common.cancel') },
    });
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: `${itemData?.manufacturer.name ?? ''}, ${itemData?.name ?? ''}`,
        secondButtonAction: rejectVersion,
        secondButtonLabel: t('onarte.management.artworkEditChangesView.secondButtonLabel'),
        buttonAction: approveVersion,
        buttonLabel: t('onarte.management.artworkEditChangesView.buttonLabel')
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.artworksList.title'), path: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/' },
        {
          label: `${itemData?.manufacturer.name ?? ''}, ${itemData?.name ?? ''}`,
          path: getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: itemData?.id ?? '' })
        },
        { label: t('onarte.management.meta.artworkEditChanges.title'), path: '/' },
      ]}
    >
      <DetailsTable rows={tableData} />
    </BaseView>
  );
};
