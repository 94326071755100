import { ListPaginationInterface, OrderListElementDTO } from '@on-arte/core-types';
import { 
  ElementsTable, 
  ElementsTableRow, 
  FilterDetails, 
  FilterOption, 
  Filters, 
  getPathWithParams, 
  IconName, 
  Input, 
  InputTheme, 
  Language, 
  PaginationDetails, 
  Status, 
  useFormatDate, 
  UseFormatDate, 
  usePagination, 
  UseState 
} from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';

import { getOrders } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { auctionTypeConfig, orderStatusConfig } from '@onArte/constants';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { getRouteDetailsByName } from '@onArte/utils';

import { Container } from './ordersList.styled';

export const OrdersListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset, page }: PaginationDetails = usePagination(10, true);
  const { formatDate }: UseFormatDate = useFormatDate(Language.Pl);
  const [orders, setOrders]: UseState<ElementsTableRow[]> = useState<ElementsTableRow[]>([]);
  const [searchInput, setSearchInput]: UseState<string> = useState<string>('');
  const [filters, setFilters]: UseState<FilterDetails[]> = useState<FilterDetails[]>([]);
  const [filtersOpen, setFiltersOpen]: UseState<boolean> = useState<boolean>(false);
  const [submittedFilters, setSubmittedFilters]: UseState<Record<string, string[]>> = useState<Record<string, string[]>>({});
  const currency: string = 'zł';

  const { refetch }: QueryObserverResult = useQuery(
    [QueryKey.OrdersList, offset, searchInput],
    (): Promise<ListPaginationInterface<OrderListElementDTO>> => getOrders(
      { limit: itemsPerPage, offset, search: searchInput, ...submittedFilters }
    ),
    {
      onSuccess: (data: ListPaginationInterface<OrderListElementDTO>): void => {
        setMaxItems(data.amount);
        setOrders(data.list.map((order: OrderListElementDTO): ElementsTableRow => {
          return {
            cells: [
              { name: 'no', content: order.no },
              { name: 'date', content: formatDate(order.orderedAt, 'DD MMM YYYY, HH:mm:ss') },
              { name: 'buyer', content: `${order.buyer.firstName} ${order.buyer.lastName}` },
              { name: 'price', content: `${order.finalPrice} ${currency}` },
              { name: 'auctionType', content: t(`onarte.common.${order.auctionType}`) },
              { name: 'auctionCount', content: `${order.auctionCount}` },
              {
                name: 'status',
                content: {
                  label: t(orderStatusConfig[order.status].label),
                  background: orderStatusConfig[order.status].background,
                  color: orderStatusConfig[order.status].color,
                },
              },
            ],
            contextMenuPositions: [
              {
                label: t('onarte.management.ordersList.actions.details'),
                internalPath: getPathWithParams(getRouteDetailsByName(RouteNameEnum.OrderDetails)?.url ?? '/', { id: order.id }),
                icon: IconName.Description
              },
            ]
          };
        }));
        setFilters([
          {
            name: 'orderedAt',
            label: t('onarte.management.ordersList.filters.orderedAt'),
            inputsType: 'date'
          },
          {
            name: 'finalPrice',
            label: t('onarte.management.ordersList.filters.finalPrice'),
            inputsType: 'text',
            inputsIcon: IconName.Zl
          },
          {
            name: 'auctionCount',
            label: t('onarte.management.ordersList.filters.auctionCount'),
            inputsType: 'text'
          },
          {
            name: 'auctionType',
            label: t('onarte.management.ordersList.filters.auctionType'),
            options: Object.values(auctionTypeConfig).map((auction: FilterOption): FilterOption => ({
              name: t(auction.name),
              label: t(auction.label)
            }))
          },
          {
            name: 'status',
            label: t('onarte.management.ordersList.filters.status'),
            options: Object.entries(orderStatusConfig).map(([key, status]: [string, Status]): FilterOption => ({
              name: key,
              label: t(status.label)
            }))
          }
        ]);
      }
    }
  );

  useEffect((): void => void refetch(), [submittedFilters]);

  return (
    <Container>
      <BaseView
        pageTitleSettings={{ 
          title: t('onarte.management.meta.ordersList.title'),
          buttonLabel: `${t('onarte.management.ordersList.filters.filterButton.label')} ${
            Object.keys(submittedFilters).length 
              ? `(${Object.keys(submittedFilters).length})` 
              : ''
          }`,
          buttonAction: (): void => setFiltersOpen(true)
        }}
        pageTitleChildren={
          <Input
            label={t('onarte.common.searchInput.label')}
            icon={IconName.Loupe}
            inputTheme={InputTheme.Small}
            value={searchInput}
            onChange={(value: string): void => {
              setSearchInput(value);
              setPage(1);
            }}
          />
        }
        breadcrumbs={[
          { label: t('onarte.common.management'), path: '/' },
          { label: t('onarte.management.meta.ordersList.title'), path: getRouteDetailsByName(RouteNameEnum.OrdersList)?.url ?? '/' },
        ]}
      >
        <ElementsTable
          columns={[
            { name: 'no', label: t('onarte.management.ordersList.tableColumns.no') },
            { name: 'date', label: t('onarte.management.ordersList.tableColumns.date') },
            { name: 'buyer', label: t('onarte.management.ordersList.tableColumns.buyer') },
            { name: 'price', label: t('onarte.management.ordersList.tableColumns.price') },
            { name: 'auctionType', label: t('onarte.management.ordersList.tableColumns.auctionType') },
            { name: 'auctionCount', label: t('onarte.management.ordersList.tableColumns.auctionCount') },
            { name: 'status', label: t('onarte.management.ordersList.tableColumns.status') },
          ]}
          rows={orders}
          maxItemsCount={maxItems}
          onPageChange={setPage}
          initialPageCount={page}
        />
      </BaseView>
      <Filters 
        filters={filters} 
        onFilter={setSubmittedFilters} 
        onClose={(): void => setFiltersOpen(false)}
        visible={filtersOpen} 
      />
    </Container>
  );
};
