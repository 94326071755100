import { IconName, Logger, SlideMenu } from '@on-arte/ui';
import { Location } from 'history';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useLocation, useMatch } from 'react-router-dom';

import { appConfig } from '@onArte/app.config';
import { plRouting } from '@onArte/constants';
import { RouteNameEnum } from '@onArte/enums';
import { useAuth } from '@onArte/hooks';
import { RouteInfo, UseAuth } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

import { pathsWithMenuHidden } from './appWrapper.configs';
import { AppWrapperProps } from './appWrapper.types';

export const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps): JSX.Element => {
  const { children }: AppWrapperProps = props;
  const { t }: TransProps<never> = useTranslation();
  const location: Location = useLocation();
  const { signOut, token, userData }: UseAuth = useAuth();
  const pathObject: RouteInfo[] = plRouting.filter(
    (route: RouteInfo): boolean => route.name !== RouteNameEnum.NotFound && !!useMatch(route.url)
  );

  useEffect(
    (): void => {
      if (pathObject.length) {
        document.title = `${t(pathObject[0].title)} | ${appConfig.applicationName}`;
      }
    },
    [location.pathname]
  );

  return (
    <Logger
      applicationId={appConfig.loggerSettings.applicationId}
      clientToken={appConfig.loggerSettings.clientToken}
      service={appConfig.loggerSettings.service}
      env={appConfig.loggerSettings.env}
      version={appConfig.loggerSettings.version}
      userId={userData?.id}
    >
      {(!pathObject?.length || !pathsWithMenuHidden.includes(pathObject[0].name)) && token && (
        <SlideMenu
          activePositionName={pathObject?.length ? pathObject[0].name : undefined}
          menuPositions={[
            {
              icon: IconName.Account,
              label: t('onarte.management.menu.usersList'),
              name: RouteNameEnum.UsersList,
              internalPath: getRouteDetailsByName(RouteNameEnum.UsersList)?.url ?? '/'
            },
            {
              icon: IconName.Receipt,
              label: t('onarte.management.menu.ordersList'),
              name: RouteNameEnum.OrdersList,
              internalPath: getRouteDetailsByName(RouteNameEnum.OrdersList)?.url ?? '/'
            },
            {
              icon: IconName.Content,
              label: t('onarte.management.menu.content'),
              internalPath: getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/',
              childrenPositions: [
                {
                  label: t('onarte.management.menu.contentCollectionsList'),
                  name: RouteNameEnum.ContentCollectionsList,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentCollectionsList)?.url ?? '/'
                },
                {
                  label: t('onarte.management.menu.contentSlidersList'),
                  name: RouteNameEnum.ContentSlidersList,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/'
                },
                {
                  label: t('onarte.management.menu.contentOrangeStripeEditForm'),
                  name: RouteNameEnum.ContentOrangeStripeEditForm,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentOrangeStripeEditForm)?.url ?? '/'
                },
                {
                  label: t('onarte.management.menu.contentRecommendationsHomeEditForm'),
                  name: RouteNameEnum.ContentRecommendationsHomeEditForm,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentRecommendationsHomeEditForm)?.url ?? '/'
                },
                {
                  label: t('onarte.management.menu.contentRecommendationsContactEditForm'),
                  name: RouteNameEnum.ContentRecommendationsContactEditForm,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentRecommendationsContactEditForm)?.url ?? '/'
                },
                {
                  label: t('onarte.management.menu.contentRecommendationsArtistsEditForm'),
                  name: RouteNameEnum.ContentRecommendationsArtistsEditForm,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentRecommendationsArtistsEditForm)?.url ?? '/'
                },
                // TODO: temporary hidden
                // {
                //   label: t('onarte.management.menu.contentRecommendationsCollectorsEditForm'),
                //   name: RouteNameEnum.ContentRecommendationsCollectorsEditForm,
                //   internalPath: getRouteDetailsByName(RouteNameEnum.ContentRecommendationsCollectorsEditForm)?.url ?? '/'
                // },
                {
                  label: t('onarte.management.menu.contentHighlightedBuyNowList'),
                  name: RouteNameEnum.ContentHighlightedBuyNowList,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentHighlightedBuyNowList)?.url ?? '/'
                },
                {
                  label: t('onarte.management.menu.contentHighlightedBiddingList'),
                  name: RouteNameEnum.ContentHighlightedBiddingList,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentHighlightedBiddingList)?.url ?? '/'
                },
                {
                  label: t('onarte.management.menu.contentHighlightedPublicProfilesList'),
                  name: RouteNameEnum.ContentHighlightedPublicProfilesList,
                  internalPath: getRouteDetailsByName(RouteNameEnum.ContentHighlightedPublicProfilesList)?.url ?? '/'
                }
              ]
            },
            {
              icon: IconName.Photo,
              label: t('onarte.management.menu.artworksList'),
              name: RouteNameEnum.ArtworksList,
              internalPath: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/'
            },
            {
              icon: IconName.Brush,
              label: t('onarte.management.menu.authors'),
              name: 'authors',
              internalPath: getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/'
            },
            {
              icon: IconName.PublicProfile,
              label: t('onarte.management.menu.publicProfilesList'),
              name: 'publicProfilesList',
              internalPath: getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/'
            },
            {
              icon: IconName.Gavel,
              label: t('onarte.management.menu.eventsList'),
              name: 'eventsList',
              internalPath: getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/'
            },
            {
              icon: IconName.Equalizer,
              label: t('onarte.management.menu.reportsList'),
              name: 'reportsList',
              internalPath: getRouteDetailsByName(RouteNameEnum.ReportsList)?.url ?? '/'
            },
            // TODO: temporary hidden
            // {
            //   icon: IconName.Dollar,
            //   label: t('onarte.management.menu.sale'),
            //   name: 'sale',
            //   internalPath: getRouteDetailsByName(RouteNameEnum.Sale)?.url ?? '/'
            // {
            //   icon: IconName.SettingsFilled,
            //   label: t('onarte.management.menu.settings'),
            //   name: 'settings',
            //   internalPath: getRouteDetailsByName(RouteNameEnum.Settings)?.url ?? '/'
            // },
            {
              icon: IconName.Exit,
              label: t('onarte.management.menu.logout'),
              name: 'logout',
              action: signOut
            },
          ]}
        />
      )}
      {children}
    </Logger>
  );
};
