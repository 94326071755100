import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';

import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const getSellersReports: () => Promise<string> = (): Promise<string> => new Promise(
  (resolve: ApiResolve<string>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get('/reports/sellers').then(
      (response: AxiosResponse<string>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
