import { AuctionDetailsBox, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const StyledAuctionDetailsBox: StyledComponent<typeof AuctionDetailsBox> = styled(AuctionDetailsBox)`
  & + & {
    margin-top: 20px;
  }
`;

export const NoDataMessage: StyledComponent<'div'> = styled.div``;
