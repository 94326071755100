import {
  AuctionDetailsInterface,
  AuctionOfferInterface,
  AuctionStatusEnum,
  EventDetailsInterface,
  EventStatusEnum,
  ItemSearchInterface,
  ListPaginationInterface,
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import {
  ApiError,
  Autosuggest,
  BooleanValue,
  DialogTheme,
  FormFieldType,
  FormFieldValue,
  SmallDialog,
  SuggestionItem,
  useFormikForm,
  UseFormikForm,
  UseLogger,
  useLogger,
  useNotifications,
  UseNotifications,
  UseState
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { getEventDetails, searchItems } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { FormFieldWithStandardSpace, FormSectionWithStandardSpace } from '@onArte/theme';
import { getImageThumbnail, getRouteDetailsByName } from '@onArte/utils';

import { useEventAuctionsEditMethods } from './eventAuctionsEdit.hooks';
import { FormikForm, StyledAuctionDetailsBox, StyledBiddingUserData } from './eventAuctionsEdit.styled';
import { EventAuctionsEditMethods, ItemInEventData } from './eventAuctionsEdit.types';

export const EventAuctionsEditView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();
  const [eventDetails, setEventDetails]: UseState<EventDetailsInterface<AuctionDetailsInterface> | null>
    = useState<EventDetailsInterface<AuctionDetailsInterface> | null>(null);
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const formRef: React.MutableRefObject<FormikProps<ItemInEventData> | null>
    = useRef<FormikProps<ItemInEventData> | null>(null);
  const [searchedItemName, setSearchedItemName]: UseState<string> = useState<string>('');
  const [itemsSuggestions, setItemsSuggestions]: UseState<SuggestionItem[]> = useState<SuggestionItem[]>([]);
  const { logger }: UseLogger = useLogger();
  const [isOffersPopupVisible, setIsOffersPopupVisible]: UseState<boolean> = useState<boolean>(false);
  const [offers, setOffers]: UseState<AuctionOfferInterface[]> = useState<AuctionOfferInterface[]>([]);
  const {
    addOrEditItemToEvent,
    removeItemFromEvent,
    isPopupVisible,
    setIsPopupVisible,
    popupFormData,
    setPopupFormData,
    actionType,
    setActionType,
    addOrEditItemFormValidation,
  }: EventAuctionsEditMethods = useEventAuctionsEditMethods(id ?? '');

  useQuery(
    [QueryKey.EventDetails],
    (): Promise<EventDetailsInterface<AuctionDetailsInterface>> => getEventDetails(id ?? ''),
    {
      onSuccess: (data: EventDetailsInterface<AuctionDetailsInterface>): void => {
        setEventDetails(data);
      },
      onError: (error: ApiError): void => {
        logger(QueryKey.EventDetails, error, 'error');
        addToast({ content: t(error.message) });
        navigate(getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/');
      },
    }
  );

  useQuery(
    [searchedItemName],
    (): Promise<ListPaginationInterface<ItemSearchInterface>> => searchItems({ name: searchedItemName, canParticipateEvent: true }),
    {
      enabled: !!searchedItemName,
      onSuccess: (data: ListPaginationInterface<ItemSearchInterface>): void => {
        const itemsIdsInEvent: string[] = eventDetails?.auctions
          .filter((auction: AuctionDetailsInterface): boolean => auction.status === AuctionStatusEnum.Active)
          .map((auction: AuctionDetailsInterface): string => auction.itemId) ?? [];

        setItemsSuggestions(
          data.list
            .filter((item: ItemSearchInterface): boolean => !itemsIdsInEvent.includes(item.id))
            .map((item: ItemSearchInterface): SuggestionItem => ({
              id: item.id,
              label: item.name,
              image: getImageThumbnail(item.image, ThumbnailAttachmentTypeEnum.Size_100x100)
            }))
        );
      }
    }
  );

  const save: () => void = (): void => {
    if (formRef.current) {
      setFormSubmitted();
      formRef.current.handleSubmit();
    }
  };

  const onBiddersNumberClickAction: (auction: AuctionDetailsInterface) => void = (
    auction: AuctionDetailsInterface
  ): void => {
    setOffers(auction.offers);
    setIsOffersPopupVisible(true);
  };

  const onSelectNewItemHandler: (suggestion: SuggestionItem) => void = (suggestion: SuggestionItem): void => {
    if (!suggestion.id) {
      return;
    }

    setPopupFormData({ ...popupFormData, itemId: suggestion.id });
    setIsPopupVisible(true);
    setActionType('add');
  };

  const editAuction: (auction: AuctionDetailsInterface) => void = (auction: AuctionDetailsInterface): void => {
    setActionType('edit');
    setPopupFormData({
      itemId: auction.itemId,
      startingPrice: auction.startPrice ?? 0,
      estimationFrom: auction.estimation?.from ?? 0,
      estimationTo: auction.estimation?.to ?? 0,
      acceptablePrice: auction.acceptablePrice ?? 0,
      highlighted: auction.highlighted,
    });
    setIsPopupVisible(true);
  };

  return (
    <>
      <BaseView
        pageTitleSettings={{ title: eventDetails?.name ?? '' }}
        breadcrumbs={[
          { label: t('onarte.common.management'), path: '/' },
          { label: t('onarte.management.meta.eventsList.title'), path: getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/' },
          {
            label: t('onarte.management.meta.eventAuctionsEdit.title'),
            path: getRouteDetailsByName(RouteNameEnum.EventAddForm)?.url ?? '/'
          },
        ]}
      >
        <FormSectionWithStandardSpace title={t('onarte.management.eventAuctionsEdit.artworkAdd')}>
          <Autosuggest
            label={t('onarte.management.eventAuctionsEdit.artworkChoose.label')}
            suggestions={itemsSuggestions}
            onSelect={onSelectNewItemHandler}
            onValueInputChange={setSearchedItemName}
            disabled={eventDetails?.status === EventStatusEnum.Finished}
          />
        </FormSectionWithStandardSpace>
        <FormSectionWithStandardSpace title={t('onarte.management.eventAuctionsEdit.artworksList')}>
          {!eventDetails?.auctions?.length ? (
            t('onarte.management.eventAuctionsEdit.noArtworks')
          ) : (
            <>
              {eventDetails?.auctions.map((auction: AuctionDetailsInterface): JSX.Element => (
                <StyledAuctionDetailsBox
                  key={auction.id}
                  image={auction.coverPhoto?.path ?? ''}
                  boxTitleDetails={{
                    author: auction.manufacturer.name,
                    itemName: auction.name,
                    itemDescriptiveAttributes: [t(auction.label)],
                    startPrice: auction.startPrice ?? undefined,
                    minimalPrice: auction.acceptablePrice ?? undefined,
                    estimationPriceLow: auction.estimation?.from,
                    estimationPriceHigh: auction.estimation?.to,
                    price: auction.price,
                    biddersNumber: auction.offers.length,
                    onBiddersNumberClick: auction.offers.length ? (): void => onBiddersNumberClickAction(auction) : undefined,
                  }}
                  onRemove={![EventStatusEnum.InProgress, EventStatusEnum.Finished].includes(eventDetails.status)
                    ? (): void => removeItemFromEvent(auction.itemId, auction.name)
                    : undefined
                  }
                  onEdit={![EventStatusEnum.InProgress, EventStatusEnum.Finished].includes(eventDetails.status)
                    ? (): void => editAuction(auction)
                    : undefined
                  }
                />
              ))}
            </>
          )}
        </FormSectionWithStandardSpace>
      </BaseView>
      <SmallDialog
        settings={{
          isActive: isOffersPopupVisible,
          header: t('onarte.management.eventAuctionsEdit.dialog.header'),
          theme: DialogTheme.Big,
          closeButtonAction: (): void => setIsOffersPopupVisible(false),
        }}
      >
        {offers.map((offer: AuctionOfferInterface): JSX.Element => (
          <StyledBiddingUserData
            key={offer.user.name}
            name={offer.user.name}
            price={offer.price}
            timestamp={offer.updatedAt}
          />
        ))}
      </SmallDialog>
      <SmallDialog
        settings={{
          isActive: isPopupVisible,
          header: t(`onarte.management.eventAuctionsEdit.dialogTitle.${actionType}`),
          acceptButton: {
            label: t('onarte.common.save'),
            action: save
          },
          cancelButton: {
            action: (): void => {
              setIsPopupVisible(false);
              formRef.current?.resetForm();
            },
            label: t('onarte.common.cancel'),
          }
        }}
      >
        <Formik
          innerRef={formRef}
          initialValues={popupFormData}
          onSubmit={addOrEditItemToEvent}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
          validationSchema={addOrEditItemFormValidation}
          enableReinitialize
        >
          {({ values, setFieldValue, errors }: FormikProps<ItemInEventData>) => (
            <FormikForm>
              <FormFieldWithStandardSpace
                label={t('onarte.management.eventAuctionsEdit.startingPrice')}
                fieldType={FormFieldType.Input}
                fieldName='startingPrice'
                setFieldValue={setFieldValue}
                value={values.startingPrice?.toString()}
                validationMessage={errors.startingPrice || ''}
                additionalFieldProps={{
                  withPriceParsing: true
                }}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.management.eventAuctionsEdit.estimationFrom')}
                fieldType={FormFieldType.Input}
                fieldName='estimationFrom'
                setFieldValue={setFieldValue}
                value={values.estimationFrom?.toString()}
                validationMessage={errors.estimationFrom || ''}
                additionalFieldProps={{
                  withPriceParsing: true
                }}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.management.eventAuctionsEdit.estimationTo')}
                fieldType={FormFieldType.Input}
                fieldName='estimationTo'
                setFieldValue={setFieldValue}
                value={values.estimationTo?.toString()}
                validationMessage={errors.estimationTo || ''}
                additionalFieldProps={{
                  withPriceParsing: true
                }}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.management.eventAuctionsEdit.acceptablePrice')}
                fieldType={FormFieldType.Input}
                fieldName='acceptablePrice'
                setFieldValue={setFieldValue}
                value={values.acceptablePrice?.toString()}
                validationMessage={errors.acceptablePrice || ''}
                additionalFieldProps={{
                  withPriceParsing: true
                }}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.management.eventAuctionsEdit.highlighted')}
                fieldType={FormFieldType.Dropdown}
                fieldName='highlighted'
                setFieldValue={(field: string, value: FormFieldValue): void => setFieldValue(field, value === BooleanValue.Yes)}
                value={values.highlighted === undefined
                  ? undefined
                  : values.highlighted
                    ? BooleanValue.Yes
                    : BooleanValue.No
                }
                additionalFieldProps={{
                  options: [
                    { name: BooleanValue.Yes, label: t('onarte.common.yes') },
                    { name: BooleanValue.No, label: t('onarte.common.no') }
                  ],
                }}
                validationMessage={errors.highlighted || ''}
              />
            </FormikForm>
          )}
        </Formik>
      </SmallDialog>
    </>
  );
};
