import { PublicProfileTypeEnum } from '@on-arte/core-types';
import {
  ApiError,
  UseLogger,
  useLogger,
  UseNotifications,
  useNotifications,
  useFormikForm,
  UseFormikForm,
  useRedirect,
  UseRedirect,
  AddPhotoFile,
  RelatedNews,
  SelectedNewsElement,
  UseState,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { createPublicProfile } from '@onArte/api';
import { BaseView, PublicProfileForm } from '@onArte/components';
import { createPublicProfileForm } from '@onArte/constants';
import { RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions } from '@onArte/hooks';
import { AddPublicProfileFormData, AutosuggestActions } from '@onArte/interfaces';
import { FormSectionWithTopSpace } from '@onArte/theme';
import { ProfileFormDataType } from '@onArte/types';
import { getRouteDetailsByName } from '@onArte/utils';

import { usePublicProfileAddValidation } from './publicProfileAddForm.hooks';

export const PublicProfileAddFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();
  const formRef: React.Ref<FormikProps<AddPublicProfileFormData>> = useRef(null);
  const PublicProfileAddValidation: Yup.SchemaOf<AddPublicProfileFormData>  = usePublicProfileAddValidation();
  const [selectedBlogPosts, setSelectedBlogPosts]: UseState<SelectedNewsElement[]> = useState<SelectedNewsElement[]>([]);
  const { searchBlogPosts }: AutosuggestActions = useAutosuggestActions();

  const save: () => Promise<void> = async (): Promise<void> => {
    if (!formRef.current) {
      return;
    }

    const formObject: FormikProps<AddPublicProfileFormData> = formRef.current;
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (isValid) {
      const { type, relationId, description, bio, avatar, cover, galleryPhoto }: AddPublicProfileFormData = formObject.values;
      const attachments: string[] = [...avatar, ...cover, ...galleryPhoto].map((attachment: AddPhotoFile): string => attachment.id);

      createPublicProfile({
        description,
        bio,
        attachments,
        type,
        relationId: relationId.id ?? '',
        blogPostIds: selectedBlogPosts.map((post: SelectedNewsElement): number => Number(post.id))
      })
        .then((): void => {
          addToast({ content: t('onarte.management.publicProfileAddFormView.saveSuccessfully') });
          redirect(getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/');
        })
        .catch((error: ApiError): void => {
          logger('updatePublicProfile', error, 'error');
          addToast({ content: t(error.message) });
        });
    }
  };

  const submit: () => void = (): void => {
    if (formRef.current) {
      setFormSubmitted();
      formRef.current.handleSubmit();
    }
  };
  
  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.publicProfileAddForm.title'),
        buttonLabel: t('onarte.common.save'),
        buttonAction: submit,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        {
          label: t('onarte.management.meta.publicProfilesList.title'),
          path: getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/'
        },
        { 
          label: t('onarte.management.meta.publicProfileAddForm.title'), 
          path: getRouteDetailsByName(RouteNameEnum.PublicProfileAddForm)?.url ?? '/' 
        },
      ]}
    >
      <PublicProfileForm
        initialValues={{
          type: '' as PublicProfileTypeEnum,
          name: '',
          description: '',
          bio: '',
          relationId: { id: '', label: '' },
          avatar: [],
          cover: [],
          galleryPhoto: [],
        }}
        isFormSubmitted={isFormSubmitted}
        ref={formRef as React.RefObject<FormikProps<ProfileFormDataType>>}
        formDefinition={createPublicProfileForm}
        submitAction={save}
        formValidationSchema={PublicProfileAddValidation}
      />
      <FormSectionWithTopSpace title={t('onarte.management.publicProfileAddFormView.magazine')}>
        <RelatedNews 
          selectedElements={selectedBlogPosts}
          onSelectedElementsChange={setSelectedBlogPosts}
          onValueInputChangeAsync={searchBlogPosts}
        />
      </FormSectionWithTopSpace>
    </BaseView>
  );
};
