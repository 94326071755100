import { ManufacturerInterface, ManufacturerUpdateModel } from '@on-arte/core-types';
import {
  ApiError,
  FormFieldType,
  FormFieldValue,
  getSingleValueFromFormFieldValue,
  SuggestionItem,
  useFormikForm,
  UseFormikForm,
  UseLogger,
  useLogger,
  useNotifications,
  UseNotifications,
  UseState
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { getManufacturerDetails, updateManufacturerDetails } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { useAutosuggestActions } from '@onArte/hooks';
import { AutosuggestActions } from '@onArte/interfaces';
import { FormFieldWithStandardSpace, FormSectionWithStandardSpace } from '@onArte/theme';
import { getRouteDetailsByName } from '@onArte/utils';

import { useAuthorEditFormValidation } from './authorEditForm.hooks';
import { FormikForm } from './authorEditForm.styled';

export const AuthorEditFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { searchUsersAction }: AutosuggestActions = useAutosuggestActions();
  const AuthorEditFormValidationSchema: Yup.SchemaOf<ManufacturerUpdateModel> = useAuthorEditFormValidation();
  const { logger }: UseLogger = useLogger();
  const [authorDetails, setAuthorDetails]: UseState<ManufacturerInterface | null> = useState<ManufacturerInterface | null>(null);
  const formRef: React.MutableRefObject<FormikProps<ManufacturerUpdateModel> | null>
    = useRef<FormikProps<ManufacturerUpdateModel> | null>(null);

  useQuery(
    [QueryKey.AuthorDetails],
    (): Promise<ManufacturerInterface> => getManufacturerDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: ManufacturerInterface): void => setAuthorDetails(data),
      onError: (error: ApiError): void => {
        logger(QueryKey.AuthorDetails, error, 'error');
        addToast({ content: t(error.message) });
        navigate(getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/');
      },
    }
  );

  const updateAuthor: (data: ManufacturerUpdateModel) => void = (data: ManufacturerUpdateModel): void => {
    if (!id) {
      return;
    }

    updateManufacturerDetails(id, data)
      .then((): void => {
        addToast({ content: t('onarte.management.notifications.authorEditForm.success') });
        navigate(getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/');
      })
      .catch((error: ApiError): void => {
        addToast({ content: t(error.message) });
      });
  };

  const save: () => void = (): void => {
    if (formRef.current) {
      setFormSubmitted();
      formRef.current.handleSubmit();
    }
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: authorDetails?.name ?? '',
        buttonLabel: t('onarte.common.save'),
        buttonAction: save,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => navigate(getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { label: t('onarte.management.meta.authorsList.title'), path: getRouteDetailsByName(RouteNameEnum.AuthorsList)?.url ?? '/' },
        {
          label: t('onarte.management.meta.authorEditForm.title'),
          path: getRouteDetailsByName(RouteNameEnum.ArtworkEditForm)?.url ?? '/'
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          name: authorDetails?.name ?? '',
          active: true,
          userId: authorDetails?.user?.id
        }}
        onSubmit={updateAuthor}
        validationSchema={AuthorEditFormValidationSchema}
        validateOnChange={isFormSubmitted}
        validateOnBlur={isFormSubmitted}
        enableReinitialize
      >
        {({ values, setFieldValue, errors }: FormikProps<ManufacturerUpdateModel>): JSX.Element => (
          <FormikForm>
            <FormSectionWithStandardSpace title={t('onarte.management.authorEditForm.authorName')}>
              <FormFieldWithStandardSpace
                label={t('onarte.management.authorEditForm.name.label')}
                fieldType={FormFieldType.Input}
                fieldName='name'
                setFieldValue={setFieldValue}
                value={values.name}
                validationMessage={errors.name || ''}
              />
              <FormFieldWithStandardSpace
                label={t('onarte.management.authorEditForm.userId.label')}
                fieldType={FormFieldType.Autosuggest}
                fieldName='userId'
                setFieldValue={(field: string, value: FormFieldValue): void => {
                  setFieldValue(field, getSingleValueFromFormFieldValue<SuggestionItem>(value).id);
                }}
                validationMessage={errors.userId || ''}
                actions={{ autosuggestValueInputAsync: searchUsersAction }}
                additionalFieldProps={{
                  initialValueLabel: authorDetails?.user?.name
                }}
              />
            </FormSectionWithStandardSpace>
          </FormikForm>
        )}
      </Formik>
    </BaseView>
  );
};
