import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';

import { User } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const loginRequest: (loginValue: string, password: string) => Promise<User> = (
  login: string, password: string
): Promise<User> => new Promise(
  (resolve: ApiResolve<User>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post('/auth/login', { login, password }).then(
      (response: AxiosResponse<User>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const logoutRequest: () => Promise<void> = (): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<void>): Promise<void> => (
    api.post('/auth/logout').then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<void>): void => reject(error))
  )
);
