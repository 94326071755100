import { ApiError } from './apiError.model';

export class FrontendApiError extends ApiError {
  public responseCode: number;

  constructor(
    message: string,
    responseCode: number,
    messageParams: Record<string, string> = {},
    details?: Record<string, object>
  ) {
    super(message, messageParams, details);
    this.responseCode = responseCode;
  }
}
