import { WebsiteSliderTypeEnum } from '@on-arte/core-types';
import {
  ApiError,
  useFormikForm,
  UseFormikForm,
  UseLogger,
  useLogger,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { createSlider } from '@onArte/api';
import { BaseView, SliderForm } from '@onArte/components';
import { RouteNameEnum } from '@onArte/enums';
import { AddOrUpdateSliderForm } from '@onArte/interfaces';
import { getRouteDetailsByName, getSliderUpdateModelByForm } from '@onArte/utils';

export const SliderAddFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();
  const formRef: React.Ref<FormikProps<AddOrUpdateSliderForm>> = useRef(null);

  const save: () => Promise<void> = async (): Promise<void> => {
    if (!formRef.current) {
      return;
    }

    const formObject: FormikProps<AddOrUpdateSliderForm> = formRef.current;
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (isValid) {
      formObject.handleSubmit();
      setFormSubmitted();

      createSlider({ type: WebsiteSliderTypeEnum.Homepage, ...getSliderUpdateModelByForm(formObject.values) })
        .then((): void => {
          addToast({ content: t('onarte.management.sliderAddForm.createSlider') });
          redirect(getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/');
        })
        .catch((error: ApiError): void => {
          addToast({ content: t(error.message) });
          logger('createSlider', error, 'error');
        });
    }
  };
  
  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.contentSliderAddForm.title'),
        buttonLabel: t('onarte.management.sliderAddForm.buttonLabel'),
        buttonAction: save,
        secondButtonLabel: t('onarte.common.cancel'),
        secondButtonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/'),
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentSlidersList.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentSlidersList)?.url ?? '/' 
        },
        { 
          label: t('onarte.management.meta.contentSliderAddForm.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentSliderAddForm)?.url ?? '/' 
        },
      ]}
    >
      <SliderForm
        initialValues={{
          header: '',
          imageId: '',
          description: '',
          isButtonVisible: false,
          isSecondButtonVisible: false,
          isLinkedItemVisible: false,
          linkedItemId: ''
        }}
        isFormSubmitted={isFormSubmitted}
        ref={formRef}
      />
    </BaseView>
  );
};
