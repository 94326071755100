import { SliderUpdateModel } from '@on-arte/core-types';

import { AddOrUpdateSliderForm } from '@onArte/interfaces';

export const getSliderUpdateModelByForm: (
  form: AddOrUpdateSliderForm
) => SliderUpdateModel = (form: AddOrUpdateSliderForm): SliderUpdateModel => {
  return {
    header: form.header,
    imageId: form.imageId,
    description: form.description || '',
    linkedItemId: (form.isLinkedItemVisible && form.linkedItemId) ? form.linkedItemId : undefined,
    buttonSettings: (form.isButtonVisible && form.buttonLabel 
        && form.buttonPathType && form.buttonPath)
      ? {
        label: form.buttonLabel,
        path: {
          type: form.buttonPathType,
          value: form.buttonPath
        }
      }
      : undefined,
    secondButtonSettings: (form.isSecondButtonVisible && form.secondButtonLabel 
        && form.secondButtonPathType && form.secondButtonPath)
      ? {
        label: form.secondButtonLabel,
        path: {
          type: form.secondButtonPathType,
          value: form.secondButtonPath
        }
      }
      : undefined
  };
};
