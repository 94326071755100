import { StyledComponent, ValidationBar } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div``;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin: 24px 0;
`;

export const StyledFormFieldsWrapper: StyledComponent<'div'> = styled.div`
  margin: 24px 0;
`;
