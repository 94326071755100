import {
  AvailableDeliveryOptionInterface,
  CategoryWithAttributesInterface,
  ItemSearchInterface,
  ItemInterface,
  ItemSearchCriteriaInterface,
  ItemWithChangesInterface,
  ListPaginationInterface,
  UpdateItemDeliveryOptionsModel,
  UpdateItemManagementModel,
  VerifyItemModel
} from '@on-arte/core-types';
import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { ListParams } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

export const searchItems: (
  params: Partial<ListParams & ItemSearchCriteriaInterface>
) => Promise<ListPaginationInterface<ItemSearchInterface>> = (
  params: Partial<ListParams & ItemSearchCriteriaInterface>
): Promise<ListPaginationInterface<ItemSearchInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<ItemSearchInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/items?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<ItemSearchInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getItemDetails: (itemId: string) => Promise<ItemWithChangesInterface> = (
  itemId: string
): Promise<ItemWithChangesInterface> => new Promise(
  (resolve: ApiResolve<ItemWithChangesInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/items/${itemId}`).then(
      (response: AxiosResponse<ItemWithChangesInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const editItemDetails: (itemId: string, data: UpdateItemManagementModel) => Promise<ItemInterface> = (
  itemId: string, data: UpdateItemManagementModel
): Promise<ItemInterface> => new Promise(
  (resolve: ApiResolve<ItemInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/items/${itemId}`, data).then(
      (response: AxiosResponse<ItemInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getItemAvailableDeliveryOptions: (itemId: string) => Promise<AvailableDeliveryOptionInterface[]> = (
  itemId: string
): Promise<AvailableDeliveryOptionInterface[]> => new Promise(
  (resolve: ApiResolve<AvailableDeliveryOptionInterface[]>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/items/${itemId}/deliveries`).then(
      (response: AxiosResponse<AvailableDeliveryOptionInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const editItemAvailableDeliveryOptions: (itemId: string, data: UpdateItemDeliveryOptionsModel) => Promise<ItemInterface> = (
  itemId: string, data: UpdateItemDeliveryOptionsModel
): Promise<ItemInterface> => new Promise(
  (resolve: ApiResolve<ItemInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/items/${itemId}/deliveries`, data).then(
      (response: AxiosResponse<ItemInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const verifyItem: (itemId: string, data: VerifyItemModel) => Promise<ItemInterface> = (
  itemId: string, data: VerifyItemModel
): Promise<ItemInterface> => new Promise(
  (resolve: ApiResolve<ItemInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/items/${itemId}/verification`, data).then(
      (response: AxiosResponse<ItemInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const deleteItem: (itemId: string) => Promise<void> = (itemId: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
    api.delete(`/items/${itemId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getItemCategories: () => Promise<CategoryWithAttributesInterface[]>
= (): Promise<CategoryWithAttributesInterface[]> => new Promise(
  (resolve: ApiResolve<CategoryWithAttributesInterface[]>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get('items/categories').then(
      (response: AxiosResponse<CategoryWithAttributesInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const verifyItemEditionVersion: (itemId: string, versionId: string, data: VerifyItemModel) => Promise<ItemInterface> = (
  itemId: string, versionId: string, data: VerifyItemModel
): Promise<ItemInterface> => new Promise(
  (resolve: ApiResolve<ItemInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`items/${itemId}/versions/${versionId}/verification`, data).then(
      (response: AxiosResponse<ItemInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);
