import { RouteNameEnum } from '@onArte/enums';
import { RouteInfo } from '@onArte/interfaces';

export const plRouting: RouteInfo[] = [
  {
    name: RouteNameEnum.ArtworkDetails,
    url: '/dziela/:id',
    title: 'onarte.management.meta.artworkDetails.title',
    description: 'onarte.management.meta.artworkDetails.description',
    secured: true
  },
  {
    name: RouteNameEnum.ArtworkEditChanges,
    url: '/dziela/:id/podglad-edycji',
    title: 'onarte.management.meta.artworkEditChanges.title',
    description: 'onarte.management.meta.artworkEditChanges.description',
    secured: true
  },
  {
    name: RouteNameEnum.ArtworkEditForm,
    url: '/dziela/:id/edycja',
    title: 'onarte.management.meta.artworkEditForm.title',
    description: 'onarte.management.meta.artworkEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ArtworksList,
    url: '/dziela',
    title: 'onarte.management.meta.artworksList.title',
    description: 'onarte.management.meta.artworksList.description',
    secured: true
  },
  {
    name: RouteNameEnum.AuthorsAddForm,
    url: '/autorzy/dodaj',
    title: 'onarte.management.meta.authorsAddForm.title',
    description: 'onarte.management.meta.authorsAddForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.AuthorEditForm,
    url: '/autorzy/:id/edycja',
    title: 'onarte.management.meta.authorEditForm.title',
    description: 'onarte.management.meta.authorEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.AuthorsList,
    url: '/autorzy',
    title: 'onarte.management.meta.authorsList.title',
    description: 'onarte.management.meta.authorsList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentCollectionsList,
    url: '/tresci/kolekcje',
    title: 'onarte.management.meta.contentCollectionsList.title',
    description: 'onarte.management.meta.contentCollectionsList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentCollectionAddForm,
    url: '/tresci/kolekcje/dodaj',
    title: 'onarte.management.meta.contentCollectionAddForm.title',
    description: 'onarte.management.meta.contentCollectionAddForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentCollectionEditForm,
    url: '/tresci/kolekcje/:id/edycja',
    title: 'onarte.management.meta.contentCollectionEditForm.title',
    description: 'onarte.management.meta.contentCollectionEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentCollectionItemsForm,
    url: '/tresci/kolekcje/:id/dziela',
    title: 'onarte.management.meta.contentCollectionItemsForm.title',
    description: 'onarte.management.meta.contentCollectionItemsForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentSlidersList,
    url: '/tresci/slidery',
    title: 'onarte.management.meta.contentSlidersList.title',
    description: 'onarte.management.meta.contentSlidersList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentSliderAddForm,
    url: '/tresci/slidery/dodaj',
    title: 'onarte.management.meta.contentSliderAddForm.title',
    description: 'onarte.management.meta.contentSliderAddForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentSliderEditForm,
    url: '/tresci/slidery/:id/edycja',
    title: 'onarte.management.meta.contentSliderEditForm.title',
    description: 'onarte.management.meta.contentSliderEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentOrangeStripeEditForm,
    url: '/tresci/pomaranczowy-pasek/edycja',
    title: 'onarte.management.meta.contentOrangeStripeEditForm.title',
    description: 'onarte.management.meta.contentOrangeStripeEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentRecommendationsHomeEditForm,
    url: '/tresci/sekcja-promocyjna/strona-glowna/edycja',
    title: 'onarte.management.meta.contentRecommendationsHomeEditForm.title',
    description: 'onarte.management.meta.contentRecommendationsHomeEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentRecommendationsContactEditForm,
    url: '/tresci/sekcja-promocyjna/kontakt/edycja',
    title: 'onarte.management.meta.contentRecommendationsContactEditForm.title',
    description: 'onarte.management.meta.contentRecommendationsContactEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentRecommendationsArtistsEditForm,
    url: '/tresci/sekcja-promocyjna/artysci/edycja',
    title: 'onarte.management.meta.contentRecommendationsArtistsEditForm.title',
    description: 'onarte.management.meta.contentRecommendationsArtistsEditForm.description',
    secured: true
  },
  // {
  //   name: RouteNameEnum.ContentRecommendationsCollectorsEditForm,
  //   url: '/tresci/sekcja-promocyjna/kolekcjonerzy/edycja',
  //   title: 'onarte.management.meta.contentRecommendationsCollectorsEditForm.title',
  //   description: 'onarte.management.meta.contentRecommendationsCollectorsEditForm.description',
  //   secured: true
  // },
  {
    name: RouteNameEnum.ContentHighlightedBiddingList,
    url: '/tresci/wyroznione/licytacja',
    title: 'onarte.management.meta.contentHighlightedBiddingList.title',
    description: 'onarte.management.meta.contentHighlightedBiddingList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentHighlightedBuyNowList,
    url: '/tresci/wyroznione/kup-teraz',
    title: 'onarte.management.meta.contentHighlightedBuyNowList.title',
    description: 'onarte.management.meta.contentHighlightedBuyNowList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentHighlightedPublicProfilesList,
    url: '/tresci/wyroznione/profile-publiczne',
    title: 'onarte.management.meta.contentHighlightedPublicProfilesList.title',
    description: 'onarte.management.meta.contentHighlightedPublicProfilesList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentTextPages,
    url: '/tresci/strony-tekstowe',
    title: 'onarte.management.meta.contentTextPages.title',
    description: 'onarte.management.meta.contentTextPages.description',
    secured: true
  },
  {
    name: RouteNameEnum.ContentFiles,
    url: '/tresci/pliki',
    title: 'onarte.management.meta.contentFiles.title',
    description: 'onarte.management.meta.contentFiles.description',
    secured: true
  },
  {
    name: RouteNameEnum.EventAddForm,
    url: '/aukcje/dodaj',
    title: 'onarte.management.meta.eventAddForm.title',
    description: 'onarte.management.meta.eventAddForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.EventEditForm,
    url: '/aukcje/:id/edycja',
    title: 'onarte.management.meta.eventEditForm.title',
    description: 'onarte.management.meta.eventEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.EventAuctionsEdit,
    url: '/aukcje/:id/dziela',
    title: 'onarte.management.meta.eventAuctionsEdit.title',
    description: 'onarte.management.meta.eventAuctionsEdit.description',
    secured: true
  },
  {
    name: RouteNameEnum.EventsList,
    url: '/aukcje',
    title: 'onarte.management.meta.eventsList.title',
    description: 'onarte.management.meta.eventsList.description',
    secured: true
  },
  {
    name: RouteNameEnum.Logout,
    url: '/wylogowanie',
    title: 'onarte.management.meta.logout.title',
    description: 'onarte.management.meta.logout.description'
  },
  {
    name: RouteNameEnum.OrderDetails,
    url: '/zamowienia/:id',
    title: 'onarte.management.meta.orderDetails.title',
    description: 'onarte.management.meta.orderDetails.description',
    secured: true
  },
  {
    name: RouteNameEnum.OrdersList,
    url: '/zamowienia',
    title: 'onarte.management.meta.ordersList.title',
    description: 'onarte.management.meta.ordersList.description',
    secured: true
  },
  {
    name: RouteNameEnum.PublicProfileAddForm,
    url: '/profile-publiczne/dodaj',
    title: 'onarte.management.meta.publicProfileAddForm.title',
    description: 'onarte.management.meta.publicProfileAddForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.PublicProfileDetails,
    url: '/profile-publiczne/:id',
    title: 'onarte.management.meta.publicProfileDetails.title',
    description: 'onarte.management.meta.publicProfileDetails.description',
    secured: true
  },
  {
    name: RouteNameEnum.PublicProfileEditForm,
    url: '/profile-publiczne/:id/edycja',
    title: 'onarte.management.meta.publicProfileEditForm.title',
    description: 'onarte.management.meta.publicProfileEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.PublicProfileVerifyChanges,
    url: '/profile-publiczne/:id/zmiany',
    title: 'onarte.management.meta.publicProfileVerifyChanges.title',
    description: 'onarte.management.meta.publicProfileVerifyChanges.description',
    secured: true
  },
  {
    name: RouteNameEnum.PublicProfilesList,
    url: '/profile-publiczne',
    title: 'onarte.management.meta.publicProfilesList.title',
    description: 'onarte.management.meta.publicProfilesList.description',
    secured: true
  },
  {
    name: RouteNameEnum.ReportsList,
    url: '/raporty',
    title: 'onarte.management.meta.reportsList.title',
    description: 'onarte.management.meta.reportsList.description',
    secured: true
  },
  {
    name: RouteNameEnum.Sale,
    url: '/sprzedaz',
    title: 'onarte.management.meta.sale.title',
    description: 'onarte.management.meta.sale.description',
    secured: true
  },
  {
    name: RouteNameEnum.Settings,
    url: '/ustawienia',
    title: 'onarte.management.meta.settings.title',
    description: 'onarte.management.meta.settings.description',
    secured: true
  },
  {
    name: RouteNameEnum.UserEditForm,
    url: '/uzytkownicy/:id/edycja',
    title: 'onarte.management.meta.usersEditForm.title',
    description: 'onarte.management.meta.usersEditForm.description',
    secured: true
  },
  {
    name: RouteNameEnum.UsersList,
    url: '/uzytkownicy',
    title: 'onarte.management.meta.usersList.title',
    description: 'onarte.management.meta.usersList.description',
    secured: true
  },
  {
    name: RouteNameEnum.SignIn,
    url: '/logowanie',
    title: 'onarte.management.meta.signIn.title',
    description: 'onarte.management.meta.signIn.description'
  },
  {
    name: RouteNameEnum.NotFound,
    url: '*',
    title: 'onarte.management.meta.notFound.title',
    description: 'onarte.management.meta.notFound.description'
  },
];
