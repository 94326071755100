import { OrangeStripeSectionInterface, OrangeStripeSectionUpdateModel } from '@on-arte/core-types';
import { 
  UseLogger, 
  useLogger, 
  ApiError, 
  FormFieldType, 
  UseState, 
  UseFormikForm, 
  UseNotifications,
  useNotifications,
  useFormikForm
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { editOrangeStripe, getOrangeStripe } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { FormikForm, FormFieldWithStandardSpace, FormSectionWithStandardSpace } from '@onArte/theme';
import { getRouteDetailsByName } from '@onArte/utils';

import { useOrangeStripeEditForm } from './orangeStripeEditForm.hooks';

export const OrangeStripeEditFormView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const { isFormSubmitted, setFormSubmitted }: UseFormikForm = useFormikForm();
  const [orangeStripeData, setOrangeStripeData]: UseState<OrangeStripeSectionInterface | null> 
    = useState<OrangeStripeSectionInterface | null>(null);
  const OrangeStripeFormValidationSchema: Yup.SchemaOf<OrangeStripeSectionUpdateModel> = useOrangeStripeEditForm();
  const formRef: React.Ref<FormikProps<OrangeStripeSectionUpdateModel>> = useRef(null);

  useQuery(
    [QueryKey.OrangeStripeDetails],
    (): Promise<OrangeStripeSectionInterface> => getOrangeStripe(),
    {
      onSuccess: (data: OrangeStripeSectionInterface): void => setOrangeStripeData(data),
      onError: (error: ApiError): void => logger(QueryKey.OrangeStripeDetails, error, 'error')
    }
  );

  const save: () => Promise<void> = async (): Promise<void> => {
    if (!formRef.current) {
      return;
    }

    const formObject: FormikProps<OrangeStripeSectionUpdateModel> = formRef.current;
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (isValid) {
      formObject.handleSubmit();
      setFormSubmitted();

      editOrangeStripe(formObject.values)
        .then((): void => {
          addToast({ content: t('onarte.management.orangeStripeEditForm.editOrangeStripeSuccess') });
        })
        .catch((error: ApiError): void => {
          addToast({ content: t(error.message) });
          logger('editOrangeStripe', error, 'error');
        });
    }
  };

  return (
    <BaseView
      pageTitleSettings={{
        title: t('onarte.management.meta.contentOrangeStripeEditForm.title'),
        buttonLabel: t('onarte.management.orangeStripeEditForm.buttonLabel'),
        buttonAction: save
      }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.contentOrangeStripeEditForm.title'), 
          path: getRouteDetailsByName(RouteNameEnum.ContentOrangeStripeEditForm)?.url ?? '/' 
        },
      ]}
    >
      {!!orangeStripeData && (
        <Formik
          innerRef={formRef}
          initialValues={{
            text: orangeStripeData.text,
            link: orangeStripeData.link
          }}
          onSubmit={(): void => undefined}
          validationSchema={OrangeStripeFormValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors, values }: FormikProps<OrangeStripeSectionUpdateModel>): JSX.Element => (
            <FormikForm onSubmit={handleSubmit}>
              <FormSectionWithStandardSpace title={t('onarte.common.orangeStripeEditForm.infoSection.label')}>
                <FormFieldWithStandardSpace
                  label={t('onarte.common.orangeStripeEditForm.text.label')}
                  fieldType={FormFieldType.Textarea}
                  fieldName='text'
                  setFieldValue={setFieldValue}
                  value={values.text}
                  validationMessage={errors.text || ''}
                  additionalFieldProps={{
                    maxCharsCount: 255
                  }}
                />
                <FormFieldWithStandardSpace
                  label={t('onarte.common.orangeStripeEditForm.link.label')}
                  fieldType={FormFieldType.Input}
                  fieldName='link'
                  setFieldValue={setFieldValue}
                  value={values.link}
                  validationMessage={errors.link || ''}
                />
              </FormSectionWithStandardSpace>
            </FormikForm>
          )}
        </Formik>
      )}
    </BaseView>
  );
};
