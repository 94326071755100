import { ApiError, UseNotifications, useNotifications, UseState } from '@on-arte/ui';
import { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { assignItemToEvent, detachItemFromEvent } from '@onArte/api';
import { QueryKey } from '@onArte/enums';

import { ActionType, EventAuctionsEditMethods, ItemInEventData } from './eventAuctionsEdit.types';

export const useEventAuctionsEditMethods: (eventId: string) => EventAuctionsEditMethods = (eventId: string): EventAuctionsEditMethods => {
  const { t }: TransProps<never> = useTranslation();
  const queryClient: QueryClient = useQueryClient();
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const [popupFormData, setPopupFormData]: UseState<ItemInEventData> = useState<ItemInEventData>({} as ItemInEventData);
  const [isPopupVisible, setIsPopupVisible]: UseState<boolean> = useState<boolean>(false);
  const [actionType, setActionType]: UseState<ActionType> = useState<ActionType>('add');

  const addOrEditItemToEvent: (data: ItemInEventData) => void = async (data: ItemInEventData): Promise<void> => {
    if (!eventId) {
      return;
    }

    // TODO: fix with edit endpoint when api will be ready
    if (actionType === 'edit') {
      await detachItemFromEvent(eventId, data.itemId);
    }

    assignItemToEvent(
      eventId,
      data.itemId,
      {
        startPrice: data.startingPrice,
        estimation: {
          from: data.estimationFrom, to: data.estimationTo,
        },
        acceptablePrice: data.acceptablePrice,
        highlighted: data.highlighted,
      }
    ).then((): void => {
      addToast({ content: t('onarte.management.notifications.assignItemForm.success') });
      setIsPopupVisible(false);
      setPopupFormData({} as ItemInEventData);
      void queryClient.invalidateQueries(QueryKey.EventDetails);
    }).catch((error: ApiError): void => {
      addToast({ content: t(error.message) });
    });
  };

  const removeItemFromEvent: (itemId: string, itemName: string) => void = (itemId: string, itemName: string): void => {
    showSmallDialog({
      header: t('onarte.management.notifications.itemFromEventRemoval.header'),
      content: t('onarte.management.notifications.itemFromEventRemoval.content', { itemName }),
      acceptButton: {
        label: t('onarte.common.confirm'),
        action: (): void => {
          detachItemFromEvent(eventId, itemId)
            .then((): void => {
              hideSmallDialog();
              addToast({ content: t('onarte.management.notifications.itemFromEventRemoval.success', { itemName }) });
              void queryClient.invalidateQueries(QueryKey.EventDetails);
            })
            .catch((): void => {
              addToast({ content: t('onarte.management.notifications.itemFromEventRemoval.failure', { itemName }) });
            });
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel'),
      },
    });
  };

  const addOrEditItemFormValidation: Yup.SchemaOf<Partial<ItemInEventData>> = Yup.object().shape({
    itemId: Yup.string()
      .required(t('onarte.common.requiredField')),
    startingPrice: Yup.number()
      .required(t('onarte.common.requiredField')),
    estimationFrom: Yup.number()
      .required(t('onarte.common.requiredField')),
    estimationTo: Yup.number()
      .required(t('onarte.common.requiredField')),
    acceptablePrice: Yup.number()
      .required(t('onarte.common.requiredField')),
    highlighted: Yup.boolean()
      .required(t('onarte.common.requiredField')),
  });

  return {
    addOrEditItemToEvent,
    removeItemFromEvent,
    isPopupVisible,
    setIsPopupVisible,
    popupFormData,
    setPopupFormData,
    actionType,
    setActionType,
    addOrEditItemFormValidation,
  };
};
