import { PublicProfileManagementModel } from '@on-arte/core-types';
import { ApiError, DetailsTable, TableDetailsRowData, UseLogger, UseState, useLogger } from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getPublicProfileDetails } from '@onArte/api';
import { BaseView } from '@onArte/components';
import { QueryKey, RouteNameEnum } from '@onArte/enums';
import { usePublicProfileTableDetails } from '@onArte/hooks';
import { UsePublicProfileTableDetails } from '@onArte/interfaces';
import { getRouteDetailsByName } from '@onArte/utils';

export const PublicProfileDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const { logger }: UseLogger = useLogger();
  const [profileData, setProfileData]: UseState<PublicProfileManagementModel | null> = useState<PublicProfileManagementModel | null>(null);
  const { getTableRows }: UsePublicProfileTableDetails = usePublicProfileTableDetails();

  useQuery(
    [QueryKey.PublicProfileDetails],
    (): Promise<PublicProfileManagementModel> => getPublicProfileDetails(id ?? ''),
    {
      enabled: !!id,
      onSuccess: (data: PublicProfileManagementModel): void => setProfileData(data),
      onError: (error: ApiError): void => logger(QueryKey.PublicProfileDetails, error, 'error')
    }
  );

  const tableRows: TableDetailsRowData[] = useMemo(
    (): TableDetailsRowData[] => {
      if (!profileData) {
        return [];
      }

      return getTableRows(profileData, false);
    },
    [profileData]
  );

  return (
    <BaseView
      pageTitleSettings={{ title: profileData?.name ?? '' }}
      breadcrumbs={[
        { label: t('onarte.common.management'), path: '/' },
        { 
          label: t('onarte.management.meta.publicProfilesList.title'),
          path: getRouteDetailsByName(RouteNameEnum.PublicProfilesList)?.url ?? '/' 
        },
        { label: profileData?.name ?? '', path: '/' },
      ]}
    >
      <DetailsTable rows={tableRows} />
    </BaseView>
  );
};
