import {
  ListPaginationInterface,
  ManufacturerConfirmModel,
  ManufacturerCreateModel,
  ManufacturerInterface,
  ManufacturerUpdateModel
} from '@on-arte/core-types';
import { ApiError } from '@on-arte/ui';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { ListParams } from '@onArte/interfaces';
import { ApiReject, ApiResolve } from '@onArte/types';

import { api } from '../api';

// TODO: add correct type when BE will be ready
export const searchManufacturers: (
  params: Partial<ListParams & { name: string }>
) => Promise<ListPaginationInterface<ManufacturerInterface>> = (
  params: Partial<ListParams & { name: string }>
): Promise<ListPaginationInterface<ManufacturerInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<ManufacturerInterface>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/manufacturers?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<ManufacturerInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const createManufacturer: (data: ManufacturerCreateModel) => Promise<ManufacturerInterface> = (
  data: ManufacturerCreateModel
): Promise<ManufacturerInterface> => new Promise(
  (resolve: ApiResolve<ManufacturerInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post('/manufacturers', data).then(
      (response: AxiosResponse<ManufacturerInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const getManufacturerDetails: (manufacturerId: string) => Promise<ManufacturerInterface> = (
  manufacturerId: string
): Promise<ManufacturerInterface> => new Promise(
  (resolve: ApiResolve<ManufacturerInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/manufacturers/${manufacturerId}`).then(
      (response: AxiosResponse<ManufacturerInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const updateManufacturerDetails: (manufacturerId: string, data: ManufacturerUpdateModel) => Promise<ManufacturerInterface> = (
  manufacturerId: string, data: ManufacturerUpdateModel
): Promise<ManufacturerInterface> => new Promise(
  (resolve: ApiResolve<ManufacturerInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.patch(`/manufacturers/${manufacturerId}`, data).then(
      (response: AxiosResponse<ManufacturerInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const verifyManufacturer: (manufacturerId: string, data: ManufacturerConfirmModel) => Promise<ManufacturerInterface> = (
  manufacturerId: string, data: ManufacturerConfirmModel
): Promise<ManufacturerInterface> => new Promise(
  (resolve: ApiResolve<ManufacturerInterface>, reject: ApiReject<ApiError>): Promise<void> => (
    api.post(`/manufacturers/${manufacturerId}/verification`, data).then(
      (response: AxiosResponse<ManufacturerInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  )
);

export const deleteManufacturer: (manufacturerId: string) => Promise<void> = (manufacturerId: string): Promise<void> => {
  return new Promise(
    (resolve: ApiResolve<void>, reject: ApiReject<ApiError>): Promise<void> => (
      api.delete(`/manufacturers/${manufacturerId}`).then(
        (response: AxiosResponse<void>): void => { resolve(response?.data); }
      ).catch((error: AxiosResponse<ApiError>): void => reject(error))
    )
  );
};
