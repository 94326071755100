import {
  AuctionInfoInterface,
  AuctionStatusEnum,
  AuctionTypeEnum,
  ItemSearchInterface,
  ListPaginationInterface,
  ManufacturerInterface,
  PostDTO,
  PublicProfileListElementInterface,
  PublicProfileTypeEnum,
  ThumbnailAttachmentTypeEnum,
  UserListElementInterface
} from '@on-arte/core-types';
import { SuggestionItem } from '@on-arte/ui';

import { getAuctionsList, getBlogPosts, getPublicProfiles, getUsers, searchItems, searchManufacturers } from '@onArte/api';
import { AutosuggestActions } from '@onArte/interfaces';
import { getImageThumbnail } from '@onArte/utils';

export const useAutosuggestActions: () => AutosuggestActions = (): AutosuggestActions => {
  const searchPublicProfilesAction: (inputValue: string, type: PublicProfileTypeEnum) => Promise<SuggestionItem[]> = (
    inputValue: string, type: PublicProfileTypeEnum
  ): Promise<SuggestionItem[]> => {
    return new Promise((resolve: (suggestions: SuggestionItem[]) => void, reject: () => void): void => {
      getPublicProfiles({ phrase: inputValue, limit: 50, type, highlighted: false, activeOnly: true })
        .then((data: ListPaginationInterface<PublicProfileListElementInterface>): void => {
          resolve(data.list.map((item: PublicProfileListElementInterface): SuggestionItem => ({
            id: item.id,
            label: item.name,
          })));
        })
        .catch((): void => reject());
    });
  };

  const searchUsersAction: (inputValue: string) => Promise<SuggestionItem[]> = (inputValue: string): Promise<SuggestionItem[]> => {
    return new Promise((resolve: (suggestions: SuggestionItem[]) => void, reject: () => void): void => {
      getUsers({ search: inputValue, limit: 50 })
        .then((data: ListPaginationInterface<UserListElementInterface>): void => {
          resolve(data.list.map((item: UserListElementInterface): SuggestionItem => ({
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })));
        })
        .catch((): void => reject());
    });
  };

  const searchManufacturersAction: (inputValue: string) => Promise<SuggestionItem[]> = (inputValue: string): Promise<SuggestionItem[]> => {
    return new Promise((resolve: (suggestions: SuggestionItem[]) => void, reject: () => void): void => {
      searchManufacturers({ name: inputValue })
        .then((data: ListPaginationInterface<ManufacturerInterface>): void => {
          resolve(data.list.map((manufacturerInfo: ManufacturerInterface): SuggestionItem => ({
            id: manufacturerInfo.id,
            label: manufacturerInfo.name,
          })));
        })
        .catch((): void => reject());
    });
  };

  const searchBlogPosts: (inputValue: string) => Promise<SuggestionItem[]> = (inputValue: string): Promise<SuggestionItem[]> => {
    return new Promise((resolve: (suggestions: SuggestionItem[]) => void, reject: () => void): void => {
      getBlogPosts({ titleSearch: inputValue })
        .then((data: ListPaginationInterface<PostDTO>): void => {
          resolve(data.list.map((post: PostDTO): SuggestionItem => ({
            id: String(post.id),
            label: post.title,
          })));
        })
        .catch((): void => reject());
    });
  };

  const searchItemsAction: (inputValue: string, idsNotIncludeInAutosuggest?: string[]) => Promise<SuggestionItem[]> = (
    inputValue: string, idsNotIncludeInAutosuggest?: string[]
  ): Promise<SuggestionItem[]> => {
    return new Promise((resolve: (suggestions: SuggestionItem[]) => void, reject: () => void): void => {
      searchItems({ name: inputValue, limit: 50 })
        .then((data: ListPaginationInterface<ItemSearchInterface>): void => {
          resolve(data.list
            .filter((item: ItemSearchInterface): boolean => !idsNotIncludeInAutosuggest?.includes(item.id))
            .map((item: ItemSearchInterface): SuggestionItem => ({
              id: item.id,
              label: item.name,
              image: getImageThumbnail(item.image, ThumbnailAttachmentTypeEnum.Size_100x100)
            })));
        })
        .catch((): void => reject());
    });
  };

  const searchAuctionsAction: (
    inputValue: string, auctionType?: AuctionTypeEnum
  ) => Promise<SuggestionItem[]> = (
    inputValue: string, auctionType?: AuctionTypeEnum
  ): Promise<SuggestionItem[]> => {
    return new Promise((resolve: (suggestions: SuggestionItem[]) => void, reject: () => void): void => {
      getAuctionsList({ 
        searchQuery: inputValue, 
        limit: 50, 
        offset: 0, 
        highlighted: false, 
        type: auctionType, 
        status: AuctionStatusEnum.Active
      })
        .then((data: ListPaginationInterface<AuctionInfoInterface>): void => {
          resolve(data.list
            .map((auction: AuctionInfoInterface): SuggestionItem => ({
              id: auction.id,
              label: auction.name,
              image: getImageThumbnail(auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_100x100)
            })));
        })
        .catch((): void => reject());
    });
  };

  return {
    searchUsersAction,
    searchManufacturersAction,
    searchBlogPosts,
    searchItemsAction,
    searchAuctionsAction,
    searchPublicProfilesAction
  };
};
